import Factor2 from "containers/Factor/index copy 2";
import Factor from "containers/Factor";
import BranchFactor from "containers/BranchFactor";
// import { STATUSES_OBJECT } from "enumerations";
import { useMemo, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import ReactToPrint from 'react-to-print';
// import { useReactToPrint } from 'react-to-print';
import getDataInObjectDepth from "utils/getDataInObject";
// import Print from "./Print";
import { useSelector } from "react-redux";

import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom";
import useQueryString from "utils/useQueryString";
import PrintComponent from "utils/PrintComponent";
import isoToDate from "utils/isoToDate";

const TableAction = ({ param, baseRoute, newRoute, onClick, id, disable, active, pathId, endPath, ...props }) => {
	const currentId = useMemo(() => (param && pathId ? getDataInObjectDepth(pathId, param) : false), []);
	// console.log({ currentId, param });
	const componentRef = useRef();
	const navigate = useNavigate();
	const location = useLocation();

	const paperRef = useRef();
	param = param || {};
	// console.log({ param });
	const adminState = useSelector((state) => state.admin);
	const isBranch = adminState.data.userType === "branch";
	const QueryString = useQueryString();

	// async function printDiv() {
	//   var myframe = document.createElement("IFRAME");
	//   myframe.domain = document.domain;
	//   myframe.style.position = "absolute";
	//   myframe.style.top = "-10000px";
	//   var parser = new DOMParser();
	//   // var parsedDocument = parser.parseFromString(xhr.response, 'text/html');
	//   document.body.appendChild(myframe);
	//   let element = ReactDOMServer.renderToString(<Factor {...param} />);

	//   myframe.contentDocument.write(element);
	//   setTimeout(function () {
	//     myframe.focus();
	//     myframe.contentWindow.print();
	//     myframe.parentNode.removeChild(myframe); // remove frame
	//   }, 1000); // wait for images to load inside iframe
	//   window.focus();
	// }

	async function printDiv() {
		if (adminState?.data?.id == "65cdf1d78faa8486560e7d67") {
			PrintComponent(
				<Factor {...param} propData={props} />,
				`factor-hotel(${param?.hotel?.name})-room(${param?.roomNumber})-date(${isoToDate(param?.createdAt)})`
			);
		} else {
			var myframe = document.createElement("iframe");
			myframe.style.position = "absolute";
			myframe.style.top = "-10000px";
			document.body.appendChild(myframe);

			if (param) {
				ReactDOM.render(<Factor2 {...param} propData={props} />, myframe.contentDocument.body);
			}

			setTimeout(function () {
				try {
					myframe.focus();
					myframe.contentWindow.print();
					myframe.parentNode.removeChild(myframe);
				} catch (error) {}
			}, 1000);
			window.focus();
		}
	}

	// function printDiv() {
	//   var printContents = ReactDOMServer.renderToString(<Factor  {...param} />);
	//   console.log({ printContents });
	//   var originalContents = document.body.innerHTML;

	//   document.body.innerHTML = printContents;

	//   window.print();

	//   document.body.innerHTML = originalContents;

	// }
	// const handlePrint = () => {
	//   // console.log({componentRef.current});
	//   // Print({ element: componentRef.current, name: param?.name || param?.title || "print", })
	//   window.print()
	// }
	// const handlePrint = useReactToPrint({
	//   content: () => componentRef.current,
	//   documentTitle: param?.name || param?.title || "print",
	//   // pageStyle: `
	//   //   @page {
	//   //     size: 10mm 10mm;
	//   //     margin: 0;
	//   //   }

	//   //   @media print {
	//   //     @page {
	//   //       size: 53mm 180mm;
	//   //       margin: 0;
	//   //     }
	//   //   }
	//   // `,

	// });

	// function printDiv() {
	//   var divContents = componentRef.current;
	//   var a = window.open('', '', 'height=500, width=500');
	//   a.document.write('<html>');
	//   a.document.write('<body > <h1>Div contents are <br>');
	//   a.document.write(divContents);
	//   a.document.write('</body></html>');
	//   a.document.close();
	//   a.print();
	// }
	// console.log({ componentRef: componentRef, paperRef: paperRef });

	return (
		<div className="d-flex">
			{}
			{active?.manuel?.length ? (
				active?.manuel?.map((x) => {
					return x?.route ? (
						<Link
							to={(x?.manual ? x?.route : newRoute.base + newRoute?.[x?.route]) + "/" + param?.id}
							className={`btn btn-secondary shadow btn-xs sharp mr-1 action-btn rounded p-2 height-fit-content  ${x?.class}`}
							onClick={(e) => {
								if (x?.route) {
									e.preventDefault();
									navigate(
										param.id
											? (x?.manual ? x?.route : newRoute.base + newRoute?.[x?.route]) + "/" + param.id
											: x?.manual
											? x?.route
											: newRoute.base + newRoute?.[x?.route],
										{ state: { query: QueryString, route: baseRoute || newRoute } }
									);
								}
								x?.onClick?.(param);
							}}
							title={x.title}>
							<i className={x?.icon || "mdi mdi-pencil"}></i>
							{x?.label ? <div className="mt-2">{x?.label}</div> : ""}
						</Link>
					) : (
						<div
							className={`btn btn-secondary shadow btn-xs sharp mr-1 action-btn rounded p-2 height-fit-content  ${x?.class}`}
							onClick={x?.onClick ? () => x?.onClick?.(param) : ""}
							title={x.title}>
							<i className={x?.icon || "mdi mdi-pencil"}></i>
							{x?.label ? <div className="mt-2">{x?.label}</div> : ""}
						</div>
					);
				})
			) : active?.manuel ? (
				active?.manuel?.route ? (
					<Link
						to={active?.manuel?.route ? newRoute.base + newRoute?.[active?.manuel?.route] + "/" + param?.id : "#"}
						className={`btn btn-secondary shadow btn-xs sharp mr-1 action-btn rounded p-2 height-fit-content  ${active?.manuel?.class}`}
						// onClick={active?.manuel?.onClick ? () => active?.manuel?.onClick?.(param) : ""}
						onClick={(e) => {
							if (active?.manuel?.route) {
								e.preventDefault();
								navigate(id ? newRoute.base + newRoute?.[active?.manuel?.route] + "/" + id : newRoute.base + newRoute?.[active?.manuel?.route], {
									state: {
										route: newRoute,
										query: QueryString,
									},
								});
							}
							active?.manuel?.onClick?.(param);
						}}
						title={active?.manuel.title}>
						<i className={active?.manuel?.icon || "mdi mdi-pencil"}></i>
						{active?.manuel?.label ? <div className="mt-2">{active?.manuel?.label}</div> : ""}
					</Link>
				) : (
					<div
						className={`btn btn-secondary shadow btn-xs sharp mr-1 action-btn rounded p-2 height-fit-content  ${active?.manuel?.class}`}
						onClick={active?.manuel?.onClick ? () => active?.manuel?.onClick?.(param) : ""}
						title={active?.manuel.title}>
						<i className={active?.manuel?.icon || "mdi mdi-pencil"}></i>
						{active?.manuel?.label ? <div className="mt-2">{active?.manuel?.label}</div> : ""}
					</div>
				)
			) : (
				""
			)}
			{!disable?.edit ? (
				<Link
					onClick={(e) => {
						e.preventDefault();
						navigate(
							id
								? newRoute.base + newRoute.edit + "/" + (currentId || param?.id) + "/" + id
								: newRoute.base + newRoute.edit + "/" + (currentId || param?.id),
							{ state: { query: QueryString, route: baseRoute || newRoute } }
						);
					}}
					to={
						id ? newRoute.base + newRoute.edit + "/" + (currentId || param?.id) + "/" + id : newRoute.base + newRoute.edit + "/" + (currentId || param?.id)
					}
					className=" btn btn-primary shadow btn-xs sharp mr-1 action-btn rounded p-2 height-fit-content ">
					<i className="mdi mdi-pencil"></i>
				</Link>
			) : (
				""
			)}
			{!disable?.delete ? (
				<span
					className="btn btn-danger shadow btn-xs sharp pointer action-btn mdi rounded p-2 height-fit-content "
					onClick={() => onClick({ data: [param], status: { label: "delete" } })}>
					<i className="mdi mdi-delete"></i>
				</span>
			) : (
				""
			)}

			{active?.show ? (
				<Link
					onClick={(e) => {
						e.preventDefault();
						navigate(
							id
								? newRoute.base + newRoute.show + "/" + (currentId || param?.id) + "/" + id + (endPath ? `/${endPath?.(param)}` : "")
								: newRoute.base + newRoute.show + "/" + (currentId || param?.id) + (endPath ? `/${endPath?.(param)}` : ""),
							{ state: { query: QueryString, route: baseRoute || newRoute } }
						);
					}}
					to={
						id
							? newRoute.base + newRoute.show + "/" + (currentId || param?.id) + "/" + id + (endPath ? `/${endPath?.(param)}` : "")
							: newRoute.base + newRoute.show + "/" + (currentId || param?.id) + (endPath ? `/${endPath?.(param)}` : "")
					}
					className=" btn btn-warning shadow btn-xs sharp mr-1 action-btn rounded p-2 height-fit-content ">
					<i className="mdi mdi-eye"></i>
				</Link>
			) : (
				""
			)}
			{active?.print ? (
				<>
					<span
						className="btn btn-success shadow btn-xs sharp pointer action-btn mdi rounded p-2 height-fit-content "
						onClick={printDiv}
						// onClick={() => onClick({ data: [param], status: { label: "print" } })}
					>
						<i className="mdi mdi-printer"></i>
					</span>
					{/* <Print element={componentRef}  {...{ name: param?.name || param?.title || "print", }} /> */}
					<div
						style={{
							position: "absolute",
							top: "-10000vh",
							// ,opacity: 0
						}}>
						<Factor ref={componentRef} {...param} propData={props} />
					</div>
				</>
			) : (
				""
			)}
		</div>
	);
};

export default TableAction;
