import { ADMIN_REDIRECT } from "enumerations/enums";
import routes from "operations/routing/routes";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import checkComponentRoute from "utils/checkComponentRoute";
import getDataInObjectDepth from "utils/getDataInObject";
import Add from "./Add";
import { ROUTE_NAME, PERMISSION } from "./enum";
import List from "./List";

const Product = () => {
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

	const adminState = useSelector((state) => state.admin);
	const redirect = ADMIN_REDIRECT(adminState?.data);

	return (
		<Routes>
			<Route index element={<Navigate to={newRoute.base + newRoute.list} />} />
			<Route path={newRoute.list} element={<List />} />
			<Route path={newRoute.add} element={checkComponentRoute(PERMISSION, "create", adminState, redirect, <Add />)} />
			<Route path={`${newRoute.add}/:id`} element={checkComponentRoute(PERMISSION, "create", adminState, redirect, <Add copy />)} />
			<Route path={`${newRoute.edit}/:id`} element={checkComponentRoute(PERMISSION, "edit", adminState, redirect, <Add />)} />
		</Routes>
	);
};

export default Product;
