import actionVerifyCode from "containers/Verify/redux/store/action";
import actionToken from "redux/store/token/action";
import { put, call, takeEvery, all, delay } from "redux-saga/effects";
import { changeError } from "utils/errors";
import actionTypes from "../actionTypes";
import action from "../store/action";
import network from "lib/network";
import api from "operations/network/api";
// /store/action
import Cookies from 'universal-cookie';

import actionUser from "redux/store/admin/action";

function* postSaga({ params }) {
  yield put(actionVerifyCode.initial());
  console.log({ params });
  try {
    const res = yield call(network.post, { url: api.auth.login, params });
    console.log({ res });
    const cookies = new Cookies();




    if (!res.data?.verifyRequired) {
      yield put(actionToken.receive(res.data));
      yield put(action.receive(res.data));
      yield cookies.set("accessToken", res.data.access_token, { path: "/" });
      yield cookies.set("role", res.data.role, { path: "/" });
      yield put(actionUser.request());

    } else {
      yield put(action.receive("verify"));
      yield put(actionVerifyCode.verifyData({ ...params, login: true }));
    }



    yield delay(2000);
    yield put(action.initial());
  } catch (error) {
    console.log({ error });
    yield put(action.failure(changeError(error)));
    yield delay(2000);
    yield put(action.initial());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.LOGIN_REQUEST, postSaga)]);
}
