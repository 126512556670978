import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import TranslateByObject from "components/TranslateByObject";
import {
  DRINK_DATA,
  DRINK_DATA_OBJECT,
  OPERATION_OBJECT,
  OPERATION_SAID_OBJECT,
  PLACEHOLDER_IMAGE,
} from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) =>
  // columns: ["Name", "Network type", "Default", "Actions"],
  // body: data?.map((param) => {
  //     return [
  //         param.name,
  //         param.network_type,
  //         (param.is_default)?.toString(),
  //         () => {
  //             return (
  //                 <div className="d-flex">
  //                     <Link to={routes[ROUTE_NAME].edit + '/' + param.id} className="btn btn-primary shadow btn-xs sharp mr-1"  >
  //                         <i className="fa fa-pencil"></i>
  //                     </Link>
  //                     <span className="btn btn-danger shadow btn-xs sharp pointer" onClick={() => onClick({ param, type: 'delete' })} >
  //                         <i className="fa fa-trash pointer"></i>
  //                     </span>
  //                 </div>
  //             )
  //         }
  //     ]
  // }),
  [
    // {
    //   header: "Image",
    //   Component: ({ param }) => {
    //     return (
    //       <>
    //         <span className="d-block mb-1">
    //           <img src={param?.image?.completedUrl || PLACEHOLDER_IMAGE} className="image-fluid" />
    //         </span>
    //       </>
    //     );
    //   },
    // },

    {
      header: "hotel",
      Component: ({ param }) => {
        const { base, edit } = routes.hotel;
        return param.mpu?.hotel?.length ? param.mpu?.hotel?.map((x) => {
          return x ? (
            <>
              <br />
              <div className="pt-2"></div>
              <Link to={`${base + edit}/${x.id}`} target="_blank">
                {" "}
                {x?.name}{" "}
              </Link>
              <br />
            </>
          ) : ""
        }) : "Hepsi"


      },
    },

    {
      header: "category",
      Component: ({ param }) => {
        const { base, edit } = routes.category;

        return param.mpu?.category?.length ? param.mpu?.category?.map((x) => {
          return x ? (
            <>
              <br />
              <div className="pt-2"></div>
              <Link to={`${base + edit}/${x.id}`} target="_blank">
                {" "}
                {x?.name}{" "}
              </Link>
              <br />
            </>
          ) : ""
        }) : "Hepsi"
        // return param.mpu.category ? (
        //   <Link to={`${base + edit}/${param.mpu.category.id}`} target="_blank">
        //     {" "}
        //     {param.mpu.category?.name}{" "}
        //   </Link>
        // ) : (
        //   "Hepsi"
        // );
      },
    },

    // {
    //   header: "product",
    //   Component: ({ param }) => {
    //     const { base, edit } = routes.product;

    //     // return param.mpu?.product?.length ? param.mpu?.product?.map((x) => {
    //     //   return x ? (
    //     //     <>
    //     //       <br />
    //     //       <div className="pt-2"></div>
    //     //       <Link to={`${base + edit}/${x.id}`} target="_blank">
    //     //         {" "}
    //     //         {x?.name}{" "}
    //     //       </Link>
    //     //       <br />
    //     //     </>
    //     //   ) : ""
    //     // }) : "Hepsi"

    //     return param.mpu.product ? (
    //       <Link to={`${base + edit}/${param.mpu.product.id}`} target="_blank">
    //         {" "}
    //         {param.mpu.product?.name}{" "}
    //       </Link>
    //     ) : (
    //       "Hepsi"
    //     );
    //   },
    // },
    {
      header: "delivery",
      Component: ({ param }) =>
        <TranslateByObject {...((param?.mpu?.delivery && DRINK_DATA_OBJECT[param.mpu.delivery]) && DRINK_DATA_OBJECT[param.mpu.delivery])} /> ||
        "Hepsi",
    },
    {
      header: "operation",
      Component: ({ param }) =>
        <TranslateByObject {...((param?.mpu?.operation && OPERATION_OBJECT[param.mpu.operation]) && OPERATION_OBJECT[param.mpu.operation])} /> ||
        "Hepsi",
    },
    {
      header: "operationSide",
      Component: ({ param }) =>
        (
          <TranslateByObject
            {...((param?.mpu?.operationSide && OPERATION_SAID_OBJECT[param.mpu.operationSide]) && OPERATION_SAID_OBJECT[param.mpu.operationSide])}
          />
        ) || "Hepsi",
    },

    {
      header: "operationUnit",
      Component: ({ param }) => param?.mpu?.operationUnit,
    },

    // {
    //   header: "Created by",
    //   Component: ({ param }) => `${param?.createdBy?.firstName + param?.createdBy?.lastName || "Unknown"}`,
    // },
    {
      header: "createdAt",
      Component: ({ param }) => isoToDate(param.createdAt),
    },
    // {
    //   header: "status",
    //   Component: ({ param }) => <TableStatus {...{ param, onClick }} />,
    // },
    // {
    //   header: "actions",
    //   Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
    // },
  ];
