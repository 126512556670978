import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import { convertTimeTo24HourFormat } from "utils/convertor";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) =>
	// columns: ["Name", "Network type", "Default", "Actions"],
	// body: data?.map((param) => {
	//     return [
	//         param.name,
	//         param.network_type,
	//         (param.is_default)?.toString(),
	//         () => {
	//             return (
	//                 <div className="d-flex">
	//                     <Link to={routes[ROUTE_NAME].edit + '/' + param.id} className="btn btn-primary shadow btn-xs sharp mr-1"  >
	//                         <i className="fa fa-pencil"></i>
	//                     </Link>
	//                     <span className="btn btn-danger shadow btn-xs sharp pointer" onClick={() => onClick({ param, type: 'delete' })} >
	//                         <i className="fa fa-trash pointer"></i>
	//                     </span>
	//                 </div>
	//             )
	//         }
	//     ]
	// }),
	[
		{
			header: "systemStartTime",
			Component: ({ param }) => convertTimeTo24HourFormat(param.systemStartTime) || "-",
		},
		{
			header: "systemEndTime",
			Component: ({ param }) => convertTimeTo24HourFormat(param.systemEndTime) || "-",
		},
		{
			header: "currentNotWorkingReason",
			Component: ({ param }) => param.currentNotWorkingReason || "-",
		},
		{
			header: "isSystemWorking",
			Component: ({ param }) => <CheckedComponent checked={param.isSystemWorking} />,
		},
		{
			header: "createdAt",
			Component: ({ param }) => isoToDate(param.createdAt),
		},
		{
			header: "Actions",
			Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
		},
	];
