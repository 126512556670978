import CheckBox from "components/CheckBox";
import FieldText from "components/FieldText";
import ModalLayout from "components/ModalLayout";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import DropZoonUpload from "../DropZoonUpload";

const UploadDesign = ({ handleSet }) => {
	const [toggleModal, setToggleModal] = useState();
	const [image, setImage] = useState();
	const [checked, setChecked] = useState(false);
	const handleShowModal = () => setToggleModal((p) => !p);

	const handleSetColor = () => {};

	const handleChange = (e) => {
		console.log("* * * handleChange * * *", { e });

		// alert();
		setChecked(e.target.checked);
	};
	const handleSubmit = (e) => {
		// e.preventDefault();
		// const formData=new FormData(e.target)
		// formData.get("")
		handleSet({
			image,
			checked,
		});
		handleShowModal();
		setImage();
		setChecked();
	};

	return (
		<>
			<ModalLayout title={`Room Numbers `} show={toggleModal} setShow={setToggleModal} classNames={{ content: "bg-white" }}>
				<form className="p-3 bg-white">
					<Row>
						<Col>
							<DropZoonUpload {...{ image, setImage }} />

							<div className="mt-5">
								<CheckBox onChange={handleChange} label={"design text is light!"} value={checked} />
							</div>
							<div className="mt-5">
								<Button onClick={handleSubmit}>{"Accept"}</Button>
							</div>
						</Col>
					</Row>
				</form>
			</ModalLayout>
			<div className="mt-5 text-center">
				{
					// dataById?.data?.data?.roomNumbers?.length > 2 ?
					<Button onClick={handleShowModal}>{"Upload your design"}</Button>
					// : ""
				}
			</div>
		</>
	);
};
export default UploadDesign;
