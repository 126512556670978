import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute, ...props }) => [
	{
		header: "hotel",
		Component: ({ param }) => {
			const { base, edit } = routes.hotel;
			return param.hotel ? (
				<Link to={`${base + edit}/${param.hotel.id}`} target="_blank">
					{" "}
					{param.hotel?.name}{" "}
				</Link>
			) : (
				"Unknown"
			);
		},
	},
	{
		header: "region",
		Component: ({ param }) => {
			const { base, edit } = routes.region;
			return param.region ? (
				<Link to={`${base + edit}/${param.region.id}`} target="_blank">
					{" "}
					{param.region?.name}{" "}
				</Link>
			) : (
				"Unknown"
			);
		},
	},
	{
		header: "orderCount",
		Component: ({ param }) => param.orderCount,
	},
	{
		header: "scanCount",
		Component: ({ param }) => param.scanCount,
	},
	{
		header: "scannedRooms",
		Component: ({ param }) => param.scannedRooms?.join(","),
	},
	{
		header: "orderedRooms",
		Component: ({ param }) => param.orderedRooms?.join(","),
	},
];
