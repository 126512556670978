import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { STATUSES } from "enumerations";
import { useDispatch } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
// import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import GalleryModal from "components/gallery/GalleryModal";
import styles from "./Gallery.module.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useQueryString from "utils/useQueryString";
import Loading from "components/Loading";
import Select from "react-select";
import swal from "sweetalert";
import RemoveNullObjectValue from "utils/RemoveNullObjectValue";
import PaginationM from "components/PaginationM";
import { useSelector } from "react-redux";
import { PERMISSION } from "./enum";


const Gallery = () => {
  // TODO: separate logics to their own components.
  //  ----------------------------------------- VARIABLES/STATES/ROUTES ----------------------------------------- //
  // const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
  const adminState = useSelector((state) => state.admin);
  const validPermission = adminState?.data?.permissions === true
  const permissions = validPermission || adminState?.data?.permissions?.[PERMISSION]

  let { folderName } = useParams();
  const FOLDER_SLUG = folderName;

  const dispatch = useDispatch();

  const breadcrumbs = [{ title: "Dashboard", link: routes.dashboard.base }, { title: " Galleries" }];

  const statusOptions = STATUSES.slice(0, 3);

  const QueryString = useQueryString({ sort: "desc", limit: 9, page: 1 });
  const { limit, page } = QueryString;

  const location = useLocation();
  const navigate = useNavigate();

  const changeUrl = (params) => ({
    pathname: location.pathname,
    search: new URLSearchParams(RemoveNullObjectValue({ ...QueryString, ...params })).toString(),
  });

  const navigateUrl = (obj) => navigate(changeUrl(obj));

  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [folderOptions, setFolderOptions] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderError, setFolderError] = useState(true);
  const [inputValue, setInputValue] = useState(selectedPhoto ? selectedPhoto.title : "");

  const getting = async () => await axiosClient().get(api["gallery"].list, { params: { ...QueryString } });
  const updatingFile = async (params) =>
    await axiosClient().put(api["gallery"].base + "/edit/" + selectedPhoto.id, params);
  const changeStatus = async (params) => await axiosClient().put(api["gallery"].changeStatus, params);

  //  ----------------------------------------- HANDLERS ----------------------------------------- //
  const handleClickedImageWithIndex = (photo, index) => {
    // handleClickedImage(photo);
    setSelectedPhoto(photo);
    setSelectedPhotoIndex(index);
    setIsEditMode(false);
  };
  const handleNextPhoto = () => {
    if (selectedPhotoIndex < data?.result.length - 1) {
      setSelectedPhoto(data?.result?.[selectedPhotoIndex + 1]);
      setSelectedPhotoIndex(selectedPhotoIndex + 1);
      setIsEditMode(false);
    }
  };
  const handlePreviousPhoto = () => {
    if (selectedPhotoIndex > 0) {
      setSelectedPhoto(data?.result?.[selectedPhotoIndex - 1]);
      setSelectedPhotoIndex(selectedPhotoIndex - 1);
      setIsEditMode(false);
    }
  };

  const handleCancelSelectedImage = () => {
    setSelectedPhoto(null);
    setSelectedPhotoIndex(null);
  };

  const handleUploadButton = () => {
    const requestedData = {
      folder: selectedFolder?.value,
      title: inputValue,
    };
    mutate(requestedData);
  };

  const handleCancelButton = () => {
    resetFields();
  };

  const handleSelectingFolder = (e) => {
    setSelectedFolder(e);
    setFolderError(false);
  };

  const handleInputValue = (e) => {
    setInputValue(e.target.value);
    console.log({ value: e.target.value }, "input value");
  };

  const handleEditButton = () => {
    setIsEditMode(true);
    setInputValue(selectedPhoto.title);
    const foundFolder = folderOptions.find((folder) => folder.slug === FOLDER_SLUG);
    handleSelectingFolder(foundFolder);
  };

  const handleDeleteButton = () => {
    swal({
      title: "Are you sure?",
      text: `You want to delete "${selectedPhoto.title}" ? `,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willBe) => {
      if (willBe) {
        changeStatusMutation.mutate({ data: [selectedPhoto.id], status: 2 });
      }
    });
  };
  //  ----------------------------------------- FUNCTIONS ----------------------------------------- //
  function selectConvert(data) {
    return data?.map((param) => ({ label: param?.name || param?.title, value: param.id, slug: param?.slug }));
  }

  function resetFields() {
    setSelectedFolder(null);
    setFolderError(true);
    setInputValue("");
    setIsEditMode(false);
  }
  //  ----------------------------------------- GETTING QUERY ----------------------------------------- //
  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
  } = useQueryCustom({
    name: `galley_get`,
    url: getting,
    params: { ...QueryString },
    enabled: !!(validPermission || permissions?.list)
  });

  //  ----------------------------------------- GETTING FOLDERS ----------------------------------------- //
  const onSuccessGettingFolders = ({ data }) => {
    setFolderOptions(selectConvert(data?.result));
  };
  // const foldersData = useQueryCustom({
  //   name: "foldersList",
  //   url: getFolders,
  //   onSuccess: onSuccessGettingFolders,
  //   params: { sort: "desc", page: 1, limit: 10, status: 1 },
  // });

  //  ----------------------------------------- UPDATING ----------------------------------------- //
  const onSuccessUpdating = () => {
    toastify.success({ title: "success" });
    resetFields();
    handleCancelSelectedImage();
  };
  const {
    isLoading: isLoadingUpdate,
    data: updatedData,
    mutate,
  } = useMutationCustom({
    url: updatingFile,
    invalidQuery: `galley_get`,
    onSuccess: onSuccessUpdating,
  });

  // ---------------------------------------------- mutation Change Status
  const onSuccessChangeStatusMutation = () => {
    toastify.success({ title: "Success" });
    resetFields();
    handleCancelSelectedImage();
  };

  const changeStatusMutation = useMutationCustom({
    name: "gallery_changeStatus",
    url: changeStatus,
    invalidQuery: `galley_get`,
    onSuccess: onSuccessChangeStatusMutation,
  });

  //  ----------------------------------------- EFFECTS ----------------------------------------- //

  useEffect(() => {
    dispatch(actionBreadcrumbs.set(breadcrumbs));
  }, []);

  //  ----------------------------------------- GALLERY MODAL----------------------------------------- //
  const [showModal, setShowModal] = useState(false);
  const handleGalleryModal = () => {
    setShowModal(!showModal);
  };
  const count = data?.total;

  let showing = page * limit;
  if (showing > count) showing = count;
  //  ----------------------------------------- LOGS ----------------------------------------- //
  console.log({ data, selectedPhoto }, "folder by slug data");
  console.log({ selectedFolder, inputValue }, "folder and input");

  return (
    <div className={styles.mainWrapper}>
      <GalleryModal {...{ showModal, handleModal: handleGalleryModal, isGalleryDisabled: true, FOLDER_SLUG }} />
      <div className="row page-titles mx-0">
        <div className="col-sm-6 p-md-0">
          <div className="pt-2 d-flex pl-3">
            <div className="table-title-wrapper ">
              <h3 className="card-title text-capitalize"> Gallery</h3>
            </div>
          </div>
        </div>
        {validPermission || permissions?.create ?
          <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
            <Button className={`btn btn-success btn-sm mr-2`} type="success" onClick={handleGalleryModal}>
              Upload
            </Button>
          </div>
          : ""}
      </div>
      <div className={`${styles.flexWrapper}`}>
        {data?.result && data?.result?.length ? (
          <div className={`${styles.galleryWrapper} ${selectedPhoto && styles.halfSizeWrapper}`}>
            {data?.result?.map((image, index) => (
              <div key={image.id} className={styles.imageWrapper}>
                <img src={image?.path} alt="gallery photo" onClick={() => handleClickedImageWithIndex(image, index)} />
              </div>
            ))}
          </div>
        ) : isLoading ? (
          "Loading..."
        ) : (
          <div className={styles.noDataWrapper}>
            <h2>There is no Data to Show !</h2>
          </div>
        )}

        <div className={`${styles.imageDetailsWrapper} ${selectedPhoto && styles.showDetailsWrapper}`}>
          <div className={styles.imageDetailsWrapper_imageWrapper}>
            <img src={selectedPhoto?.path} alt="selected photo" />
            <button className={styles.imageDetailsWrapper_button} onClick={handleCancelSelectedImage}>
              <i className="mdi mdi-close" />
            </button>
            <div className={styles.nexPrevBtnWrapper}>
              <button onClick={handlePreviousPhoto}>
                <i className="mdi mdi-chevron-left" />
              </button>
              <button onClick={handleNextPhoto}>
                <i className="mdi mdi-chevron-right" />
              </button>
            </div>
          </div>

          {/* {isEditMode ? (
            <div className={styles.extraInformationWrapper}>
              <h3>Edit :</h3>
              <Form.Group className={styles.formGroup}>
                <div className={"d-flex justify-content-between"}>
                  <label htmlFor={"name"}>Title :</label>
                </div>
                <Form.Control id={"name"} name={"name"} type="text" placeholder="Choose a new title" value={inputValue} onChange={handleInputValue} />
              </Form.Group>
              <Form.Group className={"form-group "}>
                <div className={"d-flex justify-content-between"}>
                  <label htmlFor={"folder"}>Folder * :</label>
                  {folderError && <div className={`error-text`}>Choose please !</div>}
                </div>
                <Select className="custom-selector" placeholder={"Choose a folder"} options={folderOptions} value={selectedFolder} onChange={handleSelectingFolder} />
              </Form.Group>

              <div className={styles.buttonsWrapper}>
                <button className="btn btn-success btn-sm mr-2 btn btn-primary" disabled={isLoadingUpdate} onClick={handleUploadButton}>
                  {isLoadingUpdate ? <Loading size="sm" /> : "Done"}
                </button>
                <button className="btn btn-danger btn-sm btn btn-primary" disabled={isLoadingUpdate} onClick={handleCancelButton}>
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div className={styles.imageDetailsWrapper_detailsWrapper}>
              <div className={styles.textWrapper}>
                <h4>{selectedPhoto?.title}</h4>
                <p>{selectedPhoto?.mimetype}</p>
              </div>
              <div className={styles.btnWrapper}>
                <button className="btn btn-primary btn-info btn-sm" onClick={handleEditButton}>
                  Edit
                </button>
                <button className="btn btn-primary btn-danger btn-sm" onClick={handleDeleteButton}>
                  Delete
                </button>
              </div>
            </div>
          )} */}
        </div>
      </div>

      <Row className="mt-5">
        {count ? (
          <Col sm={6} md={6}>
            {`Showing ${showing || 0} out of ${count || 0} entries`}
          </Col>
        ) : (
          ""
        )}

        <Col sm={6} md={6}>
          <div className="pagination-container">
            <PaginationM
              {...{
                activePage: page,
                pages: Math.ceil(data?.total / limit),
                limited: 2,
              }}
              onClick={(page) => navigateUrl({ page })}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Gallery;
