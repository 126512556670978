import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
	name: "name",
	iban: "iban",
	ibanName: "ibanName",
	shortDesc: "shortDesc",
	desc: "desc",
	stock: "stock",
	listPrice: "listPrice",
	image: "image",
	isNewProduct: "isNewProduct",
	isActive: "isActive",
	isFastDelivery: "isFastDelivery",
	category: "category",
	integrationCode: "integrationCode",
	listOrderNumber: "listOrderNumber",
	commercialTitle: "commercialTitle",
	name: "name",
	taxNo: "taxNo",
	taxAdministration: "taxAdministration",
	address: "address",
	defaultLanguage: "defaultLanguage",
	phoneNumber: "phoneNumber",
	roomCount: "roomCount",
	drink: "drink",
	isActive: "isActive",
	alcohol: "alcohol",
	coldDrink: "coldDrink",
	isOrderLimit: "isOrderLimit",
	commission: "commission",
	orderLimit: "orderLimit",
	serviceCharge: "serviceCharge",
	drinkInvoice: "drinkInvoice",
	foodInvoice: "foodInvoice",
	invoice: "invoice",
	isOnlineActive: "isOnlineActive",
	isCardActive: "isCardActive",
	isCashActive: "isCashActive",
	branch: "branch",
	users: "users",
	email: "email",
	password: "password",
	confirm_password: "confirm_password",
	name: "name",
	lastName: "lastName",
	phoneNumber: "phoneNumber",
	role: "role",
	status: "status",
	image: "image",
	type: "type",
	isEmailNotificationActive: "isEmailNotificationActive",
	isPhoneNotificationActive: "isPhoneNotificationActive",
	isActive: "isActive",
	mpu: "mpu",
	missafir: "missafir",
	missafirRooms: "missafirRooms",
	location: "location",
	latitude: "latitude",
	longitude: "longitude",
	// mpu: {
	//   "categories": "mpu.categories",
	//   "delivery": "mpu.delivery",
	//   "operation": "mpu.operation",
	//   "operationUnit": "mpu.operationUnit",
	//   "operationSide": "mpu.operationSide"
	// },
	region: "region",

	category: "category",
	delivery: "delivery",
	operation: "operation",
	operationUnit: "operationUnit",
	operationSide: "operationSide",
	exceptCategories: "exceptCategories",
	exceptProducts: "exceptProducts",
	includeProducts: "includeProducts",
	languages: "languages",
	roomNumbers: "roomNumbers",
	specialRoom: "specialRoom",
	paymentPeriod: "paymentPeriod",
	setupCost: "setupCost",
	paymentMethod: "paymentMethod",
	address: "address",
	roomNumber: "roomNumber",
};

const users = yup
	.object({
		[fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
		[fieldNames.lastName]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.lastName)).label(fieldNames.lastName),
		[fieldNames.email]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.email)).label(fieldNames.email),
		[fieldNames.password]: yup
			.string()
			// .matches(regex.passwordRegExp, "Password must at least 8 characters, including uppercase,lowercase,number and symbol")
			.label("Password"),
		[fieldNames.confirm_password]: yup.string().oneOf([yup.ref(fieldNames.password), null], "Passwords must match"),
		[fieldNames.role]: yup.object().label(fieldNames.role),
		[fieldNames.phoneNumber]: yup.string().label(fieldNames.phoneNumber),
		[fieldNames.isActive]: yup.object().label(fieldNames.isActive),
		[fieldNames.isEmailNotificationActive]: yup.bool().default(false).label("isEmailNotificationActive"),
		[fieldNames.isPhoneNotificationActive]: yup.bool().default(false).label("isPhoneNotificationActive"),
	})
	.defined();

const missafirRooms = yup
	.object({
		[fieldNames.address]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.address)).label(fieldNames.address),
		[fieldNames.roomNumber]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.roomNumber)).label(fieldNames.roomNumber),
	})
	.defined();

export const validation = () =>
	yup.object().shape({
		[fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
		// [fieldNames.iban]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.iban)).label(fieldNames.iban),
		// [fieldNames.ibanName]: yup
		//   .string()
		//   .required(ERRORS.pleaseEnterYour(fieldNames.ibanName))
		//   .label(fieldNames.ibanName),
		[fieldNames.paymentPeriod]: yup
			.object()
			.required(ERRORS.pleaseEnterYour(fieldNames.paymentPeriod))
			.label(fieldNames.paymentPeriod)
			.label(fieldNames.paymentPeriod),
		[fieldNames.isCardActive]: yup.bool().default(false).label("isCardActive"),
		[fieldNames.isCashActive]: yup.bool().default(false).label("isCashActive"),
		[fieldNames.isOnlineActive]: yup.bool().default(false).label("isOnlineActive"),
		[fieldNames.isOrderLimit]: yup.bool().default(false).label("isOrderLimit"),
		[fieldNames.isFastDelivery]: yup.bool().default(false).label("isFastDelivery"),
		[fieldNames.isNewProduct]: yup.bool().default(false).label("isNewProduct"),
		[fieldNames.serviceCharge]: yup.bool().default(false).label("serviceCharge"),
		[fieldNames.alcohol]: yup.mixed().label("alcohol"),
		[fieldNames.coldDrink]: yup.mixed().label("coldDrink"),
		[fieldNames.roomNumbers]: yup.array().when(fieldNames.specialRoom, {
			is: true,
			then: yup.array().min(1, `Room numbers are required when specialRoom is true`),
		}),

		// [fieldNames.missafirRooms]: yup
		// 	.array()
		// 	.of(missafirRooms)
		// 	.when(fieldNames.missafir, {
		// 		is: true,
		// 		then: yup.array().min(1, `missafir Rooms are required when missafir is true`),
		// 	}),
		// .test(
		//   'roomCount-min-length',
		//   `roomNumbers must have a minimum length of \${${fieldNames.roomCount}}`,
		//   function (value) {
		//     const newVal = value?.filter((x) => x)
		//     const roomCount = this.parent[fieldNames.roomCount];
		//     console.log({ value, newVal, roomCount }, "error");
		//     return !newVal || !newVal.length || newVal.length >= roomCount;
		//   }
		// )
		// [fieldNames.shortDesc]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.shortDesc)).label(shortDesc.name),
		// [fieldNames.desc]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.desc)).label(fieldNames.name),
		// [fieldNames.stock]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stock)).label(fieldNames.name),
		[fieldNames.users]: yup.array().of(users).required(ERRORS.pleaseEnterYour(fieldNames.users)),
		[fieldNames.languages]: yup.array().max(8).required(ERRORS.pleaseEnterYour(fieldNames.languages)),
		[fieldNames.paymentMethod]: yup.object().label(fieldNames.paymentMethod),

		// [fieldNames.lastName]: yup.string().required(ERRORS.pleaseEnterYour("Last name")).label("LastName"),
		// [fieldNames.email]: yup.string().required(ERRORS.pleaseEnterYour("Email")).label("email"),
		// [fieldNames.password]: yup
		//   .string()
		//   .matches(regex.passwordRegExp, "Password must at least 8 characters, including uppercase,lowercase,number and symbol")
		//   .label("Password"),
		// [fieldNames.confirm_password]: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
		// [fieldNames.role]: yup.object().label("role"),
		// [fieldNames.phoneNumber]: yup.string().label("mobile-number"),
		// [fieldNames.isActive]: yup.object().label("status"),
		// [fieldNames.type]: yup.object().required(ERRORS.pleaseSelectYour("Type")).label("Type"),
		// [fieldNames.image]: yup.object().nullable().required(ERRORS.pleaseSelectYour("image")).label("image"),
	});
