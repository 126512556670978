
import production from "./production.json";
import develop from "./development.json";

let isProduction = process.env.REACT_APP_NODE_ENV == "production";
let isDevelop = process.env.REACT_APP_NODE_ENV == "development";

const configs = {
    isProduction,
    isDevelop,
    data: isProduction ? production : develop
}
console.log({ configs });

export default configs