// import CheckedComponent from "components/CheckedComponent";
// import TableAction from "components/TableAction";
// import TableStatus from "components/TableStatus";
// import { INVOICE_DATA_OBJECT, PLACEHOLDER_IMAGE } from "enumerations";
// import routes from "operations/routing/routes";
// import { Link } from "react-router-dom";

// import {
//   changePriceFormat,
//   numberWithCommas,
// } from "../../../../utils/calculators";

// export const tableData = ({ onClick, newRoute, active, isHotel }) => {
//   let data = [
//     {
//       header: "hotel",
//       Component: ({ param }) => {
//         const { base, edit } = routes.hotel;
//         return param.hotel ? (
//           <Link to={`${base + edit}/${param.hotel.id}`} target="_blank">
//             {" "}
//             {param.hotel?.name}{" "}
//           </Link>
//         ) : (
//           "-"
//         );
//       },
//     },
//     {
//       header: "orderCount",
//       Component: ({ param }) => numberWithCommas(param.orderCount),
//     },
//     {
//       header: "commission",
//       Component: ({ param }) =>
//         "₺ " + changePriceFormat(param?.hotel?.commission, 2),
//     },
//     {
//       header: "totalAmount",
//       Component: ({ param }) => "₺ " + changePriceFormat(param.totalAmount, 2),
//     },
//   ];

//   if (isHotel)
//     data = [
//       {
//         header: "name",
//         Component: ({ param }) => param.name,
//       },
//       {
//         header: "commission",
//         Component: ({ param }) => "₺ " + changePriceFormat(param.commission, 2),
//       },
//       {
//         header: "orderNumber",
//         Component: ({ param }) => param.orderNumber,
//       },
//       // {
//       //   header: "kdv",
//       //   Component: ({ param }) => numberWithCommas(param.kdv),
//       // },
//       {
//         header: "price",
//         Component: ({ param }) => "₺ " + changePriceFormat(param.price, 2),
//       },
//     ];

//   return data;
// };

// import { INVOICE_DATA_OBJECT, PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import { Button } from "react-bootstrap";
import { changePriceFormat, numberWithCommas } from "utils/calculators";
import { checkPayment } from "containers/FinanceHotels/enum";
import TableAction from "components/TableAction";

export const tableData = ({ onClick, newRoute, ...props }) => {
	let data = [
		{
			header: "hotel",
			Component: ({ param }) => {
				const { base, edit } = routes.hotel;
				return param.hotel ? (
					<Link to={`${base + edit}/${param.hotel.id}`} target="_blank">
						{" "}
						{param.hotel?.name}{" "}
					</Link>
				) : (
					"-"
				);
			},
		},
		{
			header: "region",
			Component: ({ param }) => {
				const { base, edit } = routes.region;
				return param.region ? (
					<Link to={`${base + edit}/${param?.region?.id}`} target="_blank">
						{" "}
						{param?.region?.name}{" "}
					</Link>
				) : (
					"-"
				);
			},
		},
		{
			header: "orderCount",
			Component: ({ param }) => numberWithCommas(param.orderCount),
		},
		{
			header: "commission",
			Component: ({ param }) => "₺ " + changePriceFormat(param?.totalCommission, 2),
		},
		{
			header: "totalAmount",
			Component: ({ param }) => "₺ " + changePriceFormat(param.totalAmount, 2),
		},
		{
			header: "paymentMethod",
			Component: ({ param, t }) => t(checkPayment(param?.hotel?.paymentMethod)) || "-",
		},
		{
			header: "roomCount",
			Component: ({ param }) => numberWithCommas(param?.hotel?.roomCount) || "-",
		},
		{
			header: "Actions",
			Component: ({ param }) => <TableAction {...{ param, newRoute, onClick, ...props }} />,
		},
	];

	if (props.isHotel)
		data = [
			// {
			//   header: "product",
			//   Component: ({ param }) => {
			//     // const { base, edit } = routes.product;

			//     return (
			//       <div className="">
			//         {param.products?.map((x) => {

			//           return (
			//             <ul className="" key={x.name}>
			//               <li className="d-flex text-nowrap justify-content-start align-items-center ">
			//                 <strong style={{ minWidth: "100px" }} className="mr-2 text-start">
			//                   {"name"}
			//                 </strong>

			//                 {" "}
			//                 <div className="mx-2">{":"}</div>
			//                 <div className="text-start">
			//                   {/* <Link to={`${base + edit}/${x?.id}`} target="_blank"> */}
			//                   {x.name}
			//                   {/* </Link> */}
			//                 </div>{" "}
			//               </li>
			//               <li className="d-flex text-nowrap justify-content-start align-items-center ">
			//                 <strong style={{ minWidth: "100px" }} className="mr-2 text-start">
			//                   {"commission"}
			//                 </strong>{" "}
			//                 <div className="mx-2">{":"}</div>
			//                 <div className="text-start">{x.commission}</div>{" "}
			//               </li>
			//               <li className="d-flex text-nowrap justify-content-start align-items-center ">
			//                 <strong style={{ minWidth: "100px" }} className="mr-2 text-start">
			//                   {"price"}
			//                 </strong>{" "}
			//                 <div className="mx-2">{":"}</div>
			//                 <div className="text-start">{x.price}</div>{" "}
			//               </li>
			//               <li className="d-flex text-nowrap justify-content-start align-items-center ">
			//                 <strong style={{ minWidth: "100px" }} className="mr-2 text-start">
			//                   {"createdAt"}
			//                 </strong>{" "}
			//                 <div className="mx-2">{":"}</div>
			//                 <div className="text-start">{isoToDate(x.createdAt)}</div>{" "}
			//               </li>
			//               <hr />
			//             </ul>
			//           );
			//         })}
			//       </div>
			//     )

			//   },
			// },
			{
				header: "products",
				Component: ({ param }) => {
					return (
						<Button variant="info" size="sm">
							view
						</Button>
					);
				},
				subTable: {
					getData: (param) => param?.products,
					options: [
						{
							header: "name",
							Component: ({ param }) => {
								const { base, edit } = routes.product;
								// const currRegion = props?.isAdmin ? param?.product : param?.hotel?.product

								return (
									// props?.isAdmin ?
									//   <Link to={`${base + edit}/${curr?.id}`} target="_blank">
									//     {" "}
									//     {param?.name}{" "}
									//   </Link>
									//   :
									param?.name
								);
							},
						},
						{
							header: "commission",
							Component: ({ param }) => param?.commission || "-",
						},
						{
							header: "price",
							Component: ({ param }) => param?.price || "-",
						},
					],
				},
			},
			{
				header: "totalCommission",
				Component: ({ param }) => "₺ " + changePriceFormat(param.totalCommission, 2),
			},
			{
				header: "orderNumber",
				Component: ({ param }) => param.orderNumber,
			},
			// {
			//   header: "kdv",
			//   Component: ({ param }) => numberWithCommas(param.kdv),
			// },
			{
				header: "totalPrice",
				Component: ({ param }) => "₺ " + changePriceFormat(param.totalPrice, 2),
			},
			{
				header: "Actions",
				Component: ({ param }) => <TableAction {...{ param, newRoute, onClick, ...props }} />,
			},
		];

	return data;
};
