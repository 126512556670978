import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { Children, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import { CATEGORY_DATA, CATEGORY_OBJECT, STATUSES } from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import CheckBox from "components/CheckBox";
import CategorySelector, { convertCategoryData } from "../CategorySelector";
import GalleryComponent from "components/gallery/GalleryComponent";
import LanguageSelector from "containers/Language/LanguageSelector";
import { useTranslation } from "react-i18next";

// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";

const Add = () => {
	const [showPass, setShowPass] = useState(false);
	const [showConfirmPass, setShowConfirmPass] = useState(false);
	const [type, setType] = useState(null);
	const { t } = useTranslation();
	const location = useLocation();

	// ------------------------------------ route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const previousQuery = location?.state?.query;
	const params = new URLSearchParams(previousQuery || {});
	const typeOptions = [
		{ label: "User", value: "USER" },
		{ label: "Admin", value: "ADMIN" },
	];

	// ----------------------------------------------------------------------------- Fetching Functions
	const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
	const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
	const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);
	// const getRoles = async () => await axiosClient().get(api["role"].list);

	const navigation = useNavigate();
	let { id } = useParams();
	const URL_ID = id ? id : "";
	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(validation(URL_ID)),
		mode: "onSubmit",
	});

	const watchIsClickDisabled = watch(fieldNames.isClickDisabled);
	// ----------------------------------------------------------------------------- Mutation
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		const params = new URLSearchParams(previousQuery || {});
		navigation(newRoute.base + newRoute.list + `?${params.toString()}`);
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: URL_ID ? updating : creating,
		invalidQuery: `getById_${API_NAME}`,
		onSuccess: onSuccessMutating,
	});

	// ----------------------------------------------------------------------------- Query By ID
	const onSuccessDataById = ({ data }) => {
		let params = { ...data };
		let selectedStatus = STATUSES.find((item) => item.value === params.isActive);
		console.log({ params });
		handleSelectThumbnail({
			_id: params?.image?._id,
			completedUrl: params?.image?.completedUrl,
			title: params?.image?.title,
		});
		reset({
			...params,
			// role: { label: params?.role, value: params?.role },
			isActive: selectedStatus,
			parent: params.parent && convertCategoryData(params.parent),
			redirect: params.redirect && convertCategoryData(params.redirect),
			categoryType: CATEGORY_OBJECT[params.categoryType],
			translations: params?.translations?.map((x) => ({
				name: x.name,
				language: { label: x.language, value: x.language },
				// listOrderNumber: x.listOrderNumber,
			})),
			// image: { _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title },
		});
	};
	const dataById = useQueryCustom({
		name: `getById_${API_NAME}`,
		url: getById,
		onSuccess: onSuccessDataById,
		enabled: !!URL_ID,
	});

	//  ----------------------------------------- GETTING ROLES ----------------------------------------- //
	// const onSuccessRoles = ({ data }) => {
	//   setRolesOption(convertToLabelValue(data?.result));
	// };
	// const rolesData = useQueryCustom({
	//   name: "rolesData",
	//   url: getRoles,
	//   onSuccess: onSuccessRoles,
	//   params: { sort: "desc", page: 1, limit: 10, status: 1 },
	// });
	// --------------------------------------- Submit Form
	const onSubmit = () => {
		const { translations, ...values } = getValues();
		const requestData = {
			...values,
			isNewCategory: !!values.isNewCategory,
			isClickDisabled: !!values.isClickDisabled,
			parent: values.parent?.value,
			isActive: values.isActive ? values.isActive.value : "1",
			image: values.image?.path || values.image,
			categoryType: values.categoryType.value,
			redirect: (!values.isClickDisabled && values.redirect?.value) || null,
			translations: translations?.map((x) => ({
				language: x.language?.value || x.language,
				name: x.name,
				// listOrderNumber: x.listOrderNumber,
			})),
		};
		console.log({ requestData });
		mutate(requestData);
	};

	// ----------------------------------------------------------------------------- Constance
	const dispatch = useDispatch();
	const button = {
		title: `Back To ${COMPONENT_NAMES[0]} List`,
		link: newRoute.base + newRoute.list,
	};

	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: COMPONENT_NAMES[0], link: newRoute.base },
		{
			title: URL_ID ? `Edit / ${dataById?.data?.data?.result?.name} ${dataById?.data?.data?.result?.name}` : "Add",
		},
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, [dataById]);
	// ----------------------------------------------------------------------------- Log
	const values = getValues();
	console.log({ dataById, values }, "Add");
	console.log({ URL_ID });
	console.log({ errors }, "errors");

	// ----------------------------------------------------------------------------- UseEffects

	//  ----------------------------------------- GALLERY ----------------------------------------- //
	const [showModal, setShowModal] = useState(false);
	const [thumbnail, setThumbnail] = useState(null);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};
	const handleSelectThumbnail = (thumbnail) => {
		setValue(fieldNames.image, thumbnail, {
			shouldValidate: true,
		});
		setThumbnail(thumbnail);
	};

	const defaultData = { name: "" };

	const addNewtranslation = () => {
		const values = getValues();
		const newData = values[fieldNames.translations] ? [...values[fieldNames.translations], { ...defaultData }] : [{ ...defaultData }];
		console.log({ newData });
		setValue(fieldNames.translations, newData);
		// reset({ ...values, [fieldNames.locale]: values[fieldNames.locale] ? [...values[fieldNames.locale], { ...defaultEpisode }] : [{ ...defaultEpisode }, { ...defaultEpisode }] })
	};

	return (
		<div>
			<GalleryModal
				{...{
					showModal,
					handleModal: handleGalleryModal,
					handleClickedImage: handleSelectThumbnail,
					selectedPhoto: thumbnail,
				}}
			/>
			<PageTitle title={URL_ID ? `Edit  ${COMPONENT_NAMES[0]}` : `Add  ${COMPONENT_NAMES[0]}`} button={button} />
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				<div className="row mt-5">
					<Col md="8">
						<div className="col-12 border p-4">
							<div className="">
								<div className="b-1">
									<h4 className="">{"Basic Information"}</h4>
								</div>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form">
											<FieldText
												require
												{...{
													name: fieldNames.name,
													register,
													placeholder: "name",
													label: "Name ",
													errors,
												}}
											/>

											<FieldText
												require
												{...{
													name: fieldNames.listOrderNumber,
													register,
													placeholder: "listOrderNumber",
													label: "listOrderNumber ",
													errors,
													type: "number",
												}}
											/>
											<FieldText
												{...{
													name: fieldNames.slug,
													register,
													placeholder: "slug",
													label: "slug ",
													errors,
												}}
											/>
											{/* <FieldText
												{...{
													name: fieldNames.redirect,
													register,
													placeholder: "redirect",
													label: "redirect ",
													errors,
												}}
											/> */}

											<FieldSelect
												{...{
													Controller,
													name: fieldNames.categoryType,
													register,
													label: "categoryType",
													errors,
													control,
													options: CATEGORY_DATA,
												}}
											/>

											<CategorySelector
												{...{
													name: fieldNames.parent,
													register,
													label: "parent",
													errors,
													control,
												}}
											/>

											<CheckBox
												{...{
													Controller,
													name: fieldNames.isNewCategory,
													register,
													label: "isNewCategory",
													errors,
													control,
												}}
											/>
											<CheckBox
												{...{
													Controller,
													name: fieldNames.isClickDisabled,
													register,
													label: "isClickDisabled",
													errors,
													control,
												}}
											/>

											{!watchIsClickDisabled ? (
												<CategorySelector
													require
													isClearable
													{...{
														name: fieldNames.redirect,
														register,
														label: "redirect",
														errors,
														control,
													}}
												/>
											) : (
												""
											)}

											<FieldSelect
												{...{
													Controller,
													name: fieldNames.isActive,
													register,
													label: "Status",
													errors,
													control,
													options: STATUSES,
												}}
											/>

											<GalleryComponent
												isRequired
												{...{
													handleModal: handleGalleryModal,
													selectedPhoto: thumbnail,
													labe: "image",
													name: fieldNames.image,
													Controller,
													control,
													errors,
													register,
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 border p-4 mt-4">
							<div className="">
								{/* <div className="b-1">
                  <h4 className="">{"translations"}</h4>

                </div> */}
								<Row className="b-1">
									<Col lg={6}>
										<h2 className="">translations</h2>
									</Col>
									<Col lg={6} className="d-flex justify-content-end">
										<Button onClick={addNewtranslation} className={"d-flex align-items-center"} size="sm">
											<div className={"gallery-add-title"}>Add translate</div>
											<div className={"ml-1"}>+</div>
										</Button>
									</Col>
								</Row>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form"></div>
										<Controller
											name={fieldNames.translations}
											control={control}
											render={({ field: { onChange, value } }) => {
												console.log({ value });

												return Children.toArray(
													value?.map?.((locale, index) => {
														const values2 = getValues();

														// function changeHandle(param) {
														//   console.log({ param });
														//   let values = value ? [...value] : [{ ...defaultData }];
														//   values[index][param.name] = param.value;
														//   onChange(values);
														// }

														// function handleChangeInput(e) {
														//   let target = e.target;
														//   console.log({ target });
														//   changeHandle({ name: target.name, value: target.checked || target.value });
														// }
														// function handleChangeSelect(e) {
														//   // let target = e.target;
														//   console.log({ e });
														//   changeHandle({ name: e.name, value: { label: e.label, value: e.value } });
														// }

														const removeClick = (index) => {
															let values = getValues();
															if (!values[fieldNames.translations]) return;
															const newValues = values[fieldNames.translations].filter((param, i) => i !== index);
															setValue(fieldNames.translations, newValues);
														};

														return (
															<Row className="translation-row" key={value?.name || index}>
																{index >= 0 ? (
																	<>
																		<Col lg={10}>
																			<h5>translation{index + 1}</h5>
																		</Col>
																		<Col lg={2}>
																			<div>
																				<Button
																					color="warning"
																					className={"d-flex align-items-center"}
																					size="sm"
																					onClick={(e) => {
																						e.preventDefault();
																						removeClick(index);
																					}}>
																					remove
																				</Button>
																			</div>
																		</Col>
																		<Col lg={12}>
																			<hr style={{ marginBottom: 0 }} />
																		</Col>
																	</>
																) : (
																	""
																)}
																{/* <Col md={12} lg={5}>
                                  <FormSelectDefaultWithControl change={languageSelectHandler} value={locale.localeLanguage} isRequire isSearchable {...{ control, label: "language", name: "localeLanguage", options: [] }} />
                                </Col> */}

																<FieldText
																	require
																	{...{
																		name: `translations[${index}].name`,
																		placeholder: "name",
																		label: "name ",
																		error: errors.translations?.[index]?.name,
																		// value: value?.[index]?.[fieldNames.translate_name],
																		// onChange: handleChangeInput,
																		control,
																	}}
																/>
																{/* <FieldText
																	require
																	{...{
																		name: `translations[${index}].listOrderNumber`,
																		placeholder: "listOrderNumber",
																		label: "listOrderNumber ",
																		error: errors.translations?.[index]?.listOrderNumber,
																		// value: value?.[index]?.[fieldNames.translate_name],
																		// onChange: handleChangeInput,
																		control,
																		type: "number",
																	}}
																/> */}

																<LanguageSelector
																	{...{
																		name: `translations[${index}].language`,
																		label: "language",
																		// value: value?.[index]?.[fieldNames.translate_language],
																		error: errors.translations?.[index]?.language,

																		control,
																		// onChange: (e) => handleChangeSelect({ ...e, name: fieldNames.translate_language }),
																	}}
																/>
															</Row>
														);
													})
												);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</Col>

					<Col md="4">
						<div className="row">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Actions"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											{/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

											{/* <hr /> */}

											<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
												{!isLoading ? t("publish") : "Loading..."}
											</Button>
										</div>
									</div>
								</div>
							</Col>
						</div>
					</Col>
				</div>
			</form>
		</div>
	);
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
