import routes from "operations/routing/routes";
import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import getDataInObjectDepth from "utils/getDataInObject";
import Add from "./Add";
import { ROUTE_NAME, PERMISSION } from "./enum";
import List from "./List";
import ProtectedRoute from "components/ProtectedRoute";
import { ADMIN_PERMISSIONS, ADMIN_REDIRECT } from "enumerations/enums";
import { useSelector } from 'react-redux';
import checkComponentRoute from "utils/checkComponentRoute";


// const routing = [
//   { index:true, element: <Navigate to={newRoute.base + newRoute.list} /> },
//   { path: routes.region.all, element: <Region />, permission: true, name: "region" },
// ]


const Region = () => {

  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

  const adminState = useSelector((state) => state.admin);
  const redirect = ADMIN_REDIRECT(adminState?.data)




  return (
    <Routes>
      <Route index element={<Navigate to={newRoute.base + newRoute.list} />} />
      <Route path={newRoute.list} element={
        <List />
      } />
      <Route path={newRoute.add} element={

        checkComponentRoute(PERMISSION, "create", adminState, redirect, <Add />)
        // <Add />
      } />
      <Route path={`${newRoute.edit}/:id`} element={
        checkComponentRoute(PERMISSION, "edit", adminState, redirect, <Add />)
        // <Add />
      } />
    </Routes>
  );
};

export default Region;
