import { Button, Col, Modal } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { fieldNames, validation } from "./validation";
import { useForm } from "react-hook-form";
import FieldText from "components/FieldText";
import ModalLayout from "components/ModalLayout";
import UserSelector from "containers/User/userSelector";


const AssignUser = ({ setShow, show, onSubmit }) => {

    const [t] = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        control,
        reset,
        watch
    } = useForm({
        resolver: yupResolver(validation),
        mode: "onSubmit"
    });

    useEffect(() => {
        if (show) reset({ user: null })
    }, [show])

    const onSubmitted = () => {
        const values = getValues();
        onSubmit(values?.user?.value)
        setShow(false)
    }


    return (

        <ModalLayout
            {...{
                setShow,
                show,
                title: t("assign"),
                size: "sm"
            }}
        >
            <form onSubmit={handleSubmit(onSubmitted)} id={"form-container "} noValidate>
                <UserSelector
                    require
                    {...{
                        name: fieldNames.user,
                        register,
                        placeholder: "user",
                        label: "user",
                        errors,
                        control
                    }}
                />

                <Button className={"d-flex align-items-center"} size="sm" type="submit">
                    <div className={"gallery-add-title"}>
                        {t("submit")}
                    </div>

                </Button>
            </form>
        </ModalLayout>

    )
}

export default AssignUser;