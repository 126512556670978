import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PERMISSION } from "../enum";
import * as hotelMenuEnum from "containers/HotelMenu/enum";
import { tableData } from "./tableInfo";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BranchModal from "components/BranchModal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const List = () => {
	const [checkList, setCheckList] = useState();
	const [showModal, setShowModal] = useState();
	const adminState = useSelector((state) => state.admin);
	const validPermission = adminState?.data?.permissions === true;
	const permissions = validPermission || adminState?.data?.permissions?.[PERMISSION];
	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const newRouteHotelMenu = useMemo(() => getDataInObjectDepth(hotelMenuEnum.ROUTE_NAME, routes), []);
	// ---------------------------------------------- Constance
	const button = {
		title: `Add ${COMPONENT_NAMES[0]}`,
		link: newRoute.base + newRoute.add,
	};
	const buttonTwo = {
		title: `Add ${"menu to all hotel"}`,
		link: newRouteHotelMenu.base + newRouteHotelMenu.add + "/add-item-all-hotels",
	};
	const buttons = [];
	if (permissions?.create || validPermission) buttons.push(buttonTwo, button);
	const QueryString = useQueryString({ sort: "desc" });
	const { page, limit } = QueryString;
	const dispatch = useDispatch();
	const { t } = useTranslation();

	// ---------------------------------------------- fetching functions
	const getting = async () =>
		await axiosClient().get(api[API_NAME].list, {
			params: { ...QueryString },
		});
	const changeStatus = async (params) => {
		console.log(params, "changeStatus");
		await axiosClient().patch(api[API_NAME].changeStatus, params);
	};

	const deleting = async (params) => {
		await axiosClient().delete(api[API_NAME].delete + "/" + params?.id);
	};
	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get`,
		url: getting,
		params: { ...QueryString },
		enabled: !!(validPermission || permissions?.list),
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessDeleteMutation = () => {
		toastify.success({ title: "success" });
	};

	const deleteMutation = useMutationCustom({
		name: `${API_NAME}_delete`,
		url: deleting,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessDeleteMutation,
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessChangeStatusMutation = () => {
		toastify.success({ title: "success" });
	};

	const changeStatusMutation = useMutationCustom({
		name: `${API_NAME}_changeStatus`,
		url: changeStatus,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessChangeStatusMutation,
	});
	// ---------------------------------------------- logs
	console.log({ data, error, isError, isLoading });
	// ---------------------------------------------- action click table

	const actionsOperation = ({ data, status }) => {
		swal({
			title: "Are you sure?",
			text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				if (status.label === "delete") deleteMutation.mutate({ id: data });
				else changeStatusMutation.mutate({ ids: data, isActive: status.value });
			}
		});
	};

	const clickAction = ({ data, status }) => {
		const newData = data.map((x) => x.id);
		actionsOperation({ data: newData, status });
	};

	const handleAction = (status) => {
		const data = Object.keys(checkList);
		console.log({ data, status });
		actionsOperation({ data, status: status });
	};
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [{ title: "Dashboard", link: routes.dashboard.base }, { title: COMPONENT_NAMES[0], link: newRoute.base }, { title: "List" }];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);

	// const handleShowModal = () => setShowModal(true)
	// const handleHideModal = () => setShowModal(false)

	const tableOptions = tableData({
		onClick: clickAction,
		newRoute,
		active: {
			// manuel: {
			//   icon: 'mdi mdi-source-branch',
			//   class: 'bg-success',
			//   label: "change branch",
			//   onClick: (data) => {
			//     setShowModal(data)
			//   }
			// }
		},
		validPermission,
		disable: { edit: !(validPermission || permissions?.edit), delete: !(validPermission || permissions?.delete) },
	});

	const gettingHotels = async () => {
		return await axiosClient().get(api[API_NAME].list, {
			params: { limit: 0 },
		});
	};
	//  ----------------------------------------- LOGS ----------------------------------------- //
	console.log({ checkList, data }, "checkList");
	const exportToCSV = ({ apiData, fileName, head, cols }) => {
		const workbook = XLSX.utils.book_new();
		// const myHeader = ["id","name","location"];
		const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e["c"] = head.length - 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);
		worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		worksheet["!cols"] = cols;

		XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
		XLSX.writeFile(workbook, "excel_export.xlsx");
	};

	const handleGetHotels = () => {
		let cols = [
			{ wch: 30 },
			{ wch: 15 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
		];
		gettingHotels()
			.then((x) => {
				console.log("x ===>", { x });

				const head = [
					t("hotelName"),
					t("region"),
					t("id"),
					t("roomCount"),
					"komisyon_orani",
					"indirim_yuzdesi",
					// "islem_turu",
					"service_charge",
					"soguk_icecek",
					"alkol",
					// "iban_adi",
					// "iban",
					"fatura",
				];
				const data = x?.data?.result?.map((y) => ({
					[t("hotelName")]: y?.name || "",
					[t("region")]: y?.region?.name || "",
					[t("id")]: `${y?.id}`,
					[t("roomCount")]: y?.roomCount || "-",
					komisyon_orani: y?.commission,
					indirim_yuzdesi: y?.mpu?.map((x) => x?.operationUnit)?.join(" , "),
					// islem_turu: String(y?.mpu?.map((x) => x?.operationSide)),
					service_charge: String(y?.serviceCharge),
					soguk_icecek: String(y?.coldDrink),
					alkol: String(y?.alcohol),
					// iban_adi: y?.ibanName || "",
					// iban: y?.iban,
					fatura: y?.invoice,
					bolge: y?.region?.id,
				}));
				exportToCSV({ apiData: data, fileName: "exc", head, cols });
				console.log(" ===>", data);
			})
			.catch((err) => {
				console.log({ err });
			});
	};
	return (
		<div>
			<PageTitle title={t("listsAuthor", { author: t(COMPONENT_NAMES[0]) })} breadcrumbs={breadcrumbs} buttons={buttons} />
			<TableComponent
				{...{
					QueryString,
					data: data?.result,
					tableOptions,
					loading: isLoading,
					search: {
						placeholder: `Search ${COMPONENT_NAMES[0]}`,
						defaultValue: QueryString.search_text,
					},
					title: `${COMPONENT_NAMES[1]}`,
					button: button,
					count: data.total,
					pagination: {
						activePage: page,
						pages: Math.ceil(data.total / limit),
						limited: 2,
					},
					checkbox: {
						state: checkList,
						setState: setCheckList,
					},
					actions: {
						onClick: handleAction,
					},
					status: STATUSES,
					excelButton: {
						show: true,
						disabled: !data?.result,
						onClick: handleGetHotels,
					},
				}}
			/>
			{/* <BranchModal  {...{ show: showModal, setShow: setShowModal, refetchKey: `${API_NAME}_get` }} /> */}
		</div>
	);
};

export default List;
