import { Button, Modal } from "react-bootstrap";
// import { useEffect, useState } from 'react';
// import isoToDate from "utils/isoToDate";
// import OrderStatusButton from "../OrderStatusButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { fieldNames, validation } from "./validation";
import { Controller, useForm } from "react-hook-form";
import ProductSelector from "containers/Product/ProductSelector";
import axiosClient from 'lib/axiosConfig';
import api from "operations/network/api";
import toastify from "lib/toastify";
import { useMutationCustom } from "utils/useMutationCustom";

const RemoveProductSelector = ({ setModal, show, onClick, refetchKey, ...props }) => {

    // const [state,setState]=useState()

    // useEffect(()=>{
    //     setState()
    // },[show])

    console.log({ show });
    const param = show


    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        control,
        reset,
        watch,
    } = useForm({
        resolver: yupResolver(validation()),
        mode: "onSubmit",
    });

    const deleting = async (x) => {
        console.log({ xxxxxxxxxxxxxxxxx: x });
        // return true
        return await axiosClient().delete(api.menus.base + "/" + param?.id + "/item/" + x.product)
    };


    const onSuccessMutating = (data) => {
        console.log({ xxxxxxxxxxxxxxxxx: data });

        toastify.success({ title: "success" });
        setModal(false)
    };
    const { isLoading, data, mutate } = useMutationCustom({
        url: deleting,
        // invalidQuery: refetchKey,
        onSuccess: onSuccessMutating,
    });



    const onSubmit = () => {
        const values = getValues()
        console.log({ xxxxxxxxxxxxxxxxx: values });
        mutate({
            product: values.product?.value
        })
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={() => setModal(false)}
            style={{ margin: "auto" }}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex w-100 align-items-center">
                    <div className="d-flex">
                        <small>
                            {param?.name}
                        </small>
                        {/* <div className="mr-4"></div> */}
                        {/* <small>
                            {`created At: `}{isoToDate(show?.createdAt)}
                        </small> */}
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>

                    <ProductSelector
                        {...{
                            name: fieldNames.product,
                            control,
                            register,
                            label: "product",
                            errors,
                            control,
                            setParams: {
                                menu: param?.id,
                            },
                        }}
                    />
                    <div className="d-flex">


                        <Button disabled={isLoading} variant="danger" type='submit'>
                            {isLoading ? "loading..." : "Remove"}
                        </Button>
                        <div className="mr-3"></div>

                        <Button disabled={isLoading} variant="success" type='button' onClick={() => setModal()}>
                            {"Cancel"}
                        </Button>

                    </div>

                </form>
                {/* <div className="row">
                    {show?.products?.map((x) => {
                        return new Array(x.quantity).fill({}).map((_, i) => (
                            <div className="col-md-4 grid-margin stretch-card" key={x._id || x.id}>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">{x?.product?.name}</h4>
                                        <div className="media">
                                            <img src={x?.product?.images} width={45} />
                                            <div className="mr-2" />
                                            <div className="media-body">
                                                <p className="card-text">{x?.product?.shortDesc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ));
                    })}


                </div> */}

            </Modal.Body>
        </Modal>
    )
}

export default RemoveProductSelector;