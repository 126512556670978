import React, { Fragment, Children } from "react";
import cLog from "utils/cLog";

import "./index.scss";
const PaginationM = ({ limited, pages, activePage, onClick }) => {
  console.log({ limited, pages, activePage, onClick });
  let startFullPageNumber = 7;
  let sort = [];
  if (pages === undefined || pages === 1 || !pages) return <div></div>;
  Number(activePage);
  // cLog({ limited, pages, activePage });
  let viewNext = +limited + +activePage;
  let viewPrev = activePage - limited;
  // cLog({ viewNext, viewPrev });
  for (let index = 1; index <= pages; index++) {
    if (pages >= startFullPageNumber) {
      // cLog({ check: pages - limited }, limited - pages === index, { index });
      if (2 == index && viewPrev == 2) sort.unshift(1);
      if (viewPrev <= index && viewNext >= index) sort.push(index);
    } else if (pages <= startFullPageNumber) sort.push(index);
  }

  if (pages - limited - 1 == activePage && pages >= startFullPageNumber) sort.push(pages);
  // cLog({ active: pages - limited - 1 == activePage, sort });

  let prevFirst = Number(activePage) < Number(limited);
  let prevSecond = Number(activePage) === Number(1);
  let nextFirst = Number(activePage) === Number(pages);
  let nextSecond = Number(activePage) > Number(pages - limited);
  // cLog({ prevFirst });
  // cLog({ prevSecond });
  // cLog({ nextFirst });
  // cLog({ nextSecond });
  console.log({ sort });
  let Pagination_Sort = (
    <div className="Pagination-wrapper">
      {" "}
      <div
        className={`paginationIcon centerAll translateR transition0-2  ${pages < startFullPageNumber || prevFirst ? "hidden-element" : ""}`}
        disabled={prevFirst}
        onClick={() => (prevFirst ? null : onClick(1))}
      >
        <i className="mdi mdi-chevron-double-left" aria-hidden="true" />
        {/* {"<<"} */}
      </div>
      <div
        className={`paginationIcon centerAll translateR transition0-2  ${prevSecond ? "hidden-element" : ""}`}
        disabled={prevSecond}
        onClick={() => (prevSecond ? null : onClick(activePage - 1))}
      >
        <i className="mdi mdi-chevron-left" aria-hidden="true" />
        {/* {"<"} */}
      </div>
      {Number(activePage) > 2 + Number(limited) && pages >= startFullPageNumber && (
        <Fragment>
          <div className="paginationIcon centerAll translateT transition0-2" onClick={() => onClick(1)}>
            {1}
          </div>
          <div className="paginationIcon centerAll">
            <i className="mdi mdi-dots-horizontal" aria-hidden="true" />
            {/* {" ..."} */}
          </div>
        </Fragment>
      )}
      {Children.toArray(
        sort.map((number, index) => {
          if (number == 1 && +activePage > +limited + 2 && +pages >= startFullPageNumber) return;
          else if (number === pages && activePage < pages - limited && pages >= startFullPageNumber) return;
          else
            return (
              <>
                <div
                  className={`paginationIcon centerAll translateT transition0-2 ${number === Number(activePage) && "activated"}`}
                  onClick={() => (number === Number(activePage) ? null : onClick(number))}
                >
                  {number}
                </div>
              </>
            );
        })
      )}
      {/* {pages - limited - 1 == activePage && (
        <div className={`paginationIcon centerAll translateT transition0-2 `} onClick={() => onClick(pages)}>
          {pages}
        </div>
      )} */}
      {activePage < pages - limited - 1 && pages >= startFullPageNumber && (
        <Fragment>
          <div className="paginationIcon centerAll">
            <i className="mdi mdi-dots-horizontal" aria-hidden="true" />
            {/* {"..."} */}
          </div>

          <div className="paginationIcon centerAll translateT transition0-2" onClick={() => onClick(pages)}>
            {pages}
          </div>
        </Fragment>
      )}
      <div
        className={`paginationIcon centerAll translateL transition0-2  ${nextFirst ? "hidden-element" : ""}`}
        disabled={nextFirst}
        onClick={() => (nextFirst ? null : onClick(+activePage + +1))}
      >
        <i className="mdi mdi-chevron-right" aria-hidden="true" />
        {/* {">"} */}
      </div>
      <div
        className={`paginationIcon centerAll translateL transition0-2  ${pages < startFullPageNumber || nextSecond ? "hidden-element" : ""}`}
        disabled={nextSecond}
        onClick={() => (nextSecond ? null : onClick(Number(pages)))}
      >
        <i className="mdi mdi-chevron-double-right " aria-hidden="true" />
        {/* {">>"} */}
      </div>
    </div>
  );

  return Pagination_Sort;
};

export default PaginationM;
