import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

export const tableData = ({ onClick, newRoute, ...props }) => [
  {
    header: "language",
    Component: ({ param }) => (param.language) || '-',
  },
  {
    header: "orders",
    Component: ({ param }) => param.orders || '-',
  },
  {
    header: "scans",
    Component: ({ param }) => param.scans || '-',
  },
];