import React, { Fragment } from 'react'
// import { InputError } from "components/forms/InputError";
import AsyncSelect from 'react-select/async'
import { API_NAME } from "./enum";
import axiosClient from 'lib/axiosConfig';
import { useQueryCustom } from 'utils/useQueryCustom';
import api from 'operations/network/api';
import { useMemo, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import compareTranslate from 'utils/compareTranslate';
import { Form } from 'react-bootstrap';

const LoadingIndicator = () => {
    return <div className='mx-2'>
        <Spinner animation="grow" size='sm' />
    </div>
}

export const convertLanguagesData = (data) => data?.map?.((x) => ({
    label: (
        <div className='d-flex align-items-center justify-items-center'>
            <div className=" mt-1 d-flex align-items-center">
                <img width={30} height={30} src={x?.image} className={"rounded-circle"} />
            </div>
            <div className='d-flex align-items-center'>{x?.name}</div>
            <div className='d-flex align-items-center'>({x?.code})</div>
        </div>
    )
    , value: x.code
}) || [])


export const convertLanguageData = (x) => ({
    label: (
        <div className='d-flex align-items-center justify-items-center'>
            <div className=" mt-1 d-flex align-items-center">
                <img width={30} height={30} src={x?.image} className={"rounded-circle"} />
            </div>
            <div className='d-flex align-items-center'>{x?.name}</div>
            <div className='d-flex align-items-center'>({x?.code})</div>
        </div>
    )
    , value: x?.code
})



const LanguageSelector = ({
    name, control, label, valid, register, errors, require, options, setState, onChange, selectAll, defaultValue, ...other
}) => {
    // const [dataForSelectBox, setDataForSelectBox] = React.useState([])

    const [t] = useTranslation();
    const { setValue } = useFormContext() || {} // retrieve all hook methods

    const getting = async (params) => {
        return await axiosClient().get(api[API_NAME].list, {
            params,
        });
    }
    // ------------------------------------------------------- query Get
    const {
        data: { data = {} } = {},
        error,
        isError,
        isLoading,
        refetch,

    } = useQueryCustom({
        name: `${API_NAME}_get`,
        url: getting,
        // params: {}
        // params: { ...QueryString },
    });

    const loadOptions = async (inputValue, callback) => {
        const params = {
            search_text: inputValue,
            limit: 5,
            page: 1
        }
        await getting(params).then(x => {
            callback(convertLanguagesData(x?.data?.result))
        })

    }

    const dataForSelectBox = useMemo(() => {
        return convertLanguagesData(data?.result)
    }, [data])
    let newRegister = register ? register(name) : {};

    useEffect(() => {
        if (!selectAll && typeof other.value === "string" && data?.result) {
            const isValue = data?.result?.find((x) => ([x.id, x.code, x.name].includes(other.value)))
            console.log(" * * * languageSelector : ", { value: other.value, isValue });
            if (isValue) onChange?.(convertLanguageData(isValue))
        }
        if (!selectAll && typeof defaultValue === "string" && data?.result) {
            const isValue = data?.result?.find((x) => ([x.id, x.code, x.name].includes(defaultValue)))
            console.log(" * * * languageSelector : ", { result: data?.result, defaultValue, isValue });
            if (isValue) setValue?.(name, convertLanguageData(isValue))
        }

        if (selectAll && data?.result) {

            setValue(name, convertLanguagesData(data?.result))
        }
    }, [other.value, data])

    console.log(" * * * languageSelector : ", { data });
    return (
        <>
            <Form.Group className="form-group ">
                {label ? <div className={"d-flex justify-content-between"}>
                    <label htmlFor={name}>
                        {compareTranslate(t, label)}
                        {require ? " *" : ""}
                    </label>
                    {errors ? <div className={`error-text `}>{compareTranslate(t, errors[name]?.message)}</div> : ""}
                    {other?.error ? <div className={`error-text `}>{compareTranslate(t, other?.error?.message)}</div> : ""}

                </div> : ""}
                {/* {control ? */}
                <Controller
                    control={control}
                    ref={newRegister}
                    name={name}
                    render={({ field: { onChange: change, value } }) => {
                        function handleChange(params) {
                            onChange?.(params);
                            change?.(params);
                            setState?.(params);
                        }
                        // console.log({ onChange, value, options },'');
                        return <AsyncSelect
                            className="custom-selector"
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions={dataForSelectBox}
                            onChange={handleChange}
                            components={{ LoadingIndicator }}
                            classNamePrefix='select'
                            styles={{
                                input: (base) => ({
                                    ...base,
                                    'input:focus': {
                                        boxShadow: 'none',
                                    },
                                }),
                                control: (styles) => ({
                                    ...styles,
                                    borderColor: error || other?.error || errors?.[name] ? 'red' : styles.borderColor,
                                }),
                            }}
                            // validationState={error ? "error" : "default"}
                            value={value}
                            {...other}
                        />
                        //   return <Select className="custom-selector" placeholder={label} options={newOptions}
                        //    value={value} onChange={handleChange} {...other} />
                    }}
                />
                {/* :
        <Select className="custom-selector" options={options} {...other} />
      } */}
            </Form.Group>

            {/* <InputError error={error} /> */}
        </>
    )
}
export default LanguageSelector