import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
  product: "product",

};

// const translations = yup
//   .object({
//     [fieldNames.translate_name]: yup.string().required(ERRORS.pleaseEnterYour("name")).label("name"),
//     [fieldNames.translate_shortDesc]: yup.string().required(ERRORS.pleaseEnterYour("shortDesc")).label("shortDesc"),
//     [fieldNames.translate_desc]: yup.string(),
//     [fieldNames.translate_language]: yup.object().required(ERRORS.pleaseEnterYour("language")).label("language"),
//   })
//   .defined();

export const validation = (URL_ID) =>
  yup.object().shape({
    // [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
    [fieldNames.product]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.product)).label("product"),

    // [fieldNames.isRecommended]: yup.bool().default(false).label(fieldNames.isRecommended),

    // [fieldNames.lastName]: yup
    //   .string()
    //   .required(ERRORS.pleaseEnterYour(fieldNames.lastName))
    //   .label(fieldNames.lastName),
    // [fieldNames.phoneNumber]: yup
    //   .string()
    //   .required(ERRORS.pleaseEnterYour(fieldNames.phoneNumber))
    //   .label(fieldNames.phoneNumber),
    // [fieldNames.phoneNumber]: yup
    //   .string()
    //   .required(ERRORS.pleaseEnterYour(fieldNames.phoneNumber))
    //   .label(fieldNames.phoneNumber),
    // [fieldNames.isWorking]: yup.object().required(ERRORS.pleaseEnterYour("status")).label("status"),
    // [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
    // [fieldNames.shortDesc]: yup
    //   .string()
    //   .required(ERRORS.pleaseEnterYour(fieldNames.shortDesc))
    //   .label(fieldNames.shortDesc),
    // [fieldNames.desc]: yup.string().label(fieldNames.desc),
    // [fieldNames.stock]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stock)).label(fieldNames.stock),
    // [fieldNames.price]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.price)).label(fieldNames.price),
    // [fieldNames.images]: yup.lazy((value) => {
    //   switch (typeof value) {
    //     case "object":
    //       return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.images)).label(fieldNames.images); // schema for object
    //     case "string":
    //       return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.images)).label(fieldNames.images); // schema for string
    //     default:
    //       return yup.mixed().required(ERRORS.pleaseEnterYour(fieldNames.images)).label(fieldNames.images); // here you can decide what is the default
    //   }
    // }),
    // [fieldNames.stock]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stock)).label(fieldNames.name),
    // [fieldNames.translations]: yup.array().of(translations),
    // [fieldNames.isNewProduct]: yup.bool().default(false).label(fieldNames.isNewProduct),
    // [fieldNames.isFastDelivery]: yup.bool().default(false).label(fieldNames.isFastDelivery),
    // [fieldNames.isRecommended]: yup.bool().default(false).label(fieldNames.isRecommended),
    // [fieldNames.kdv]: yup
    //   .string()
    //   .when(fieldNames.isRecommended, {
    //     is: true,
    //     then: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.kdv)),
    //   })
    //   .label(fieldNames.kdv),
    // [fieldNames.delivery]: yup
    //   .object()
    //   .when(fieldNames.isRecommended, {
    //     is: true,
    //     then: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.delivery)),
    //   })
    //   .label(fieldNames.delivery),
    // [fieldNames.email]: yup.string().required(ERRORS.pleaseEnterYour("Email")).label("email"),
    // [fieldNames.password]: yup
    //   .string()
    //   .matches(regex.passwordRegExp, "Password must at least 8 characters, including uppercase,lowercase,number and symbol")
    //   .label("Password"),
    // [fieldNames.confirm_password]: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
    // [fieldNames.role]: yup.object().label("role"),
    // [fieldNames.phoneNumber]: yup.string().label("mobile-number"),
    // [fieldNames.isActive]: yup.object().label("status"),
    // [fieldNames.type]: yup.object().required(ERRORS.pleaseSelectYour("Type")).label("Type"),
    // [fieldNames.image]: yup.object().nullable().required(ERRORS.pleaseSelectYour("image")).label("image"),
  });
