const CheckedComponent = ({ checked }) => {
  return (
    <div className="d-flex">
      <div className={` h4  `}>
        {checked ? <i class="mdi mdi-check-circle text-success"></i> : <i class="mdi mdi-close-circle text-danger"></i>}
      </div>
      {/* <span
        className="btn btn-danger shadow btn-xs sharp pointer action-btn mdi rounded p-2 "
        onClick={() => onClick({ data: [param], status: STATUSES_OBJECT["2"] })}
      >
        <i className="mdi mdi-delete"></i>
      </span> */}
    </div>
  );
};

export default CheckedComponent;
