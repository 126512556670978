import { all, call, put, select, takeLatest } from "@redux-saga/core/effects";
// import action from "../store/admin/action";
import action from "redux/store/admin/action";
// import actionAccessToken from "redux/store/accessToken/action";
// import actionRefreshToken from "redux/store/refreshToken/action";
import actionToken from "redux/store/token/action";
import * as actionTypes from "redux/store/admin/actionTypes";
import { changeError } from "utils/errors";
import network from "lib/network";
import api from "operations/network/api";

function* getSaga() {
	try {
		const res = yield call(network.get, { url: api.user.profile });
		console.log({ res });
		yield put(action.receive(res.data));
	} catch (error) {
		console.log({ error });
		yield put(actionToken.initial());
		yield put(action.initial());
		yield put(action.failure(changeError(error?.response)));
	}
}

export function* logoutSaga() {
	const stateSocket = yield select((state) => state.socket);
	yield put(actionToken.initial());
	yield put(action.initial());
	if (stateSocket.data) stateSocket.data.disconnect();
	localStorage.removeItem("state");
}

function* rootSaga() {
	yield all([takeLatest(actionTypes.USER_LOGOUT, logoutSaga), takeLatest(actionTypes.USER_REQUEST, getSaga)]);
}

export default rootSaga;
