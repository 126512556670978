import Dashboard from "containers/Dashboard";
import { PERMISSIONS } from "enumerations";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import routes from "./routes";
import User from "containers/User";
import Hotel from "containers/Hotel";
import Category from "containers/Category";
import Gallery from "containers/Gallery";
import Branch from "containers/Branch";
import Product from "containers/Product";
import ProductHour from "containers/productHour";
import Deliveries from "containers/Deliveries";
import Activities from "containers/Activity";
import Places from "containers/Places";
import Menus from "containers/Menus";
import Language from "containers/Language";
import Setting from "containers/Setting";
import Order from "containers/Order";
import Factor from "containers/Factor";
import Finance from "./../../containers/Finance/index";
import MultiplePrice from "containers/MultiplePrice";
import BaseDashboard from "containers/BaseDashboard";
import Region from "containers/Regions";
import Invoice from "containers/Invoince";
import FinanceHotels from "../../containers/FinanceHotels";
import FinanceBranches from "../../containers/FinanceBranches";
import Role from "containers/Role";
import Permission from "containers/Permission";
import IdeaBox from "containers/IdeaBox";
import Discount from "containers/Discount";
import PrintExample from "components/PrintHotelLanding/PrintExample";
import PrintPage from "components/PrintHotelLanding";
// import Report from "containers/Report";
import HourlyReport from "containers/HourlyReport";
import PendingPriceUpdate from "containers/PendingPriceUpdate";
import CostReport from "containers/CostReport";
import LanguageReport from "containers/LanguageReport";
import ContactUs from "containers/ContactUs";
import IntegrationCode from "containers/IntegrationCode";
import RoomReport from "containers/RoomReport";

const Buttons = lazy(() => import("containers/basic-ui/Buttons"));
const Dropdowns = lazy(() => import("containers/basic-ui/Dropdowns"));
const Typography = lazy(() => import("containers/basic-ui/Typography"));
const BasicElements = lazy(() => import("containers/form-elements/BasicElements"));
const BasicTable = lazy(() => import("containers/tables/BasicTable"));
const Mdi = lazy(() => import("components/icons/Mdi"));
const ChartJs = lazy(() => import("containers/charts/ChartJs"));
const Error404 = lazy(() => import("containers/error-pages/Error404"));
const Error500 = lazy(() => import("containers/error-pages/Error500"));
const Register1 = lazy(() => import("containers/user-pages/Register"));
const Lockscreen = lazy(() => import("containers/user-pages/Lockscreen"));
const BlankPage = lazy(() => import("containers/general-pages/BlankPage"));

// -------------------- containers

const routing = [
	{ path: "/", exact: true, element: <Navigate to={routes.dashboard.base} /> },

	{
		path: routes.dashboard.base,
		element: <Dashboard />,
		name: "dashboard",
		permission: true,
		// permissions: ["ALL"],
	},

	{
		path: "/base-dashboard",
		element: <BaseDashboard />,
		name: "base-dashboard",
		permission: true,
		// permissions: ["ALL"],
	},

	// { path: routes.user.all, element: <User />, permissions: [PERMISSIONS.USER_ADMIN] },
	{ path: routes.role.all, element: <Role />, permissions: true, name: "roles" },
	{ path: routes.permission.all, element: <Permission />, permissions: true, name: "permissions" },
	// { path: routes.newsletter.all, element: <Newsletter />, permissions: [PERMISSIONS.NEWSLETTER] },
	{ path: routes.region.all, element: <Region />, permission: true, name: "regions" },
	{ path: routes.ideaBox.all, element: <IdeaBox />, permission: true, name: "ideaBoxes" },
	{ path: routes.discount.all, element: <Discount />, permission: true, name: "discounts" },

	{ path: routes.user.all, element: <User />, permission: true, name: "users" },
	{ path: routes.hotel.all, element: <Hotel />, permission: true, name: "hotels" },
	{ path: routes.category.all, element: <Category />, permission: true, name: "productCategories" },
	{ path: routes.gallery.all, element: <Gallery />, permission: true, name: "files" },
	{ path: routes.branch.all, element: <Branch />, permission: true, name: "branches" },
	{ path: routes.product.all, element: <Product />, permission: true, name: "products" },
	{ path: routes.hour.all, element: <ProductHour />, permission: true, name: "productHour" },
	{ path: routes.places.all, element: <Places />, permission: true, name: "places" },
	{ path: routes.menus.all, element: <Menus />, permission: true, name: "menus" },
	{ path: routes.integrationCodes.all, element: <IntegrationCode />, permission: true, name: "integrationCode" },
	{ path: routes.deliveries.all, element: <Deliveries />, permission: true, name: "deliveries" },
	{ path: routes.activities.all, element: <Activities />, permission: true, name: "activities" },
	{ path: routes.finance.all, element: <Finance />, permission: true, name: "finance", supports: ["financeHotels"] },
	{ path: routes.invoice.all, element: <Invoice />, permission: true, name: "invoice" },
	{ path: routes.financeHotels.all, element: <FinanceHotels />, permission: true, name: "financeHotels" },
	{ path: routes.financeBranches.all, element: <FinanceBranches />, permission: true, name: "financeBranches" },
	{ path: routes.language.all, element: <Language />, permission: true, name: "languages" },
	{ path: routes.setting.all, element: <Setting />, permission: true, name: "settings" },
	{ path: routes.order.all, element: <Order />, permission: true, name: "orders" },
	{ path: routes.multiplePrice.all, element: <MultiplePrice />, permission: true, name: "mpu" },
	{ path: routes.PendingPriceUpdate.all, element: <PendingPriceUpdate />, permission: true, name: "pendingProduct" },
	// { path: routes.report.all, element: <Report />, permission: true, name: "report" },
	{ path: routes.hourlyReport.all, element: <HourlyReport />, permission: true, name: "hourlyReports" },
	{ path: routes.costReport.all, element: <CostReport />, permission: true, name: "costReports" },
	{ path: routes.languageReport.all, element: <LanguageReport />, permission: true, name: "languageReports" },
	{ path: routes.roomReport.all, element: <RoomReport />, permission: true, name: "roomDetailsReports" },

	{ path: routes.contactUs.all, element: <ContactUs />, permission: true, name: "contactUs" },
	// { path: routes.member.user.all, element: <User />, permissions: true },

	// { path: routes.blog.category.all, element: <Category />, permissions: [PERMISSIONS.BLOG] },
	// { path: routes.blog.tag.all, element: <Tag />, permissions: [PERMISSIONS.BLOG] },
	// { path: routes.blog.post.all, element: <Post />, permissions: [PERMISSIONS.BLOG] },

	// { path: routes.media.folder.all, element: <Folder />, permissions: [PERMISSIONS.MEDIA, PERMISSIONS.BLOG, PERMISSIONS.USER] },
	// { path: routes.media.gallery.base, element: <Gallery />, permissions: [PERMISSIONS.MEDIA, PERMISSIONS.BLOG, PERMISSIONS.USER] },
	// { path: routes.media.gallery.folderName, element: <FolderDetail />, permissions: [PERMISSIONS.MEDIA, PERMISSIONS.BLOG, PERMISSIONS.USER] },

	{ path: "/factor", element: <Factor /> },
	{ path: "/print", element: <PrintExample /> },
	{ path: "/print-page", element: <PrintPage /> },
	{ path: "/basic-ui/buttons", element: <Buttons /> },
	{ path: "/basic-ui/dropdowns", element: <Dropdowns /> },
	{ path: "/basic-ui/typography", element: <Typography /> },
	{ path: "/form-Elements/basic-elements", element: <BasicElements /> },
	{ path: "/tables/basic-table", element: <BasicTable /> },
	{ path: "/icons/mdi", element: <Mdi />, permissions: [PERMISSIONS.USER] },
	{ path: "/charts/chart-js", element: <ChartJs /> },
	{ path: "/user-pages/register-1", element: <Register1 /> },
	{ path: "/user-pages/lockscreen", element: <Lockscreen /> },
	{ path: "/error-pages/error-404", element: <Error404 /> },
	{ path: "/error-pages/error-500", element: <Error500 /> },
	{ path: "/general-pages/blank-page", element: <BlankPage /> },
	// { path: "*", element: Error404 },
];

export default routing;
