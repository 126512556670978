import routes from "operations/routing/routes";
import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import getDataInObjectDepth from "utils/getDataInObject";
import { ROUTE_NAME } from "./enum";
import List from "./List";
import Show from "./Show";

const Finance = () => {
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

	return (
		<Routes>
			<Route index element={<Navigate to={newRoute.base + newRoute.list} />} />
			<Route path={newRoute.list} element={<List />} />
			<Route path={newRoute.list + "/:years"} element={<List />} />
			<Route path={newRoute.show + "/:id/:years"} element={<Show />} />
			<Route path={newRoute.show + "/:id"} element={<Show />} />
		</Routes>
	);
};

export default Finance;
