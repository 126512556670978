import ProtectedRoute from "components/ProtectedRoute";

const checkComponentRoute = (permissionKey, permissionValue, adminState, redirect, element) => {
    console.log("* * * checkComponentRoute : ", { permissionKey, permissionValue, adminState, redirect });
    return (permissionValue && (adminState.data.operation || adminState.data).permissions !== true)
        ?
        <ProtectedRoute isAllowed={(adminState.data.operation || adminState.data)?.permissions?.[permissionKey]?.[permissionValue]}
            redirect={
                redirect || "/"
            }>{element}</ProtectedRoute>
        :
        element
}

export default checkComponentRoute;