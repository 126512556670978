import { Button, Col, Modal } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { fieldNames, validation } from "./validation";
import { useForm } from "react-hook-form";
import FieldText from "components/FieldText";


const NoteForStatusCancel = ({ setModal, show, onSubmit }) => {

    const [t] = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        control,
        reset,
        watch
    } = useForm({
        resolver: yupResolver(validation),
        mode: "onSubmit"
    });

    useEffect(() => {
        if (show) reset({ note: "" })
    }, [show])

    const onSubmitted = () => {
        const values = getValues();
        onSubmit(values?.note)
        setModal(false)

    }


    return (
        <Modal
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={() => setModal(false)}
            style={{ margin: "auto" }}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex w-100 align-items-center">
                    <div className="d-flex">
                        <small>
                            {t("cancel-note")}
                        </small>

                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmitted)} id={"form-container "} noValidate>
                    <FieldText
                        require
                        {...{
                            name: fieldNames.note,
                            register,
                            placeholder: "note",
                            label: "note",
                            errors,
                            mode: "textarea"
                        }}
                    />

                    <Button className={"d-flex align-items-center"} size="sm" type="submit">
                        <div className={"gallery-add-title"}>
                            {t("submit")}
                        </div>

                    </Button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default NoteForStatusCancel;