import * as QRCode from 'easyqrcodejs';


const generateQrCode = (text, qrCode, roomNumber, options) => {
    const setting = {
        // ====== Basic
        text: text,
        width: options?.width || 195,
        height: options?.height || 195,
        correctLevel: QRCode.CorrectLevel.H,
        colorDark: options?.background || '#000',
        colorLight: options?.color || '#fff',
        // ====== Quiet Zone
        quietZone: 1,
        quietZoneColor: '#fff',
        // ==== Images format
        format: 'PNG', // 'PNG', 'JPG'
        compressionLevel: 9, // ZLIB compression level (0-9). default is 6
        quality: 1, // An object specifying the quality (0 to 1). default is 0.75. (JPGs only)
        // ====== Logo
        ...(roomNumber && {
            logo: generateImageWithNumber(roomNumber, options),
            logoWidth: options?.logo?.width || 60,
            logoHeight: options?.logo?.height || 60,
            logoBackgroundColor: '#fff',
            logoBackgroundTransparent: false, // Whether use transparent image, default is false
        })
    };

    new QRCode(qrCode, setting);
}

function convertImageToSVG(imageDataURL) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imageDataURL;
        img.onload = function () {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
            let svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${canvas.width}" height="${canvas.height}">`;

            for (let i = 0; i < data.length; i += 4) {
                const r = data[i];
                const g = data[i + 1];
                const b = data[i + 2];
                const a = data[i + 3] / 255;

                svg += `<rect x="${(i / 4) % canvas.width}" y="${Math.floor(i / 4 / canvas.width)}" width="1" height="1" fill="rgba(${r},${g},${b},${a})"/>`;
            }

            svg += `</svg>`;
            resolve(svg);
        };

        img.onerror = function (error) {
            reject(error);
        };
    });
}

function generateImageWithNumber(roomNumber, options) {
    roomNumber = String(roomNumber)
    // const canvas = canvasRef.current;
    var canvas = document.createElement('canvas');
    canvas.className = 'hiddenCanvas';
    canvas.width = options?.logo?.bgWhite ? 50 : 1000;
    canvas.height = options?.logo?.bgWhite ? 50 : 1000;
    console.log({ options });
    var body = document.getElementsByTagName('body')[0];
    body.appendChild(canvas);
    const ctx = canvas.getContext('2d');
    if (!options?.logo?.bgWhite) {
        ctx.fillStyle = '#FFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        let fontSize = canvas.width / (roomNumber?.trim(" ").length / 1.5);
        // console.log({ width: canvas.width, roomNumber: roomNumber?.trim(" "), fontSize }, roomNumber.length, canvas.width / roomNumber?.trim(" ").length);
        if (fontSize > canvas.width) fontSize = canvas.width
        ctx.fillStyle = options?.textColor || '#000';
        ctx.font = `700 ${fontSize}px Arial`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(roomNumber, canvas.width / 2, canvas.height / 2);
    }
    setTimeout(function () {
        body.removeChild(canvas);
    }, 5000);

    return canvas.toDataURL('image/png');
}

export default generateQrCode;



// import { createCanvas } from 'canvas';
// import { Injectable } from '@nestjs/common';
// import { IQrCodeService } from './qr-code.interface';
// import { config } from 'src/common/config';
// import EasyQrCode = require('easyqrcodejs-nodejs');
// import { UploadDto } from 'src/aws/dto/aws.dto';
// @Injectable()
// export class QrCodeService implements IQrCodeService {
//     async createQrCodeWithNumber(
//         qrCodeId: string,
//         roomNumber: string,
//     ): Promise<string> {
//         //* url will come from backend and frontend will add the room number to this url
//         const qrCodeUrl = https://room-services.net/sayfa/urunler?ouid=019B07E0-5292-48D5-BA59-3F6176650829?roomNumber=${roomNumber};

//         const options = {
//             // ====== Basic
//             text: qrCodeUrl,
//             width: 1080,
//             height: 1080,
//             correctLevel: EasyQrCode.CorrectLevel.H,
//             // ====== Quiet Zone
//             quietZone: 1,
//             quietZoneColor: '#fff',
//             // ==== Images format
//             format: 'PNG', // 'PNG', 'JPG'
//             compressionLevel: 9, // ZLIB compression level (0-9). default is 6
//             quality: 1, // An object specifying the quality (0 to 1). default is 0.75. (JPGs only)
//             // ====== Logo
//             logo: this.generateImageWithNumber(roomNumber),
//             logoWidth: 320,
//             logoHeight: 320,
//             logoBackgroundColor: '#fff',
//             logoBackgroundTransparent: false, // Whether use transparent image, default is false
//         };
//         const qrCode = await new EasyQrCode(options).toDataURL('image/png');

//         // Extract the base64-encoded data
//         const [, encodedData] = qrCode.split(',');

//         // Decode the base64 data
//         const decodedData = Buffer.from(encodedData, 'base64');
//         const filename = ${qrCodeId}-${roomNumber}.png;
//         const { path } = await this.awsService.upload(
//             new UploadDto(decodedData, filename),
//         );
//         return path;
//     }
//     generateImageWithNumber(roomNumber: string): string {
//         // Create a canvas
//         const canvas = createCanvas(1080, 1080);
//         const ctx = canvas.getContext('2d');

//         // Draw something (transparent background)
//         ctx.clearRect(0, 0, 1080, 1080);

//         // Draw the number in the middle
//         ctx.fillStyle = '#000';
//         ctx.font = ${1000 / roomNumber.length}px Arial;
//         ctx.textAlign = 'center';
//         ctx.textBaseline = 'middle';
//         ctx.fillText(roomNumber, canvas.width / 2, canvas.height / 2);

//         // return the image
//         return canvas.toDataURL('image/png');
//     }
// }
// export const QR_CODE_SERVICE_TOKEN = Symbol('QR_CODE_SERVICE_TOKEN');