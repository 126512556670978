import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  email: "email",
  password: "password",
  confirm_password: "confirm_password",
  name: "name",
  lastName: "lastName",
  phoneNumber: "phoneNumber",
  role: "role",
  status: "status",
  image: "image",
  type: "type",
  isEmailNotificationActive: "isEmailNotificationActive",
  isPhoneNotificationActive: "isPhoneNotificationActive",
  isActive: "isActive",
  permissions: "permissions",
  userType: "userType"
};

export const validation = (URL_ID) =>
  URL_ID
    ? yup.object().shape({

      [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
      [fieldNames.lastName]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.lastName)).label(fieldNames.lastName),
      [fieldNames.email]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.email)).label(fieldNames.email),
      [fieldNames.password]: yup
        .string()
        .when({
          is: (val) => val !== undefined && val !== null && val !== '',
          then: yup.string().min(6, ERRORS.min(6)),
        })
        // .min(6, ERRORS.min(6))
        // .nullable()
        .label("Password"),
      // .test("", "Password must at least 8 characters, including uppercase,lowercase,number and symbol", (value) => {
      //   const re = new RegExp(regex.passwordRegExp);
      //   if (value) return re.test(value)
      //   else return true
      // })
      [fieldNames.confirm_password]: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
      [fieldNames.userType]: yup.object().label(fieldNames.userType),
      [fieldNames.role]: yup.object().nullable().label(fieldNames.role),
      [fieldNames.phoneNumber]: yup.string().label(fieldNames.phoneNumber),
      [fieldNames.isActive]: yup.object().label(fieldNames.isActive),
      [fieldNames.isEmailNotificationActive]: yup.bool().default(false).label("isEmailNotificationActive"),
      [fieldNames.isPhoneNotificationActive]: yup.bool().default(false).label("isPhoneNotificationActive"),

      // [fieldNames.type]: yup.object().required(ERRORS.pleaseSelectYour("Type")).label("Type"),
      // [fieldNames.image]: yup.object().nullable().required(ERRORS.pleaseSelectYour("image")).label("image"),
    })
    : yup.object().shape({

      [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
      [fieldNames.lastName]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.lastName)).label(fieldNames.lastName),
      [fieldNames.email]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.email)).label(fieldNames.email),
      [fieldNames.password]: yup
        .string()
        .min(6, ERRORS.min(6))

        // .matches(regex.passwordRegExp, "Password must at least 8 characters, including uppercase,lowercase,number and symbol")
        .required(ERRORS.pleaseEnterYour("Password"))
        .label("Password"),
      [fieldNames.confirm_password]: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
      [fieldNames.role]: yup.object().nullable().label(fieldNames.role),
      [fieldNames.phoneNumber]: yup.string().label(fieldNames.phoneNumber),
      [fieldNames.isActive]: yup.object().label(fieldNames.isActive),
      [fieldNames.isEmailNotificationActive]: yup.bool().default(false).label("isEmailNotificationActive"),
      [fieldNames.isPhoneNotificationActive]: yup.bool().default(false).label("isPhoneNotificationActive"),
      // [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour("First name")).label("name"),
      // [fieldNames.lastName]: yup.string().required(ERRORS.pleaseEnterYour("Last name")).label("LastName"),
      // [fieldNames.email]: yup.string().required(ERRORS.pleaseEnterYour("Email")).label("Email"),
      // [fieldNames.password]: yup
      //   .string()
      //   .matches(regex.passwordRegExp, "Password must at least 8 characters, including uppercase,lowercase,number and symbol")

      //   .required(ERRORS.pleaseEnterYour("Password"))
      //   .label("Password"),
      // [fieldNames.confirm_password]: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
      // [fieldNames.role]: yup.object().label("Role"),
      // [fieldNames.phoneNumber]: yup.string().label("Mobile Number"),
      // [fieldNames.status]: yup.object().label("Status"),
      // [fieldNames.image]: yup.object().nullable().required(ERRORS.pleaseSelectYour("image")).label("image"),
      // [fieldNames.type]: yup.object().required(ERRORS.pleaseSelectYour("Type")).label("Type"),
    });
