import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PERMISSION } from "../enum";
import { tableData } from "./tableInfo";
import { useSelector } from "react-redux";

import BoxWithLabelValue from "./../../../components/BoxWithLabelValue/index";
import { Col, Row } from "react-bootstrap";
import FinanceModal from "containers/Finance/component/GalleryModal";
import isoToDate from "utils/isoToDate";
import { changePriceFormat } from "utils/calculators";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import moment from "moment";

const List = () => {
	const [checkList, setCheckList] = useState();
	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const { t } = useTranslation();

	// ---------------------------------------------- Constance
	const adminState = useSelector((state) => state.admin);
	const validPermission = adminState?.data?.permissions === true;
	const permissions = validPermission || adminState?.data?.permissions?.[PERMISSION];
	const isBranch = adminState.data.userType === "branch";
	const isHotel = adminState.data.userType === "hotel";
	const isAdmin = adminState.data.userType?.includes("admin");

	const QueryString = useQueryString({ sort: "desc" });
	const { page, limit } = QueryString;
	const dispatch = useDispatch();

	// ---------------------------------------------- fetching functions
	const getting = async () =>
		await axiosClient().get(api[API_NAME].list, {
			params: { ...QueryString },
		});
	const changeStatus = async (params) => {
		console.log(params, "changeStatus");
		await axiosClient().patch(api[API_NAME].changeStatus, params);
	};

	const deleting = async (params) => {
		await axiosClient().delete(api[API_NAME].delete + "/" + params?.id);
	};
	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get`,
		url: getting,
		params: { ...QueryString },
		enabled: !!(validPermission || permissions?.list),
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessDeleteMutation = () => {
		toastify.success({ title: "success" });
	};

	const deleteMutation = useMutationCustom({
		name: `${API_NAME}_delete`,
		url: deleting,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessDeleteMutation,
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessChangeStatusMutation = () => {
		toastify.success({ title: "success" });
	};

	const changeStatusMutation = useMutationCustom({
		name: `${API_NAME}_changeStatus`,
		url: changeStatus,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessChangeStatusMutation,
	});

	const exportToCSV = ({ apiData, fileName, head, cols }) => {
		const workbook = XLSX.utils.book_new();
		// const myHeader = ["id","name","location"];
		const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e["c"] = head.length - 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);
		worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		worksheet["!cols"] = cols;

		XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
		XLSX.writeFile(workbook, "excel_export.xlsx");
	};
	// ---------------------------------------------- logs
	console.log({ data, error, isError, isLoading });
	// ---------------------------------------------- action click table

	const actionsOperation = ({ data, status }) => {
		swal({
			title: "Are you sure?",
			text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				if (status.label === "delete") deleteMutation.mutate({ id: data });
				else changeStatusMutation.mutate({ ids: data, isActive: status.value });
			}
		});
	};

	const clickAction = ({ data, status }) => {
		const newData = data.map((x) => x.id);
		actionsOperation({ data: newData, status });
	};

	const handleAction = (status) => {
		const data = Object.keys(checkList);
		console.log({ data, status });
		actionsOperation({ data, status: status });
	};
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [{ title: "Dashboard", link: routes.dashboard.base }, { title: COMPONENT_NAMES[0], link: newRoute.base }, { title: "List" }];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);

	// const handleShowModal = () => setShowModal(true)
	// const handleHideModal = () => setShowModal(false)

	const tableOptions = tableData({
		isBranch,
		isHotel,
		isAdmin,
		onClick: clickAction,
		newRoute,
		active: {
			// manuel: {
			//   icon: 'mdi mdi-source-branch',
			//   class: 'bg-success',
			//   label: "change branch",
			//   onClick: (data) => {
			//     setShowModal(data)
			//   }
			// }
		},
	});
	const headButtonData = [
		{
			label: "Toplam Bakiye",
			value: data?.totalCommission && "₺ " + changePriceFormat(data?.totalCommission, 2),
		},
	];

	if (!isBranch)
		headButtonData.push(
			{
				label: "Toplam Ödenen",
				value: "₺ " + changePriceFormat(data?.totalPaid && data?.totalPaid, 2),
			},
			{
				label: "Kalan",
				value:
					// data?.totalPaid &&
					"₺ " + changePriceFormat(data?.totalCommission - data?.totalPaid, 2),
			},
			{
				label: "Most Recent Request Date",
				value: data?.nextPaymentDate && isoToDate(data?.nextPaymentDate),
			}
		);

	const [showModal, setShowModal] = useState(false);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};

	const gettingDataExcel = () =>
		axiosClient().get(api[API_NAME].list, {
			params: { ...QueryString, limit: 0, page: 1 },
		});

	const handleGetExcel = () => {
		let cols = [
			{ wch: 30 },
			{ wch: 15 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
		];
		gettingDataExcel()
			.then((x) => {
				console.log("x ===>", { x });
				let head = [],
					data;
				if (isBranch) {
					// head = [t("name"), t("commission"), t("orderNumber"), t("date"), t("clock")];
					data = x?.data?.result?.map((y) => {
						const date = moment(y?.createdAt);
						return {
							[t("name")]: `${y?.name}`,
							[t("commission")]: y?.commission || "",
							[t("orderNumber")]: y?.orderNumber || "-",
							// [t("price")]: y?.price || "-",
							[t("date")]: date.format("YYYY-MM-DD"),
							[t("clock")]: date.format("HH:mm:ss"),
						};
					});
				} else {
					// head = [t("branch"), t("orderCount"), t("totalCommission")];
					data = x?.data?.result?.map((y) => {
						// const date = moment(y?.createdAt);

						return {
							[t("branch")]: y?.branch?.name || "",
							[t("orderCount")]: y?.orderCount || "",
							[t("totalCommission")]: y?.totalCommission || "-",
							// [t("date")]: date.format("YYYY-MM-DD"),
							// [t("clock")]: date.format("HH:mm:ss"),
						};
					});
				}
				exportToCSV({ apiData: data, fileName: "exc", head, cols });
				console.log(" ===>", data);
			})
			.catch((err) => {
				console.log({ err });
			});
	};

	const button = false; // { title: "Request Payment", onClick: handleGalleryModal };
	//  ----------------------------------------- LOGS ----------------------------------------- //
	console.log({ checkList, data }, "checkList");

	return (
		<div>
			<PageTitle title={t("listsAuthor", { author: t(COMPONENT_NAMES[0]) })} breadcrumbs={breadcrumbs} button={!isAdmin && button} />
			<FinanceModal
				{...{
					showModal,
					handleModal: handleGalleryModal,
					isGalleryDisabled: true,
				}}
			/>
			{!isAdmin ? (
				<Row className="my-5">
					{headButtonData?.map((x) => {
						return (
							<Col xs={4}>
								{" "}
								<BoxWithLabelValue {...x} />
							</Col>
						);
					})}
				</Row>
			) : (
				""
			)}
			<TableComponent
				{...{
					QueryString,
					data: data?.result,
					tableOptions,
					loading: isLoading,
					search: {
						placeholder: `Search ${COMPONENT_NAMES[0]}`,
						defaultValue: QueryString.search_text,
					},
					title: `${COMPONENT_NAMES[1]}`,
					button: button,
					count: data.total,
					pagination: {
						activePage: page,
						pages: Math.ceil(data.total / limit),
						limited: 2,
					},
					checkbox: {
						state: checkList,
						setState: setCheckList,
					},
					actions: {
						onClick: handleAction,
					},
					status: STATUSES,
					dating: true,
					excelButton: {
						show: isBranch || true,
						disabled: !data?.result,
						onClick: handleGetExcel,
					},
				}}
			/>
			{/* <BranchModal  {...{ show: showModal, setShow: setShowModal, refetchKey: `${API_NAME}_get` }} /> */}
		</div>
	);
};

export default List;
