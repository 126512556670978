import { ERRORS } from "enumerations";
import * as yup from "yup";
export const fieldNames = {
  note: "note",
};



export const validation = yup.object().shape({
  [fieldNames.note]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.note)).label(fieldNames.note),
});
