import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert';
const AlertComponent = ({ error }) => {
  const [state, setState] = useState();

  useEffect(() => {
    if (!error) return;
    const valid = Object.keys(error).filter((a) => a)?.length;
    console.log({ valid });
    if (!valid) return;

    setState(error);
    setTimeout(() => {
      setState(false)
    }, 10000);
  }, [error])
  console.log({ error }, "AlertComponent");
  return (
    state ? <Alert variant={state?.type}>
      {state?.message}
    </Alert> : <></>
  )
}

// AlertComponent.defaultProps = {
//   error: {
//     message: "This is a alert—check it out!",
//     type: "info",
//   }

// }

export default AlertComponent
