import { IS_WORKING } from "enumerations";
import { Children } from "react";
import { Button, Col } from "react-bootstrap";

const IsWorking = ({ param, onClick, slice }) => {
  let foundStatus = IS_WORKING.find((status) => status.value === param?.isWorking);
  let status = foundStatus?.value;
  return (
    <Button
      id="dropdown-basic"
      className="btn-sm filter-sort mr-3"
      variant={`${
        status ? "success" : "danger"
        // ?
        //   // "secondary"
        //   "danger"
        //   : status === "ACTIVE" ? "success" : status === "DELETE" ? "danger" : status === "BLOCK" ? "warning" : "dark"
      }
        `}
    >
      <span>{foundStatus?.label}</span>
    </Button>
  );
};

export default IsWorking;
