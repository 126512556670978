import html2canvas from "html2canvas";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";

// function PrintComponent(reference) {
//     const content = reference.current.innerHTML;
//     const printWindow = window.open('', '_blank');
//     if (printWindow) {
//         printWindow.document.write(`
//             <html>
//             <head>
//                 <title>Print</title>
//             </head>
//             <body>${content}</body>
//             </html>
//         `);

//         setTimeout(() => {
//             printWindow.document.close();
//             printWindow.print();
//         }, 1000)
//     } else {
//         alert('Please allow pop-ups for this site');
//     }

// }

function PrintComponentIframe(Component, fileName) {
	let myframe = document.createElement("iframe");
	myframe.style.position = "absolute";
	myframe.style.top = "-10000px";

	if (fileName) myframe.setAttribute("title", fileName);

	document.body.appendChild(myframe);

	ReactDOM.render(Component, myframe.contentDocument.body);
	myframe.focus();

	setTimeout(function () {
		myframe.contentWindow.print();
		myframe.parentNode.removeChild(myframe);
		window.focus();
	}, 1000);
}

// function PrintComponent(Component) {
//     const content = ReactDOMServer.renderToStaticMarkup(Component);
//     const printWindow = window.open('', '_blank');
//     const pri = window.open('', '_blank');
//     pri.document.write(content);
//     setTimeout(function () {
//         pri.document.close();
//         pri.print();
//         // pri.close();
//     }, 2000);

// }

// // const head = printWindow.document.head;
// function PrintComponent(Component, fileName) {
//     const printWindow = window.open('', '_blank');
//     if (printWindow) {

//         const title = fileName || "print";

//         printWindow.document.write(`
//         <!DOCTYPE html>
//             <html>
//             <head>
//                 <meta charset="utf-8" />
//                 <title>${title}</title>
//                 <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous" />
//             </head>
//             <body></body>
//             </html>
//         `);

//         // ReactDOM.hydrateRoot(printWindow.document.body, Component);
//         ReactDOM.render(Component, printWindow.document.body);

//         printWindow.document.close();
//         // printWindow.onafterprint = () => {
//         //     printWindow.close();
//         // };
//         printWindow.onload = function () {
//             printWindow.setTimeout(async () => {
//                 printWindow.print();
//                 //     printWindow.close();
//             }, 1000);
//         };

//     } else {
//         alert('Please allow pop-ups for this site');
//     }

// }

// ReactDOM.render(Component, printWindow.document.body);
// printWindow.document.close();
// setTimeout(() => {
//     printWindow.print();
//     printWindow.close();
// }, 2000)

export default PrintComponentIframe;
