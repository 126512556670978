
import { Col, Row } from "react-bootstrap";
import styles from "./index.module.scss";

const TableFiltersSelector = ({ data }) => {

    return (
        <div className={styles.box}>
            <Row>

                {
                    data?.map((x) => {
                        return (
                            <Col xs={6} className={styles.boxItem}>{x}</Col>
                        )
                    })
                }
            </Row>

        </div>
    )
}

export default TableFiltersSelector;