import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { Children, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";
import FieldDate from "components/FieldDate";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import ProductSelector, { convertProductData } from "../../Product/ProductSelector";

import {
	DRINK_DATA,
	CURRENCY_DATA,
	CURRENCY_OBJECT,
	DRINK_DATA_OBJECT,
	INVOICE_DATA,
	INVOICE_DATA_OBJECT,
	IS_WORKING,
	IS_WORKING_OBJECT,
	LANGUAGE_DATA,
	LANGUAGE_DATA_OBJECT,
	ROLES,
	STATUSES,
	STATUSES_OBJECT,
} from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import CheckBox from "components/CheckBox";
import BranchSelector, { convertBranchData } from "containers/Branch/BranchSelector";
import CategorySelector, { convertCategoryData } from "containers/Category/CategorySelector";
import GalleryComponent from "components/gallery/GalleryComponent";
import LanguageSelector from "containers/Language/LanguageSelector";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Add = () => {
	const { t } = useTranslation();

	const navigation = useNavigate();
	const location = useLocation();

	let { id, menuId } = useParams();
	const URL_ID = id ? id : "";
	const MENU_URL_ID = menuId === "all" ? false : menuId;

	const [showPass, setShowPass] = useState(false);
	const [showConfirmPass, setShowConfirmPass] = useState(false);
	const [type, setType] = useState(null);
	// ------------------------------------ route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const previousQuery = location?.state?.query;

	const typeOptions = [
		{ label: "translation", value: "translation" },
		{ label: "Admin", value: "ADMIN" },
	];

	// ----------------------------------------------------------------------------- Fetching Functions
	const creating = async (params) =>
		await axiosClient().post(api[API_NAME].base + (MENU_URL_ID ? "/item/" + MENU_URL_ID : "/add-item-all-menus/"), params);
	const updating = async (params) => await axiosClient().put(api[API_NAME].base + (MENU_URL_ID ? "/item/" + URL_ID : "/add-item-all-menus/"), params);
	const getById = async () => await axiosClient().get(api[API_NAME].get + URL_ID);
	// const getRoles = async () => await axiosClient().get(api["role"].list);
	console.log({ MENU_URL_ID }, MENU_URL_ID ? "/item/" : "/add-item-all-menus/");

	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(validation(URL_ID)),
		mode: "onSubmit",
	});

	// ----------------------------------------------------------------------------- Mutation
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		const params = new URLSearchParams(previousQuery || {});
		navigation(newRoute.base + newRoute.list + `?${params.toString()}`);
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: URL_ID ? updating : creating,
		invalidQuery: `getById_${API_NAME}`,
		onSuccess: onSuccessMutating,
	});

	// ----------------------------------------------------------------------------- Query By ID
	const onSuccessDataById = ({ data }) => {
		let params = { ...data };
		// let selectedStatus = STATUSES.find((item) => item.value === params.isActive);
		console.log("params ==>", { params });

		// handleSelectThumbnail({ _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title });
		let date = "2017-03-13";

		const resetParam = {
			// translate_language: { label: x.language, value: x.language },
			// translations: params?.translations?.map((x) => ({
			//   translate_name: x.name,
			//   translate_shortDesc: x.shortDesc,
			//   translate_desc: x.desc,
			//   translate_language: { label: x.language, value: x.language },
			// })),
			// image: { _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title },
		};
		console.log({ resetParam });
		reset(resetParam);
	};
	const dataById = useQueryCustom({
		name: `getById_${API_NAME}`,
		url: getById,
		onSuccess: onSuccessDataById,
		enabled: !!URL_ID,
	});

	//  ----------------------------------------- GETTING ROLES ----------------------------------------- //
	// const onSuccessRoles = ({ data }) => {
	//   setRolesOption(convertToLabelValue(data?.result));
	// };
	// const rolesData = useQueryCustom({
	//   name: "rolesData",
	//   url: getRoles,
	//   onSuccess: onSuccessRoles,
	//   params: { sort: "desc", page: 1, limit: 10, status: 1 },
	// });
	// --------------------------------------- Submit Form
	const onSubmit = () => {
		const values = getValues();
		const { items } = values;

		console.log("values ==>", { values });

		const reqData = {
			items: items.map((x) => ({
				product: x.product?.value,
				price: x.price,
				kdv: x.kdv,
				currency: x.currency?.value,
				delivery: x.delivery?.value,
				isRecommended: !!x.isRecommended,
				hideInAll: !!x.hideInAll,
			})),
		};
		console.log({ reqData });
		mutate(reqData);
	};

	// ----------------------------------------------------------------------------- Constance
	const dispatch = useDispatch();
	const button = {
		title: `Back To ${COMPONENT_NAMES[0]} List`,
		link: newRoute.base + newRoute.list,
	};

	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: COMPONENT_NAMES[0], link: newRoute.base },
		{
			title: URL_ID ? `Edit / ${dataById?.data?.data?.name} ` : "Add",
		},
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, [dataById]);
	// ----------------------------------------------------------------------------- Log
	const values = getValues();
	console.log({ dataById, values }, "Add");
	console.log({ URL_ID });
	console.log({ errors }, "errors");

	// ----------------------------------------------------------------------------- UseEffects

	//  ----------------------------------------- GALLERY ----------------------------------------- //
	const [showModal, setShowModal] = useState(false);
	const [thumbnail, setThumbnail] = useState(null);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};

	const defaultData = {};

	// const addNewtranslation = () => {
	//   const values = getValues();
	//   const newData = values[fieldNames.translations]
	//     ? [...values[fieldNames.translations], { ...defaultData }]
	//     : [{ ...defaultData }, { ...defaultData }];
	//   console.log({ newData });
	//   setValue(fieldNames.translations, newData);
	//   // reset({ ...values, [fieldNames.locale]: values[fieldNames.locale] ? [...values[fieldNames.locale], { ...defaultEpisode }] : [{ ...defaultEpisode }, { ...defaultEpisode }] })
	// };

	const isRecommendedState = watch(fieldNames.isRecommended);

	const addNewItem = () => {
		const values = getValues();
		const newData = values[fieldNames.items] ? [...values[fieldNames.items], { ...defaultData }] : [{ ...defaultData }, { ...defaultData }];
		console.log({ newData });
		setValue(fieldNames.items, newData);
	};
	return (
		<div>
			{/* <GalleryModal
        {...{
          showModal,
          handleModal: handleGalleryModal,
          handleClickedImage: handleSelectThumbnail,
          selectedPhoto: thumbnail,
        }}
      /> */}
			<PageTitle title={URL_ID ? `Edit  ${COMPONENT_NAMES[0]}` : `Add ${!MENU_URL_ID ? " item to all " : ""} ${COMPONENT_NAMES[0]} `} button={button} />
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				<div className="row mt-5">
					<Col md="8">
						{/* <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{"Basic Information"}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <FieldText
                        require
                        {...{
                          name: fieldNames.price,
                          register,
                          placeholder: "price",
                          label: "price ",
                          errors,
                          type: "number",
                        }}
                      />
                      <FieldText
                        require
                        {...{
                          name: fieldNames.kdv,
                          register,
                          placeholder: "kdv",
                          label: "kdv",
                          errors,
                          type: "number",
                        }}
                      />
                      <ProductSelector
                        {...{
                          Controller,
                          name: fieldNames.product,
                          register,
                          label: "product",
                          errors,
                          control,
                        }}
                      />


                      <FieldSelect
                        require
                        {...{
                          Controller,
                          name: fieldNames.currency,
                          register,
                          label: "currency",
                          errors,
                          control,
                          options: CURRENCY_DATA,
                        }}
                      />

                      <FieldSelect
                        {...{
                          Controller,
                          name: fieldNames.delivery,
                          register,
                          label: "delivery",
                          errors,
                          control,
                          options: DRINK_DATA,
                        }}
                      />


 
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

						<div className="mt-5">
							<div className="col-12">
								<div className="basic-form">
									<Row className="b-1">
										<Col lg={6}>
											<h2 className="">Items</h2>
										</Col>
										<Col lg={6} className="d-flex justify-content-end">
											<Button onClick={addNewItem} className={"d-flex align-items-center"} size="sm">
												<div className={"gallery-add-title"}>Add Item</div>
												<div className={"ml-1"}>+</div>
											</Button>
										</Col>
									</Row>
									<div className="mt-5">
										<div className="col-12">
											<div className="basic-form"></div>
											<Controller
												name={fieldNames.items}
												control={control}
												render={({ field: { onChange, value } }) => {
													console.log({ value });

													return Children.toArray(
														(value || [{ ...defaultData }]).map((locale, index) => {
															const removeClick = (index) => {
																let values = getValues();
																if (!values[fieldNames.items]) return;
																const newValues = values[fieldNames.items].filter((param, i) => i !== index);
																setValue(fieldNames.items, newValues);
															};

															return (
																<Row className="items-row" key={value?.translate_email || index}>
																	{true ? (
																		<>
																			<Col lg={10}>
																				<h5>Item{index + 1}</h5>
																			</Col>
																			<Col lg={2}>
																				<div>
																					<Button
																						color="warning"
																						className={"d-flex align-items-center"}
																						size="sm"
																						onClick={(e) => {
																							e.preventDefault();
																							removeClick(index);
																						}}>
																						remove
																					</Button>
																				</div>
																			</Col>
																			<Col lg={12}>
																				<hr style={{ marginBottom: 0 }} />
																			</Col>
																		</>
																	) : (
																		""
																	)}

																	<ProductSelector
																		require
																		{...{
																			Controller,
																			name: `items.[${index}].product`,
																			register,
																			label: "product",
																			error: errors.items?.[index]?.product,
																			control,
																		}}
																	/>

																	<FieldText
																		require
																		{...{
																			Controller,
																			register,
																			control,
																			name: `items.[${index}].price`,
																			placeholder: "price",
																			label: "price",
																			error: errors.items?.[index]?.price,
																		}}
																	/>
																	<FieldText
																		// require
																		{...{
																			Controller,
																			register,
																			control,

																			name: `items.[${index}].kdv`,
																			placeholder: "kdv",
																			label: "kdv",
																			error: errors.items?.[index]?.kdv,
																		}}
																	/>

																	<FieldSelect
																		{...{
																			label: "currency",
																			Controller,
																			name: `items.[${index}].currency`,
																			register,
																			error: errors.items?.[index]?.currency,
																			control,
																			options: CURRENCY_DATA,
																		}}
																	/>
																	<FieldSelect
																		isClearable
																		{...{
																			label: "delivery",
																			Controller,
																			name: `items.[${index}].delivery`,
																			register,
																			error: errors.items?.[index]?.delivery,
																			control,
																			options: DRINK_DATA,
																		}}
																	/>

																	<CheckBox
																		{...{
																			// Controller,\
																			control,
																			name: `items.[${index}].isRecommended`,
																			register,
																			label: "isRecommended",
																			errors,
																		}}
																	/>

																	<CheckBox
																		{...{
																			// Controller,\
																			control,
																			name: `items.[${index}].hideInAll`,
																			register,
																			label: "hideInAll",
																			errors,
																		}}
																	/>
																</Row>
															);
														})
													);
												}}
											/>
										</div>
									</div>

									{/* <FieldText
                        require
                        {...{
                          name: fieldNames.lastName,
                          register,
                          placeholder: "lastName",
                          label: "lastName",
                          errors,
                          // type: "number"
                        }}
                      />
                      <FieldText
                        require
                        {...{
                          name: fieldNames.phoneNumber,
                          register,
                          placeholder: "phoneNumber",
                          label: "phoneNumber",
                          errors,
                          // type: "number"
                        }}
                      /> */}

									{/* <CheckBox
                        {...{
                          // Controller,
                          control,

                          name: fieldNames.serviceCharge,
                          register,
                          label: "serviceCharge",
                          errors,
                        }}
                      /> */}
								</div>
							</div>
						</div>
					</Col>
					<Col md="4">
						<div className="row">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Actions"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											{/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

											{/* <hr /> */}

											<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
												{!isLoading ? t("publish") : "Loading..."}
											</Button>
										</div>
									</div>
								</div>
							</Col>
						</div>
					</Col>
				</div>
			</form>
		</div>
	);
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
