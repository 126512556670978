import routes from "operations/routing/routes";
import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import getDataInObjectDepth from "utils/getDataInObject";
import Add from "./Add";
import { ROUTE_NAME, PERMISSION } from "./enum";
import List from "./List";
import checkComponentRoute from "utils/checkComponentRoute";
import { ADMIN_REDIRECT } from "enumerations/enums";
import { useSelector } from 'react-redux';

const Language = () => {
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

  const adminState = useSelector((state) => state.admin);
  const redirect = ADMIN_REDIRECT(adminState?.data)

  return (
    <Routes>
      <Route index element={<Navigate to={newRoute.base + newRoute.list} />} />
      <Route path={newRoute.list} element={<List />} />
      <Route path={newRoute.add} element={checkComponentRoute(PERMISSION, "create", adminState, redirect, <Add />)} />
      <Route path={`${newRoute.edit}/:id`} element={checkComponentRoute(PERMISSION, "edit", adminState, redirect, <Add />)} />
    </Routes>
  );
};

export default Language;
