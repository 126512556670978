import initialState from './initialState';

export const request = (state, { params }) => {
    return ({
        ...state,
        isFetching: true,
        params,
    })
};

export const receive = (state, { data }) => {
    return ({
        ...state,
        isFetching: false,
        errorMessage: null,
        data
    })
};

export const failure = (state, { errorMessage }) => ({
    ...state,
    isFetching: false,
    errorMessage,
});



export const initial = () => ({ ...initialState });