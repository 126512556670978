import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import useDebounce from "hook/useDebounce";

const GoogleMapComponent = ({ onChange, value }) => {
	const [lock, setLock] = useState(false);
	const [autocompleteService, setAutocompleteService] = useState(false);
	const defaultValue = value?.lat ? value : { lat: 53.54992, lng: 10.00678 };
	const [position, setPosition] = useState(defaultValue); // Initial position for Rasht
	const [clickPosition, setClickPosition] = useState(value?.lat ? value : null);
	// const [searchTerm, setSearchTerm] = useState("");
	const [suggestions, setSuggestions] = useState([]);
	// const autocompleteService = useRef(null); // Ref for autocomplete service
	const mapRef = useRef(null); // Reference to the Map instance
	const searchInputRef = useRef(null); // Reference to the Map instance
	// const [zoom, setZoom] = useState(20); // Initial zoom level

	const { t } = useTranslation();
	// Function to get user location

	const onApiLoad = () => {
		if (window.google && window.google.maps && window.google.maps.places) {
			//  return	autocompleteService =
			setAutocompleteService(new window.google.maps.places.AutocompleteService());
		} else {
			console.error("Google Maps API or places library not available.");
		}
	};
	useEffect(() => {
		onApiLoad();
		if (value?.lat) return;

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { lat, lng } = position.coords;
					if (lat) setPosition({ lat: lat, lng: lng });
					// onChange?.({ lat: lat, lng: lng });
				},
				(error) => {
					console.error("* * * GoogleMapComponent : ", "Error getting user location:", error);
				}
			);
		} else {
			console.log("* * * GoogleMapComponent : ", "Geolocation is not supported by this browser.");
		}
	}, [window]);

	// Initialize Google Places Autocomplete
	useEffect(() => {
		if (!value) return;
		if (lock) return;
		setLock(true);
		if (value?.lat != clickPosition?.lat) {
			setPosition(value);
			setClickPosition(value);
		}
	}, [value]);

	// Function to handle search term input
	// const handleChange = (e) => {
	// 	console.log("* * * GoogleMapComponent : ", { handleChange: e });
	// 	const value = e.target.value;
	// 	// setSearchTerm(value);
	// 	searchInputRef.current.value = value;
	// 	if (value.length > 2) {
	// 		console.log("* * * GoogleMapComponent : ", { autocompleteService });

	// 		autocompleteService?.getPlacePredictions?.({ input: value }, (predictions, status) => {
	// 			if (status === window.google.maps.places.PlacesServiceStatus.OK) {
	// 				setSuggestions(predictions);
	// 			} else {
	// 				setSuggestions([]);
	// 			}
	// 		});
	// 	} else {
	// 		setSuggestions([]);
	// 	}
	// };
	const handleChange = (e) => {
		console.log("* * * GoogleMapComponent : ", { handleChange: e });
		const value = e.target.value;
		searchInputRef.current.value = value;

		// Regex to detect Google Maps URLs with coordinates
		const urlRegex = /@(-?\d+\.\d+),(-?\d+\.\d+),/;
		const match = value.match(urlRegex);

		if (match) {
			const lat = parseFloat(match[1]);
			const lng = parseFloat(match[2]);
			console.log({ lat, lng });
			setPosition({ lat, lng });
			onChange({ lat, lng });
			setClickPosition({ lat, lng });
			setSuggestions([]);
			mapRef.current && mapRef.current.setZoom(17);
		} else if (value.length > 2) {
			autocompleteService?.getPlacePredictions?.({ input: value }, (predictions, status) => {
				if (status === window.google.maps.places.PlacesServiceStatus.OK) {
					setSuggestions(predictions);
				} else {
					setSuggestions([]);
				}
			});
		} else {
			setSuggestions([]);
		}
	};

	// Function to handle suggestion selection
	const handleSuggestionSelect = (suggestion) => {
		if (autocompleteService && suggestion.place_id) {
			const service = new window.google.maps.places.PlacesService(mapRef.current);

			service.getDetails({ placeId: suggestion.place_id }, (place, status) => {
				if (status === window.google.maps.places.PlacesServiceStatus.OK && place.geometry) {
					const lat = place.geometry.location.lat();
					const lng = place.geometry.location.lng();
					setPosition({ lat, lng });
					setClickPosition({ lat, lng });
					onChange({ lat, lng });
					// setSearchTerm(suggestion.description);
					searchInputRef.current.value = suggestion.description;

					setSuggestions([]);
					mapRef.current && mapRef.current.setZoom(20);
				} else {
					console.error("* * * GoogleMapComponent : ", "Error fetching place details:", status);
				}
			});
		}
	};

	const { debounce } = useDebounce();

	console.log("* * * GoogleMapComponent : ", { value, position, autocompleteService });
	return (
		<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={["places"]}>
			{/* Search Box */}
			<div style={{
		width: "100%",
		height: "100%", 
		display:"flex",
		flexDirection:"column"
}}>

			<input
				ref={searchInputRef}
				// value={searchTerm}
				onChange={(e) => debounce(e, handleChange)}
				type="text"
				placeholder={t("search")}
				style={{
					width: "300px",
					height: "40px",
					padding: "0 12px",
					top: "10px",
					left: "10px",
					zIndex: 5,
				}}
			/>
			<div
				style={{
					position: "relative",
				}}>
				{/* Suggestions Dropdown */}
				{suggestions.length > 0 && (
					<ul
						style={{
							position: "absolute",
							top: "0px", // Adjust as needed
							left: "0",
							width: "300px",
							backgroundColor: "white",
							border: "1px solid #ccc",
							maxHeight: "200px",
							overflowY: "auto",
							zIndex: 10,
							padding: "0",
							margin: "0",
							listStyleType: "none",
						}}>
						{suggestions.map((suggestion) => (
							<li
								key={suggestion.place_id}
								onClick={() => handleSuggestionSelect(suggestion)}
								style={{
									padding: "10px",
									cursor: "pointer",
									borderBottom: "1px solid #ccc",
								}}>
								{suggestion.description}
							</li>
						))}
					</ul>
				)}
			</div>

			<GoogleMap
				center={position}
				onLoad={(mapInstance) => {
					mapRef.current = mapInstance; // Assign the map instance to mapRef
					console.log("Maps API has loaded.");
					onApiLoad();
				}}
				zoom={15}
				options={{
					draggable: true,
					zoomControl: true,
					scrollwheel: true,
					fullscreenControl: true,
					disableDefaultUI: true,
				}}
				onClick={(event) => {
					const lat = event.latLng.lat();
					const lng = event.latLng.lng();
					console.log({ lat, lng });
					setClickPosition({ lat, lng });
					onChange({ lat, lng });
				}}
				mapContainerStyle={{
					width: "100%",
					height: "100%", // Adjust height as needed
				}}>
				{clickPosition && <Marker position={clickPosition} />}
			</GoogleMap>
</div>

		</LoadScript>
	);
};

export default GoogleMapComponent;
