import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, PRIORITY, PRIORITY_OBJECT, ROUTE_NAME } from "../enum";
import { ROLES, STATUSES } from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";
import { useTranslation } from "react-i18next";
import moment from "moment"
import FieldDate from "components/FieldDate";

const Add = () => {
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [type, setType] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();

  // ------------------------------------ route
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
  const previousQuery = location?.state?.query;
  const adminState = useSelector((state) => state.admin);
  const validPermission = adminState?.data?.permissions === true
  // const permissions = validPermission || adminState?.data?.permissions?.[PERMISSION]

  // ----------------------------------------------------------------------------- Fetching Functions
  const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
  const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
  const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);
  // const getRoles = async () => await axiosClient().get(api["role"].list);

  const navigation = useNavigate();
  let { id } = useParams();
  const URL_ID = id ? id : "";
  // ----------------------------------------------------------------------------- Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validation(URL_ID)),
    mode: "onSubmit",
  });
  const watchFile = watch(fieldNames.file)
  // ----------------------------------------------------------------------------- Mutation
  const onSuccessMutating = () => {
    toastify.success({ title: "success" });
    const params = new URLSearchParams(previousQuery || {});
    navigation(newRoute.base + newRoute.list + `?${params.toString()}`);
  };
  const { isLoading, data, mutate } = useMutationCustom({
    url: URL_ID ? updating : creating,
    invalidQuery: `getById_${API_NAME}`,
    onSuccess: onSuccessMutating,
  });

  // ----------------------------------------------------------------------------- Query By ID
  const onSuccessDataById = ({ data }) => {
    let params = { ...data };
    console.log({ params });
    const resetData = {
      ...params,
      [fieldNames.priority]: PRIORITY_OBJECT[params?.[fieldNames.priority]],
      [fieldNames.dueDate]: params?.dueDate ? new Date(params?.dueDate) : null,
    }

    console.log({ resetData });

    reset(resetData);
  };
  const dataById = useQueryCustom({
    name: `getById_${API_NAME}`,
    url: getById,
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });

  //  ----------------------------------------- GETTING ROLES ----------------------------------------- //
  // const onSuccessRoles = ({ data }) => {
  //   setRolesOption(convertToLabelValue(data?.result));
  // };
  // const rolesData = useQueryCustom({
  //   name: "rolesData",
  //   url: getRoles,
  //   onSuccess: onSuccessRoles,
  //   params: { sort: "desc", page: 1, limit: 10, status: 1 },
  // });
  // --------------------------------------- Submit Form
  const onSubmit = () => {
    const values = getValues();

    if (URL_ID) {
      const reqData = {
        title: values.title,
        solution: values.solution,
        text: values.text,
        priority: values.priority?.value,
        [fieldNames.dueDate]: values[fieldNames.dueDate],
      }
      console.log({ reqData, values });

      mutate(reqData);

    } else {
      const formData = new FormData();
      formData.append("title", values?.title);
      formData.append("solution", values?.solution);
      formData.append("text", values?.text);
      formData.append("priority", values?.priority?.value);
      if (values?.file) formData.append("file", values?.file);
      if (fieldNames.dueDate) formData.append(fieldNames.dueDate, moment(values[fieldNames.dueDate]));
      console.log({ formData, values });
      mutate(formData);
    }

  };

  // ----------------------------------------------------------------------------- Constance
  const dispatch = useDispatch();
  const button = {
    title: `Back To ${COMPONENT_NAMES[0]} List`,
    link: newRoute.base + newRoute.list,
  };

  const breadcrumbs = [
    { title: "Dashboard", link: routes.dashboard.base },
    { title: COMPONENT_NAMES[0], link: newRoute.base },
    {
      title: URL_ID ? `Edit / ${dataById?.data?.data?.result?.name} ${dataById?.data?.data?.result?.name}` : "Add",
    },
  ];
  // ---------------------------------------------- EFFECTS
  useEffect(() => {
    dispatch(actionBreadcrumbs.set(breadcrumbs));
  }, [dataById]);
  // ----------------------------------------------------------------------------- Log
  const values = getValues();
  console.log({ dataById, values }, "Add");
  console.log({ URL_ID });
  console.log({ errors }, "errors");

  // ----------------------------------------------------------------------------- UseEffects

  //  ----------------------------------------- GALLERY ----------------------------------------- //
  const [showModal, setShowModal] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const handleGalleryModal = () => {
    setShowModal(!showModal);
  };
  const handleSelectThumbnail = (thumbnail) => {
    setValue(fieldNames.file, thumbnail, {
      shouldValidate: true,
    });
    setThumbnail(thumbnail);
  };

  return (
    <div>
      <GalleryModal
        manual={{
          onChange: (e) => setValue(fieldNames.file, e)
        }}
        {...{
          showModal,
          handleModal: handleGalleryModal,
          handleClickedImage: handleSelectThumbnail,
          selectedPhoto: thumbnail,
        }}
      />
      <PageTitle title={URL_ID ? t(`editAuthor`, { author: t(COMPONENT_NAMES[0]) }) : t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) })} button={button} />
      <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
        <div className="row mt-5">
          <Col md="8">
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{"Basic Information"}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <FieldText
                        require
                        {...{
                          name: fieldNames.title,
                          register,
                          placeholder: "title",
                          label: "title",
                          errors,
                        }}
                      />

                      <FieldText
                        require
                        {...{
                          name: fieldNames.solution,
                          register,
                          placeholder: "solution",
                          label: "solution",
                          errors,
                        }}
                      />
                      <FieldText
                        require
                        {...{
                          name: fieldNames.text,
                          register,
                          placeholder: "text",
                          label: "text",
                          errors,
                          mode: "textarea"
                        }}
                      />
                      <FieldSelect
                        {...{
                          label: "priority",
                          Controller,
                          name: fieldNames.priority,
                          errors: errors,
                          register,
                          control,
                          options: PRIORITY,
                        }}
                      />
                      {
                        validPermission ?
                          <FieldDate
                            require
                            {...{
                              control,
                              name: fieldNames.dueDate,
                              register,
                              placeholder: " 11:20 am",
                              label: "dueDate",
                              errors,
                            }}
                            showTimeSelect={false}
                          />
                          : ""
                      }
                      <GalleryComponent
                        isRequired
                        manual={!URL_ID}
                        // manual={{
                        //   file:watchFile
                        // }}
                        {...{
                          handleModal: !URL_ID && handleGalleryModal,
                          selectedPhoto: thumbnail,
                          labe: "image",
                          name: fieldNames.file,
                          control,
                          errors,
                          register,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col md="4">
            <div className="row">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="">
                    <h4 className="card-title">{"Actions"}</h4>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      {/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

                      {/* <hr /> */}

                      <Button
                        block
                        type="success"
                        htmlType="submit"
                        className={`btn btn-success btn-sm`}
                        loading={isLoading}
                      >
                        {!isLoading ? t("publish") : "Loading..."}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </div>
      </form>
    </div>
  );
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
