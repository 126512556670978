import routes from "operations/routing/routes";
import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import getDataInObjectDepth from "utils/getDataInObject";
import Add from "./Add";
import AddItemMenu from "./AddItemMenu";
import AddItem from "./AddItem";
import AddWithIntegrationItem from "./AddWithIntegrationItem";
import { ROUTE_NAME, PERMISSION } from "./enum";
import List from "./List";
import { ADMIN_REDIRECT } from "enumerations/enums";
import { useSelector } from "react-redux";
import checkComponentRoute from "utils/checkComponentRoute";
import AddItemToMenus from "containers/Menus/AddItemToMenus";
import RemoveWithIntegration from "containers/Menus/RemoveWithIntegration";

const Menus = () => {
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

	const adminState = useSelector((state) => state.admin);
	const redirect = ADMIN_REDIRECT(adminState?.data);

	return (
		<Routes>
			<Route index element={<Navigate to={newRoute.base + newRoute.list} />} />
			<Route path={newRoute.list} element={<List />} />
			<Route path={newRoute.add} element={checkComponentRoute(PERMISSION, "create", adminState, redirect, <Add />)} />
			<Route
				path={newRoute.addItem + "/remove-menus-with-integration-codes"}
				element={checkComponentRoute(PERMISSION, "create", adminState, redirect, <RemoveWithIntegration />)}
			/>
			<Route
				path={newRoute.addItem + "/add-menu-with-integration-codes"}
				element={checkComponentRoute(PERMISSION, "create", adminState, redirect, <AddWithIntegrationItem />)}
			/>
			<Route path={newRoute.addItem + "/all"} element={checkComponentRoute(PERMISSION, "addItemAllMenus", adminState, redirect, <AddItem />)} />
			<Route path={newRoute.addItem + "/:menuId"} element={checkComponentRoute(PERMISSION, "addItem", adminState, redirect, <AddItemMenu />)} />
			<Route
				path={newRoute.addItemsToMenus + "/:productId?"}
				element={checkComponentRoute(PERMISSION, "addItemAllMenus", adminState, redirect, <AddItemToMenus />)}
			/>

			<Route path={`${newRoute.edit}/:id`} element={checkComponentRoute(PERMISSION, "edit", adminState, redirect, <Add />)} />
		</Routes>
	);
};

export default Menus;
