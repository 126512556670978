export const API_NAME = "roomReport";
export const ROUTE_NAME = "roomReport";
export const COMPONENT_NAMES = ["roomReport", "roomReports"];
export const PERMISSION = "roomReports";

export const sortHourData = (data) => {
	const sortedHour = [8, 9, 10, 11, 12, 13, 14, 16, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3];
	let sorting = {};
	let sortingArray = [];
	for (let i = 0; i < sortedHour.length; i++) {
		const curr = sortedHour[i];
		sorting[curr] = {
			hour: curr,
			id: i,
			index: i,
		};
		sortingArray.push({
			hour: curr,
			index: i,
			id: i,
		});
	}
	for (let i = 0; i < data?.length; i++) {
		const curr = data[i];
		//   let   Object.values(sorting)
		sortingArray[sorting?.[curr.hour]?.index] = curr;
	}
	console.log("* * * sortHourData :", { data, sorting });

	return sortingArray; //Object.values(sorting)
};
