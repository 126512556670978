import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import { tableData } from "./tableInfo";
import { useTranslation } from "react-i18next";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ModalLayout from "components/ModalLayout";
// import AddItemToMenus from "containers/Menus/AddItemToMenus";
import styles from "./index.module.scss";

const List = () => {
	const { t } = useTranslation();
	const [checkList, setCheckList] = useState();
	const [menuModalState, setMenuModalState] = useState();
	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	// ---------------------------------------------- Constance
	const button = {
		title: t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) }),
		link: newRoute.base + newRoute.add,
	};
	const QueryString = useQueryString({ sort: "desc" });
	const { page, limit } = QueryString;
	const dispatch = useDispatch();
	const adminState = useSelector((state) => state.admin);
	const isAdmin = adminState.data.userType === "admin";
	// ---------------------------------------------- fetching functions
	const getting = async () =>
		await axiosClient().get(api[API_NAME].list, {
			params: { ...QueryString },
		});
	const changeStatus = async (params) => {
		console.log(params, "changeStatus");
		await axiosClient().patch(api[API_NAME].changeStatus, params);
	};
	const deleting = async (params) => {
		await axiosClient().delete(api[API_NAME].delete + "/" + params?.id);
	};
	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get`,
		url: getting,
		params: { ...QueryString },
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessDeleteMutation = () => {
		toastify.success({ title: "success" });
	};

	const deleteMutation = useMutationCustom({
		name: `${API_NAME}_delete`,
		url: deleting,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessDeleteMutation,
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessChangeStatusMutation = () => {
		toastify.success({ title: "success" });
	};

	const changeStatusMutation = useMutationCustom({
		name: `${API_NAME}_changeStatus`,
		url: changeStatus,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessChangeStatusMutation,
	});
	// ---------------------------------------------- logs
	console.log({ data, error, isError, isLoading });
	// ---------------------------------------------- action click table

	const actionsOperation = ({ data, status }) => {
		swal({
			title: "Are you sure?",
			text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				if (status.label === "delete") deleteMutation.mutate({ id: data });
				else changeStatusMutation.mutate({ ids: data, isActive: status.value });
			}
		});
	};

	const clickAction = ({ data, status }) => {
		const newData = data.map((x) => x.id);
		actionsOperation({ data: newData, status });
	};

	const handleAction = (status) => {
		const data = Object.keys(checkList);
		console.log({ data, status });
		actionsOperation({ data, status: status });
	};

	// ---------------------------------------------- breadcrumbs

	const handleShowAddItemModal = (x) => setMenuModalState(x);
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [{ title: "Dashboard", link: routes.dashboard.base }, { title: COMPONENT_NAMES[0], link: newRoute.base }, { title: "List" }];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);

	const tableOptions = tableData({
		onClick: clickAction,
		newRoute,
		active: {
			manuel: [
				{
					icon: "mdi mdi-content-copy",
					route: "add",
				},
				{
					icon: "mdi mdi-food",
					route: routes.menus.base + routes.menus.addItemsToMenus,
					class: "btn-success",
					// onClick: handleShowAddItemModal
					manual: true,
				},
			],
		},
	});

	//  ----------------------------------------- LOGS ----------------------------------------- //
	console.log({ checkList, data }, "checkList");
	const gettingStatistics = async () => {
		const { from, to } = QueryString;

		return await axiosClient().get(api[API_NAME].list, {
			params: { from, to },
		});
	};

	const exportToCSV = ({ apiData, fileName, head, cols }) => {
		const workbook = XLSX.utils.book_new();
		// const myHeader = ["id","name","location"];
		const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e["c"] = head.length - 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);
		worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		worksheet["!cols"] = cols;

		XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
		XLSX.writeFile(workbook, "excel_export.xlsx");
	};

	const handleGetReports = () => {
		let cols = [{ wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 30 }, { wch: 30 }];
		gettingStatistics()
			.then((x) => {
				console.log(" * *  handleGetReports :", { x });
				let headObject = {};
				const head = [t("hotel"), t("region"), t("orderCount"), t("scanCount")];

				let calcAverage = {
					orderCount: 0,
					scanCount: 0,
				};

				const data = x?.data?.map((y) => {
					calcAverage.orderCount += y?.orderCount || 0;
					calcAverage.scanCount += y?.scanCount || 0;

					let newData = {
						[t("hotel")]: y.hotel?.name || "",
						[t("region")]: y.region?.name || "",
						[t("orderCount")]: y.orderCount,
						[t("scanCount")]: y.scanCount,
					};

					// y.regions.forEach((z, j) => {
					// 	if (!headObject[`${""}(${z.region}) ${t("orderCount")}`]) {
					// 		cols.push({ wch: 30 });
					// 		head.push(`${""}(${z.region}) ${t("orderCount")}`);
					// 	}
					// 	if (!headObject[`${""}(${z.region}) ${t("scanCount")}`]) {
					// 		cols.push({ wch: 30 });
					// 		head.push(`${""}(${z.region}) ${t("scanCount")}`);
					// 	}

					// 	calcAverage[`${""}(${z.region}) ${t("orderCount")}`] = calcAverage[`${""}(${z.region}) ${t("orderCount")}`] || 0;
					// 	calcAverage[`${""}(${z.region}) ${t("orderCount")}`] += z.orderCount;

					// 	calcAverage[`${""}(${z.region}) ${t("scanCount")}`] = calcAverage[`${""}(${z.region}) ${t("scanCount")}`] || 0;
					// 	calcAverage[`${""}(${z.region}) ${t("scanCount")}`] += z.scanCount;

					// 	// ----------------------------------------------------------------------------
					// 	headObject[`${""}(${z.region}) ${t("orderCount")}`] = true;
					// 	headObject[`${""}(${z.region}) ${t("scanCount")}`] = true;
					// 	// ----------------------------------------------------------------------------
					// 	newData[`${""}(${z.region}) ${t("orderCount")}`] = z.orderCount;
					// 	newData[`${""}(${z.region}) ${t("scanCount")}`] = z.scanCount;
					// });

					return newData;
				});

				let finalData = {
					// [t("hour")]: "",
					// [t("totalOrders")]: calcAverage.totalOrders,
					// [t("totalPrice")]: calcAverage.totalPrice,
					// [t("totalScan")]: calcAverage.totalScan,
					// [t("avg")]: calcAverage.avg,
				};

				for (const key in calcAverage) {
					if (Object.hasOwnProperty.call(calcAverage, key)) {
						const curr = calcAverage[key];
						finalData[t(key)] = curr;
					}
				}

				data.push(finalData);

				console.log("* * * handleGetReports", { data, cols, finalData });

				exportToCSV({
					apiData: data,
					fileName: `statistic-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}`,
					head,
					cols,
				});
			})
			.catch((err) => {
				console.log({ err });
			});
	};

	return (
		<div>
			<PageTitle
				title={t("listsAuthor", { author: t(COMPONENT_NAMES[0]) })}
				breadcrumbs={breadcrumbs}
				// button={button}
			/>
			{/* <ModalLayout {...{
        show: menuModalState,
        setShow: setMenuModalState,
        classNames: {
          modal: styles.modal,
          dialog: styles.modalDialog,
          content: styles.modalContent,
        },
        title: "add item to menus"
      }}>
        <AddItemToMenus product={menuModalState} handleClose={setMenuModalState} />
      </ModalLayout> */}
			<TableComponent
				{...{
					QueryString,
					data: data,
					tableOptions,
					loading: isLoading,
					search: {
						placeholder: t("search"),

						defaultValue: QueryString.search_text,
					},
					title: `${COMPONENT_NAMES[1]}`,
					button: button,
					count: data?.length,
					pagination: {
						activePage: page,
						pages: Math.ceil(data?.total / limit),
						limited: 2,
					},
					checkbox: {
						state: checkList,
						setState: setCheckList,
					},
					actions: {
						onClick: handleAction,
					},
					status: STATUSES,
					excelButton: {
						show: true,
						disabled: !data,
						onClick: handleGetReports,
					},
					dating: {
						from: {
							changeTime: (date) => date.setHours(8, 0, 0, 0),
						},
						to: {
							changeTime: (date) => date.setHours(3, 0, 0, 0),
						},
					},
				}}
			/>
		</div>
	);
};

export default List;
