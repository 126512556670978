import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import IsWorking from "components/IsWorkingStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute }) => [
  {
    header: "actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
  {
    header: "name",
    Component: ({ param }) => param.firstName,
  },
  {
    header: "lastName",
    Component: ({ param }) => param.lastName,
  },
  {
    header: "phoneNumber",
    Component: ({ param }) => param.phoneNumber,
  },
];
