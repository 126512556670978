import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE, USER_TYPES_OBJECT } from "enumerations";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute, id, ...props }) =>
  // columns: ["Name", "Network type", "Default", "Actions"],
  // body: data?.map((param) => {
  //     return [
  //         param.name,
  //         param.network_type,
  //         (param.is_default)?.toString(),
  //         () => {
  //             return (
  //                 <div className="d-flex">
  //                     <Link to={routes[ROUTE_NAME].edit + '/' + param.id} className="btn btn-primary shadow btn-xs sharp mr-1"  >
  //                         <i className="fa fa-pencil"></i>
  //                     </Link>
  //                     <span className="btn btn-danger shadow btn-xs sharp pointer" onClick={() => onClick({ param, type: 'delete' })} >
  //                         <i className="fa fa-trash pointer"></i>
  //                     </span>
  //                 </div>
  //             )
  //         }
  //     ]
  // }),
  [
    // {
    //   header: "Image",
    //   Component: ({ param }) => {
    //     return (
    //       <>
    //         <span className="d-block mb-1">
    //           <img src={param?.image?.completedUrl || PLACEHOLDER_IMAGE} className="image-fluid" />
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      header: "Actions",
      Component: ({ param }) => <TableAction {...{ param, newRoute: newRoute, onClick, id, ...props }} />,
    },
    {
      header: "Name",
      Component: ({ param }) => `${param.name} ${param.lastName}` || "Unknown",
    },

    {
      header: "Email",
      Component: ({ param }) => param.email,
    },
    {
      header: "Email notification",
      Component: ({ param }) => {
        return (
          // <span className={`${param.type === "ADMIN" ? "text-primary" : param.type === "USER" ? "text-success" : "text-secondary"} text-capitalize`}>{param?.type?.toLowerCase() || "Unknown"}</span>
          <CheckedComponent checked={param.isEmailNotificationActive} />
        );
      },
    },
    {
      header: "Phone notification",
      Component: ({ param }) => {
        return (
          // <span className={`${param.type === "ADMIN" ? "text-primary" : param.type === "USER" ? "text-success" : "text-secondary"} text-capitalize`}>{param?.type?.toLowerCase() || "Unknown"}</span>
          <CheckedComponent checked={param.isPhoneNotificationActive} />
        );
      },
    },
    {
      header: "userType",
      Component: ({ param }) => USER_TYPES_OBJECT[param?.userType]?.label || "-",
    },
    {
      header: "role",
      Component: ({ param }) => `${param?.role?.name || "-"}`,
    },
    // {
    //   header: "Created by",
    //   Component: ({ param }) => `${param?.createdBy?.firstName + param?.createdBy?.lastName || "Unknown"}`,
    // },
    {
      header: "createdAt",
      Component: ({ param }) => isoToDate(param.createdAt),
    },
    {
      header: "Status",
      Component: ({ param }) => <TableStatus {...{ param, onClick, disabled: props?.disabled?.edit }} />,
    },

  ];
