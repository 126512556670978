import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

const DownloadExcelAllRoomNumbers = ({ qrCodeUrl, data, fileName }) => {
	const { t } = useTranslation();

	const exportToCSV = ({ apiData, fileName, head, cols }) => {
		const workbook = XLSX.utils.book_new();
		// const myHeader = ["id","name","location"];
		const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e["c"] = head.length - 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);
		worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		worksheet["!cols"] = cols;

		XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
		XLSX.writeFile(workbook, fileName + ".xlsx");
	};

	const handleExcel = () => {
		let ExcelData = [
			{
				roomNumber: "-",
				address: qrCodeUrl,
			},
		];
		if (data?.roomNumbers?.length)
			ExcelData.push(
				...data?.roomNumbers?.map((x) => ({
					roomNumber: x,
					address: qrCodeUrl + `&roomNumber=${x}`,
				}))
			);

		let cols = [{ wch: 30 }, { wch: 120 }];

		const head = [t("roomNumber"), t("address")];
		const apiData = ExcelData?.map((y) => ({
			[t("roomNumber")]: y?.roomNumber,
			[t("address")]: y?.address,
		}));
		console.log("apiData =====>", apiData);

		exportToCSV({ apiData, fileName: `${data?.name}-room-numbers`, head, cols });
	};
	//qrCodeUrl + `&roomNumber=${x}`
	return (
		<div>
			<Button block type="button" htmlType="button" className={`btn btn-danger btn-sm mt-5`} onClick={handleExcel}>
				{t("download-urls")}
			</Button>
		</div>
	);
};

export default DownloadExcelAllRoomNumbers;
