import CheckedComponent from "components/CheckedComponent";
import DropDownBootstrap from "components/DropDownBootstrap";
import DynamicStatus from "components/DynamicStatus";
import TableAction from "components/TableAction";
import { STATUSES_BASE } from "enumerations";
import routes from "operations/routing/routes";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute, hide, ...props }) => [
  ...(!hide.action ? [{
    header: "Actions",
    Component: ({ param }) => (
      <TableAction {...{ param, newRoute, onClick, ...props }} />
    ),
  }] : []),
  {
    header: "products",
    Component: ({ param }) => {
      return <Button variant="info" size="sm">view</Button>
    },
    subTable: {
      getData: (param) => param?.products,
      options: [
        {
          header: "product",
          Component: ({ param, parent }) => {
            const { base, edit } = routes.product;
            // const currRegion = props?.isAdmin ? param?.product : param?.hotel?.product
            const curr = param?.product;
            return curr ? (
              props?.isAdmin ?
                <Link to={`${base + edit}/${curr?.id}`} target="_blank">
                  {" "}
                  {curr?.name}{" "}
                </Link>
                : curr?.name
            ) : (
              "-"
            );
          },
        },
        {
          header: "beforeBuyPrice",
          Component: ({ param }) => param?.product?.buyPrice || "-",
        },
        {
          header: "buyPrice",
          Component: ({ param }) => param.buyPrice
        }
      ]
    }
  },
  ...(!hide.branch ? [{
    header: "branch",
    Component: ({ param }) => {
      const { base, edit } = routes.branch;
      console.log({ param });
      // const currRegion = props?.isAdmin ? param?.product : param?.hotel?.product
      const curr = param?.requester;
      return curr ? (
        <Link to={`${base + edit}/${curr?.id}`} target="_blank">
          {" "}
          {curr?.name || "-"}{"  "}
        </Link>
      ) : (
        "-"
      );
    }
  }] : []),
  {
    header: "status",
    Component: ({ param }) => {
      // console.log({ param, ORDER_STATUS_OBJECT });
      return param?.status ? (
        <DynamicStatus
          {...{
            value: param?.status,
            options: STATUSES_BASE
          }}
        />
      ) : (
        "-"
      );
    },
  },

  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
];
