import actionTypes from "./actionTypes";
import initialState from "./initialState";

export const request = (state, { queryOptions }) => ({
	...state,
	isFetching: true,
	queryOptions,
});

export const receive = (state, { data }) => ({
	...state,
	isFetching: false,
	errorMessage: null,
	data,
});

export const initial = (state, { errorMessage }) => ({
	...state,
	isFetching: false,
	errorMessage,
	data: null,
});

const reducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case actionTypes.REQUEST:
			return request(state, action);
		case actionTypes.RECEIVE:
			return receive(state, action);
		case actionTypes.INITIAL:
			return initial(state, action);
		default:
			return state;
	}
};

export default reducer;
