import { delay, fork, take, call, put, cancel, all, takeEvery, select } from "redux-saga/effects";

import io from "socket.io-client";
import { eventChannel } from "redux-saga";
import actionTypes from "redux/store/socket/actionTypes";
import actions from "redux/store/socket/action";

import api from "operations/network/api";
import orderAction from "containers/Order/redux/store/action";
import orderActionTypes from "containers/Order/redux/actionTypes";
import SocketToastComponent from "components/SocketToastComponent";
import toastify from "lib/toastify";
// import { QueryClient } from "react-query";
import { API_NAME } from "./../../containers/Order/enum";
import { queryClient } from "./../../index";
import { USER_LOGOUT } from "../store/admin/actionTypes";

export function connect(token) {
	console.log("* * * socket", { token });
	const socketConnection = io(api.host + "/orders?authorization=" + token, {
		transports: ["websocket"],
		rejectUnauthorized: false,
		forceNew: true,

		// origin: "http://localhost:3000",

		// query: {
		//   aaaaa: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1vcmFkaS5tb2p0YWJhMjY3NEBnbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE2Njk2MTkwNzgsImV4cCI6MTY3MjIxMTA3OH0.ZgmCGrYMW1jQM-S_CEriJfqoYG-_9fZynDb_ReYHpeI"
		// },

		// extraHeaders: {
		//   "aaaaaaa": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1vcmFkaS5tb2p0YWJhMjY3NEBnbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE2Njk2MTkwNzgsImV4cCI6MTY3MjIxMTA3OH0.ZgmCGrYMW1jQM-S_CEriJfqoYG-_9fZynDb_ReYHpeI",
		//   "moj": "mat"
		// },
		// headers: {
		//   "authorization": token

		// }
		// auth: {
		//   aaaaaaaaaaaaaaa: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1vcmFkaS5tb2p0YWJhMjY3NEBnbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE2Njk2MTkwNzgsImV4cCI6MTY3MjIxMTA3OH0.ZgmCGrYMW1jQM-S_CEriJfqoYG-_9fZynDb_ReYHpeI"
		// },
		// extraHeaders: {
		//   authorization: token
		// },
		// auth: {
		//   authorization: token
		// },
		// transportOptions: {
		//   polling: {
		//     extraHeaders: { authorization: token }
		//   },
		// }
	});
	console.log("* * * socket socketConnection", { socketConnection });

	return new Promise((resolve) => {
		socketConnection.on("connect", () => {
			console.log("* * * socket CONNECT", socketConnection.connected);
			resolve(socketConnection);
		});
		socketConnection.on("disconnect", (data) => {
			console.log("* * * socket DISCONNECTED");
		});
		socketConnection.on("reconnect", (data) => {
			console.log("* * * socket RECONNECT");
		});
	});
}

function subscribe(socket) {
	return eventChannel((emit) => {
		// console.log({ emit });

		const handler = (data) => {
			console.log("* * * socket Data in emitter ->>>>>>>", data);
			emit(data);
		};

		socket.on("connect", handler.bind(null, "Connected!"));
		socket.on("welcome", handler);
		// console.log("socket listening on NewAvatar");
		socket.on("new_order", (e) => handler({ ...e, isNewOrder: true }));
		socket.on("disconnect", handler);

		return () => {
			socket.off("new_order", handler);
			socket.off("welcome", handler);
			socket.off("disconnect", handler);
			socket.off("serverStatus", handler);
		};
	});
}

// function* reconnect(socket) {
//   const state = yield select(state => state.token);
//   const token = state.data?.access_token;

//   while (true) {
//     try {
//       console.log("* * * socket Reconnecting...");
//       const newSocket = yield call(connect, token);
//       yield put(actions.receive(newSocket));
//       const newChannel = yield call(subscribe, newSocket);

//       // Re-subscribe to the channel
//       yield put(orderAction.socket(newSocket));

//       while (true) {
//         let event = yield take(newChannel);

//         // ... (handle events as needed)
//       }
//     } catch (error) {
//       console.log("* * * socket Reconnect failed, retrying in 5 seconds...", error);
//       yield delay(5000); // Retry after 5 seconds
//     }
//   }
// }

let interval;

export function* socketConnectSaga(data) {
	// console.log("socket initial ->>>>>>>", data);
	const state = yield select((state) => state.token);

	const token = state.data?.access_token;
	const socket = yield call(connect, token);
	clearInterval(interval);
	// alert()
	console.log("* * * socket", { socket });

	// if (socket.connected) {

	if (socket) {
		yield put(actions.receive(socket));
		const keepAliveInterval = 30000;
		// Function to send a keep-alive message
		function sendKeepAlive(socket) {
			// console.log('* * * socket Keep alive message socket', { socket });
			// console.log('* * * socket Keep alive message socket', { readyState: socket.connected, OPEN: WebSocket.OPEN });
			if (socket.connected) {
				// console.log('* * * socket Keep alive message socket', new Date())
				// socket.send('* * * socket Keep alive message');
				socket.emit("keepAlive", "Keep alive message");
			}
		}

		// Set up an interval to send keep-alive messages
		interval = setInterval(() => {
			sendKeepAlive(socket);
		}, keepAliveInterval);
	}

	const channel = yield call(subscribe, socket);
	// console.log({ channel });

	yield put(orderAction.socket(socket));

	// console.log({ stateSocket });
	try {
		while (true) {
			let event = yield take(channel);

			console.log("* * * socket", { event });
			if (event.isNewOrder) {
				toastify.info({
					title: <SocketToastComponent />,
					autoClose: false,
					closeOnClick: false,
				});
				queryClient.invalidateQueries(`${API_NAME}_get`);
			}

			// let out = yield take(USER_LOGOUT);
			// console.log("* * * ouuuut : ", { out });
		}
	} catch (error) {
		console.log("* * * socket Error in saga chanel ->>>>>>>", error);
	}
}

export default function* rootSaga() {
	yield all([takeEvery(actionTypes.REQUEST, socketConnectSaga)]);
}
