import React, { Fragment } from "react";
// import { InputError } from "components/forms/InputError";
import AsyncSelect from "react-select/async";
import { API_NAME } from "./enum";
import axiosClient from "lib/axiosConfig";
import { useQueryCustom } from "utils/useQueryCustom";
import api from "operations/network/api";
import { useMemo } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import compareTranslate from "utils/compareTranslate";
import { Form } from "react-bootstrap";

const LoadingIndicator = () => {
	return (
		<div className="mx-2">
			<Spinner animation="grow" size="sm" />
		</div>
	);
};

export const convertBranchesData = (data) =>
	data?.map?.(
		(x) =>
			({
				label: (
					<div className="flex align-items-center justify-items-center">
						<div className="ml-4 mt-1 d-flex align-items-center">{x.name}</div>
					</div>
				),
				value: x?.id || x?._id,
				data: x,
			} || [])
	);

export const convertBranchData = (x) => ({
	label: (
		<div className="flex align-items-center justify-items-center">
			<div className="ml-4 mt-1 d-flex align-items-center">{x?.name}</div>
		</div>
	),
	value: x?.id || x?._id,
	data: x,
});

const BranchSelector = ({ name, control, label, valid, register, errors, require, options, setState, isDisabled, ...other }) => {
	// const [dataForSelectBox, setDataForSelectBox] = React.useState([])
	const [t] = useTranslation();

	const getting = async (params) => {
		return await axiosClient().get(api[API_NAME].list, {
			params,
		});
	};
	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get`,
		url: getting,
		// params: {}
		// params: { ...QueryString },
	});

	const loadOptions = async (inputValue, callback) => {
		const params = {
			search_text: inputValue,
			limit: 5,
			page: 1,
		};
		await getting(params).then((x) => {
			callback(convertBranchesData(x?.data?.result));
		});
		// console.log({ param });
		// callback(param);
		// await GetAll(query)
		//   .then((response) => {
		//     const {data} = response.data

		//     callback(filtersData(data?.data))
		//   })
		//   .catch((err) => {})
	};
	// React.useEffect(() => {
	//     async function getInitData() {
	//         const query = {}
	//         await GetAll(query)
	//             .then((response) => {
	//                 const { data } = response.data

	//                 let arrayForSelect = []

	//                 data?.data.forEach((item) => {
	//                     arrayForSelect.push({
	//                         value: item,
	//                         label: (
	//                             <div className='flex align-items-center justify-items-center'>
	//                                 <div className='ml-4 mt-1 d-flex align-items-center'>{item.title}</div>
	//                             </div>
	//                         ),
	//                     })
	//                 })

	//                 setDataForSelectBox(arrayForSelect)
	//             })
	//             .catch((err) => { })
	//     }
	// }, [])
	const dataForSelectBox = useMemo(() => {
		return convertBranchesData(data?.result);
	}, [data]);
	let newRegister = register ? register(name) : {};

	// console.log({ data, dataForSelectBox });

	return (
		<>
			<Form.Group className="form-group ">
				{label ? (
					<div className={"d-flex justify-content-between"}>
						<label htmlFor={name}>
							{compareTranslate(t, label)}
							{require ? " *" : ""}
						</label>
						{errors ? <div className={`error-text `}>{compareTranslate(t, errors[name]?.message)}</div> : ""}
					</div>
				) : (
					""
				)}
				{/* {control ? */}
				{control ? (
					<Controller
						control={control}
						ref={newRegister}
						name={name}
						render={({ field: { onChange, value } }) => {
							function handleChange(params) {
								onChange?.(params);
								setState?.(params);
							}
							// console.log({ onChange, value, options },'');
							return (
								<AsyncSelect
									className="custom-selector"
									cacheOptions
									loadOptions={loadOptions}
									defaultOptions={dataForSelectBox}
									onChange={handleChange}
									components={{ LoadingIndicator }}
									classNamePrefix="select"
									styles={{
										input: (base) => ({
											...base,
											"input:focus": {
												boxShadow: "none",
											},
										}),
										control: (styles) => ({
											...styles,
											borderColor: error ? "red" : styles.borderColor,
										}),
									}}
									// validationState={error ? "error" : "default"}
									value={value}
									{...other}
								/>
							);
							//   return <Select className="custom-selector" placeholder={label} options={newOptions}
							//    value={value} onChange={handleChange} {...other} />
						}}
					/>
				) : (
					<AsyncSelect
						className="custom-selector"
						cacheOptions
						loadOptions={loadOptions}
						defaultOptions={dataForSelectBox}
						onChange={setState}
						components={{ LoadingIndicator }}
						classNamePrefix="select"
						styles={{
							input: (base) => ({
								...base,
								"input:focus": {
									boxShadow: "none",
								},
							}),
							control: (styles) => ({
								...styles,
								borderColor: error ? "red" : styles.borderColor,
							}),
						}}
						// validationState={error ? "error" : "default"}
						isDisabled={isDisabled || isLoading}
						{...other}
					/>
				)}
				{/* :
        <Select className="custom-selector" options={options} {...other} />
      } */}
			</Form.Group>

			{/* <InputError error={error} /> */}
		</>
	);
};
export default BranchSelector;
