import toastify from "lib/toastify";
import api from "operations/network/api";
import { useEffect, useRef, useState } from "react";
import { convertBytes } from "utils/convertor";
import { useMutationCustom } from "utils/useMutationCustom";
import styles from "./UploadTab.module.scss";
import axiosClient from "lib/axiosConfig";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useQueryCustom } from "utils/useQueryCustom";
import Loading from "components/Loading";
import DragAndDrop from "./DragAndDrop";
import { useTranslation } from "react-i18next";
import Papa from "papaparse";

const UploadBox = ({ onClose }) => {
	const { t } = useTranslation();
	//  ----------------------------------------- STATES/REFS/CONST ----------------------------------------- //
	const formats = [".csv"];

	const [userUploadedFile, setUserUploadedFile] = useState(null);
	const [imageDimensions, setImageDimensions] = useState({});
	const imageRef = useRef(null);
	const [folderOptions, setFolderOptions] = useState([]);
	const [selectedFolder, setSelectedFolder] = useState(null);
	const [folderError, setFolderError] = useState(true);
	const [previewImage, setPreviewImage] = useState(null);
	const [inputValue, setInputValue] = useState("");

	const sendingFile = async (params) => await axiosClient().patch(api.priceUpdate, params);
	// const getFolders = async () => await axiosClient().get(api["folder"].list);

	//  ----------------------------------------- MUTATING ----------------------------------------- //
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		resetFields();
		onClose();
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: sendingFile,
		invalidQuery: `galley_get`,
		onSuccess: onSuccessMutating,
	});
	//  ----------------------------------------- GETTING FOLDERS ----------------------------------------- //
	// const onSuccessGettingFolders = ({ data }) => {
	//   setFolderOptions(selectConvert(data?.result));
	// };
	// const foldersData = useQueryCustom({
	//   name: "foldersList",
	//   url: getFolders,
	//   onSuccess: onSuccessGettingFolders,
	//   params: { sort: "desc", page: 1, limit: 10, status: 1 },
	// });
	//  ----------------------------------------- HANDLERS ----------------------------------------- //

	const handleSelectFile = (e) => {
		console.log({ e }, "handleSelectFile this is e");
		let validData = true;

		const file = e.target?.files?.[0] || e;
		if (file && file.type === "text/csv") {
			const reader = new FileReader();

			reader.onload = function (e) {
				const csvData = e.target.result;
				const supportedHead = ["id", "price", "buyPrice"];
				const supportedHeadA = ["id", "price"];
				const supportedHeadB = ["id", "buyPrice"];

				Papa.parse(csvData, {
					complete: function (results) {
						const headers = results.data[0]; // First row as headers
						const data = results.data?.slice(1); // Rest of the rows as data

						// Validate header length and content
						// if (headers?.length !== supportedHead.length) {
						// 	return toastify.error({
						// 		title: `Only "${supportedHead.join(", ")}" headers are acceptable in CSV!`,
						// 	});
						// }
						const headersMatch = headers.every((header, index) => {
							return supportedHead[index] === header || supportedHeadA[index] === header || supportedHeadB[index] === header;
						});
						if (!headersMatch) {
							return toastify.error({
								title: `CSV headers are incorrect. Expected: "${supportedHead.join(", ")}"`,
							});
						}

						console.log("handleSelectFile CSV data is valid!", results.data);

						// Data validation: ensure no `null`, `undefined`, or empty values
						data?.forEach((row, rowIndex) => {
							row?.forEach((cell, cellIndex) => {
								if ([null, undefined, ""].includes(cell) && cellIndex === 0) {
									validData = false;
								}
							});
						});

						if (!validData) {
							return toastify.error({ title: `Data must not contain empty or null values` });
						}

						// Format validation for files
						if (e.target) {
							let valid = formats.some((format) => file?.name?.toLowerCase().endsWith(format.toLowerCase()));
							if (!valid) {
								return toastify.error({
									title: `Only "${formats.join(", ")}" formats are acceptable!`,
								});
							}

							setUserUploadedFile(file);
						} else {
							setUserUploadedFile(e);
						}
					},
				});
			};

			reader.readAsText(file);
		} else {
			console.log("Please upload a valid CSV file.");
		}
	};
	// const handleSelectFile = (e) => {
	// 	console.log({ e }, "handleSelectFile this is e");
	// 	let validData = true;

	// 	const file = e.target?.files?.[0] || e;
	// 	if (file && file.type === "text/csv") {
	// 		const reader = new FileReader();

	// 		reader.onload = function (e) {
	// 			const csvData = e.target.result;
	// 			const supportedHead = ["id", "price", "buyPrice"];
	// 			Papa.parse(csvData, {
	// 				complete: function (results) {
	// 					const headers = results.data[0]; // Get the first row as headers
	// 					const data = results.data?.slice(1, results.data?.length); // Get the first row as headers
	// 					if (headers?.length > 3) return toastify.error({ title: `Only "${supportedHead.join(", ")}" head are acceptable in csv !` });
	// 					console.log("* * * handleSelectFile : ", { headers, data, results });
	// 					// Check if headers match
	// 					// const headersMatch = true;
	// 					const headersMatch = headers.every((header) => {
	// 						return ["id", "price", "buyPrice"].includes(header);
	// 					});
	// 					console.log("* * * headersMatch : ", { headersMatch });

	// 					if (!headersMatch) {
	// 						toastify.error({ title: `CSV headers are incorrect` });
	// 						return toastify.error({ title: `Only "${supportedHead.join(", ")}" head are acceptable in csv !` });
	// 					} else {
	// 						console.log(null);
	// 						// Process the CSV data here
	// 						console.log("handleSelectFile CSV data is valid!", results.data);
	// 					}
	// 					data?.forEach((x, i) => {
	// 						x?.forEach((y, j) => {
	// 							console.log("* * * headersMatch : ", { y, j });
	// 							y = undefined;
	// 							if ([null, undefined, ""].includes(y) && j == 0) {
	// 								validData = false;
	// 							}
	// 						});
	// 					});
	// 					if (!validData) return toastify.error({ title: `Data must not be null` });
	// 					if (e.target) {
	// 						let valid = formats.some((format) => e.target.files[0]?.name?.toLowerCase().endsWith(format.toLowerCase()));
	// 						if (!valid) {
	// 							return toastify.error({ title: `Only "${formats.join(", ")}" formats are acceptable !` });
	// 						}
	// 						// console.log({ file: e.target.files[0] }, "uploaded photo");
	// 						setUserUploadedFile(e.target.files[0]);
	// 					} else {
	// 						setUserUploadedFile(e);
	// 					}
	// 				},
	// 			});
	// 		};
	// 		reader.readAsText(file);
	// 	} else {
	// 		console.log("Please upload a valid CSV file.");
	// 	}
	// };

	const handleUploadButton = () => {
		const formData = new FormData();
		formData.append("file", userUploadedFile);
		// formData.append("folder", selectedFolder?.value);
		// inputValue && formData.append("title", inputValue);
		// console.log(formData, "form data");
		// else mutate(formData);
		mutate(formData);
	};

	const handleCancelButton = () => {
		resetFields();
	};

	//  ----------------------------------------- FUNCTIONS ----------------------------------------- //

	function resetFields() {
		setUserUploadedFile(null);
		setSelectedFolder(null);
		setFolderError(true);
		setInputValue("");
	}

	//  ----------------------------------------- EFFECTS ----------------------------------------- //
	useEffect(() => {
		if (userUploadedFile) {
			const reader = new FileReader();
			reader.onload = (e) => {
				// handleClickedImage(e.target.result);
				console.log({ e }, "reader");
				setPreviewImage(e.target.result);
			};
			reader.readAsDataURL(userUploadedFile);
		}
	}, [userUploadedFile]);

	return (
		<div className={styles.uploadTab}>
			<div className={styles.titleWrapper}>
				<h5>{t("uploadYourFile")} </h5>
			</div>
			{userUploadedFile ? (
				<div className={styles.flexWrapper}>
					{/* <div className={styles.selectedPhotoWrapper}>
						{previewImage ? <img ref={imageRef} src={previewImage} alt="uploaded photo" onLoad={handleImageLoad} /> : ""}
					</div> */}
					<div className={styles.controlWrapper}>
						<div className={styles.photoDetails}>
							<p>
								{t("name")}: {userUploadedFile?.name}
							</p>
							<p>
								{t("size")}: {imageDimensions.width || imageDimensions.height ? imageDimensions?.width + " × " + imageDimensions?.height : "Calculating..."} -{" "}
								{convertBytes(userUploadedFile?.size)}
							</p>
							<p>
								{t("type")}: {userUploadedFile?.type}
							</p>
						</div>
						<div className={styles.extraInformationWrapper}>
							{/* <h3 className="mb-0 text-capitalize">{t("upload")} :</h3> */}
							{/* <Form.Group className={styles.formGroup}>
                <div className={"d-flex justify-content-between"}>
                  <label htmlFor={"name"}>Title :</label>
                </div>
                <Form.Control id={"name"} name={"name"} type="text" placeholder="Choose a new title" onChange={handleInputValue} />
              </Form.Group>
              <Form.Group className={"form-group "}>
                <div className={"d-flex justify-content-between"}>
                  <label htmlFor={"folder"}>Folder * :</label>
                  {folderError && <div className={`error-text`}>Choose please !</div>}
                </div>
                <Select className="custom-selector" placeholder={"Choose a folder"} options={folderOptions} value={selectedFolder} onChange={handleSelectingFolder} />
              </Form.Group> */}

							<div className={styles.buttonsWrapper}>
								<button className="btn btn-success btn-sm mr-2 btn btn-primary" disabled={isLoading} onClick={handleUploadButton}>
									{isLoading ? <Loading size="sm" /> : "Upload"}
								</button>
								<button className="btn btn-danger btn-sm btn btn-primary" disabled={isLoading} onClick={handleCancelButton}>
									{t("cancel")}
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				// <div className={styles.uploadHereWrapper}>
				<DragAndDrop {...{ handleSelectFile, formats }}>
					<input type="file" name="file" id="file" accept=".csv" className={styles.fileInput} onChange={handleSelectFile} />
					<label htmlFor="file" className={styles.fileLabel}>
						{t("clickToChoose")}
					</label>
					<p>{t("or")}</p>
					<p className={styles.dragAndDrop}>{t("dragAnDrop")}</p>
					<p className={styles.accepted}>accepted files: .csv</p>
				</DragAndDrop>
				// </div>
			)}
		</div>
	);
};

export default UploadBox;
