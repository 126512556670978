import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
	name: "name",
	code: "code",
	image: "image",
	listOrderNumber: "listOrderNumber",
};

export const validation = (URL_ID) =>
	yup.object().shape({
		[fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
		[fieldNames.listOrderNumber]: yup
			.number()
			.nullable()
			.typeError(ERRORS.pleaseEnterYour(fieldNames.listOrderNumber))
			.required(ERRORS.pleaseEnterYour(fieldNames.listOrderNumber))
			.label(fieldNames.listOrderNumber),
		[fieldNames.code]: yup.string().label(fieldNames.code),
	});
