import actionTypes from "../actionTypes"


export default {
    request: (params) => ({
        type: actionTypes.REQUEST,
        params,
    }),
    receive: (data) => ({
        type: actionTypes.RECEIVE,
        data,
    }),
    initial: () => ({
        type: actionTypes.INITIAL,
    }),
    failure: (errorMessage) => ({
        type: actionTypes.FAILURE,
        errorMessage
    }),
    socket: (socket) => ({
        type: actionTypes.RUN_EVENTS,
        socket
    }),
}