import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import { changePriceFormat } from "utils/calculators";

export const tableData = ({ onClick, newRoute, id }) =>
  // columns: ["Name", "Network type", "Default", "Actions"],
  // body: data?.map((param) => {
  //     return [
  //         param.name,
  //         param.network_type,
  //         (param.is_default)?.toString(),
  //         () => {
  //             return (
  //                 <div className="d-flex">
  //                     <Link to={routes[ROUTE_NAME].edit + '/' + param.id} className="btn btn-primary shadow btn-xs sharp mr-1"  >
  //                         <i className="fa fa-pencil"></i>
  //                     </Link>
  //                     <span className="btn btn-danger shadow btn-xs sharp pointer" onClick={() => onClick({ param, type: 'delete' })} >
  //                         <i className="fa fa-trash pointer"></i>
  //                     </span>
  //                 </div>
  //             )
  //         }
  //     ]
  // }),
  [
    {
      header: "product Image",
      Component: ({ param }) => {
        return (
          <>
            <span className="d-block mb-1">
              <img src={param?.product?.images || PLACEHOLDER_IMAGE} className="image-fluid" />
            </span>
          </>
        );
      },
    },
    {
      header: "product name",
      Component: ({ param }) => param.product?.name,
    },
    {
      header: "integrationCode",
      Component: ({ param }) => param.product?.integrationCode,
    },
    {
      header: "currency",
      Component: ({ param }) => param.currency || "Unknown",
    },
    {
      header: "price",
      Component: ({ param }) => changePriceFormat(param.price) || "Unknown",
    },

    {
      header: "delivery",
      Component: ({ param }) => param.delivery,
    },
    {
      header: "kdv",
      Component: ({ param }) => param.kdv,
    },

    {
      header: "Actions",
      Component: ({ param }) => <TableAction {...{ param, newRoute: newRoute, onClick, id }} />,
    },
  ];
