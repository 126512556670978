import React, { useEffect, useRef } from 'react';
import "./index.scss";
function DropZoonUpload({ image, setImage }) {

    const uploadRef = useRef()
    const imageRef = useRef()

    useEffect(() => {
        let fileUpload = uploadRef.current  //document.querySelector(".upload");

        fileUpload.addEventListener("dragover", function () {
            this.classList.remove("drag");

            this.classList.add("drag");
            this.classList.remove("drop", "done");
        });

        fileUpload.addEventListener("dragleave", function () {
            this.classList.remove("drag");
        });

        fileUpload.addEventListener("drop", start, false);
        fileUpload.addEventListener("change", start, false);

        function start() {

            this.classList.remove("drop", "drag", "done");

            this.classList.add("drop");
            setTimeout(() => this.classList.add("done"), 3000);
        }
    }, [])


    function getBase64(file, callback) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            console.log(reader.result);
            callback(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    async function uploadFile(event) {
        if (!event.target.files.length) return
        setImage()
        let fileUpload = imageRef.current  // document.querySelector(".bg-upload-image");
        let file = event.target.files[0]

        function callbackFunc(data) {
            setTimeout(() => {
                setImage({ file: data })
                // setImage({ file: imageUrl })
                fileUpload.style.backgroundImage = `url(${data})`;
            }, 2000);

        }

        getBase64(file, callbackFunc);

        // var toFile = dataURLtoFile(Base64, 'mojtaba.txt');
        // const stringifyImageFile = JSON.stringify(event.target.files[0])
        // console.log({ file, Base64, toFile, event: event.target.value });
        // let imageUrl = objectUrl(event.target.files[0])

    }
    console.log({ image });

    return (
        <>
            <div className={'upload-wrapper'}>
                <div className="upload" ref={uploadRef}>
                    <div ref={imageRef} className={'bg-upload-image'} style={{ backgroundImage: `url(${image?.file})` }}></div>

                    <input type="file" title="" className="drop-here" onChange={uploadFile} />
                    <div className="text text-drop">click or drop here</div>
                    <div className="text text-upload">uploading</div>
                    <svg className="progress-wrapper" width="300" height="300">
                        <circle className="progress" r="115" cx="150" cy="150"></circle>
                    </svg>
                    <svg className="check-wrapper" width="130" height="130">
                        <polyline className="check" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                    </svg>
                    <div className="shadow"></div>
                </div>
            </div>


        </>
    )

}
export default DropZoonUpload;


