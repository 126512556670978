import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
	name: "name",
	code: "code",
	telegramChatId: "telegramChatId",
	isActive: "isActive",
};

export const validation = (URL_ID) =>
	yup.object().shape({
		[fieldNames.code]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.code)).label(fieldNames.code),
		[fieldNames.telegramChatId]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.telegramChatId)).label(fieldNames.telegramChatId),
	});
