import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalLayout({ show, setShow, children, title, classNames, size }) {

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            className={classNames?.modal}
            dialogClassName={classNames?.dialog}
            contentClassName={classNames?.content}
            size={size}
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    );
}

export default ModalLayout;