import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
    delivery: "delivery",
};

export const validation = () =>
    yup.object().shape({
        [fieldNames.delivery]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.delivery)).label(fieldNames.delivery),
    });
