import routes from "operations/routing/routes";
import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import getDataInObjectDepth from "utils/getDataInObject";
import Add from "./Add";
import { ROUTE_NAME, PERMISSION } from "./enum";
import List from "./List";
import { useSelector } from 'react-redux';
import checkComponentRoute from "utils/checkComponentRoute";
import { ADMIN_REDIRECT } from "enumerations/enums";


const Order = () => {
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
  const adminState = useSelector((state) => state.admin);
  const redirect = ADMIN_REDIRECT(adminState?.data)

  // const permission = (adminState.data.operation || adminState.data).permissions;
  // const permissionCurrent = permission[ROUTE_NAME] || {};
  // const fullAccess = permission === true || permissionCurrent;
  // const add = fullAccess === true || permissionCurrent?.["add"];
  // const edit = fullAccess === true || permissionCurrent?.["edit"];


  return (
    <Routes>
      <Route index element={<Navigate to={newRoute.base + newRoute.list} />} />
      <Route path={newRoute.list} element={<List {...{}} />} />
      {/* <Route path={newRoute.add} element={
        add ? <Add /> : <Navigate to={newRoute.base + newRoute.list} />
      } /> */}
      <Route path={`${newRoute.edit}/:id`} element={
        checkComponentRoute(PERMISSION, "edit", adminState, redirect, <Add />)

        // edit ? <Add /> : <Navigate to={newRoute.base + newRoute.list} />
      } />
    </Routes>
  );
};

export default Order;
