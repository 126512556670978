import { useEffect, useState } from "react";
import { Button, Col, Modal, Nav, Row, Tab, Tabs } from "react-bootstrap";
import GalleryTab from "./GalleryTab";
import FolderDetail from "./GalleryTab/FolderDetail";
import UploadTab from "./UploadTab";
import Trendyol_online from "assets/images/finance/Trendyol_online.png";
import multinet from "assets/images/finance/multinet.png";
import upload from "assets/images/finance/upload.png";
import styles from "./index.module.scss"
import api from "operations/network/api";
import axiosClient from 'lib/axiosConfig';
import { useMutationCustom } from "utils/useMutationCustom";
import toastify from "lib/toastify";


const FinanceModal = ({ showModal, handleModal, handleClickedImage, selectedPhoto, isGalleryDisabled, FOLDER_SLUG, isHotel }) => {
  const [activeTab, setActiveTab] = useState(isGalleryDisabled ? "upload" : "gallery");
  const [showCheckTab, setShowCheckTab] = useState(true);
  const [selectKey, setSelectKey] = useState();


  const sendingFile = async (params) => await axiosClient().post(api["invoice"].base, params);
  // const getFolders = async () => await axiosClient().get(api["folder"].list);

  //  ----------------------------------------- MUTATING ----------------------------------------- //
  const onSuccessMutating = () => {
    toastify.success({ title: "success" });
    handleModal();
  };
  const { isLoading, data, mutate } = useMutationCustom({
    url: sendingFile,
    useMutationCustom: `galley_get`,
    onSuccess: onSuccessMutating,
  });
  const handleUploadButton = () => {
    const formData = new FormData();
    formData.append("invoiceType", selectKey);
    mutate(formData);
  };

  useEffect(() => {
    if (isHotel) setShowCheckTab(true)
    else setShowCheckTab(false)
  }, [showModal])

  const dataArray = [
    {
      key: "file",
      image: upload,
      title: "Upload Invoice",
    },
    {
      key: "trendyol",
      image: Trendyol_online,
      title: "Trendyol Gift Voucher",
    },
    {
      key: "multinet",
      image: multinet,
      title: "Multinet Gift Voucher"
    },
  ]

  const handleSelect = () => {
    switch (selectKey) {
      case "file":
        setShowCheckTab(false)
        break;
      case "trendyol":
        handleUploadButton()
        break;
      case "multinet":
        handleUploadButton()
        break;
      default:
        break;
    }
  }

  return (
    <Modal dialogClassName={showCheckTab && styles.dialogClassName} show={showModal} onHide={handleModal} size={showCheckTab ? "md" : "lg"} keyboard scrollable>
      {
        showCheckTab ?
          <div className="p-3">
            <h1 className="text-center mb-4"> Choose Payment Method</h1>
            <Row className={styles.rowBox}>
              {
                dataArray?.map((x) => {
                  return (
                    <Col xs={4}>
                      <div className={[styles.box, selectKey === x.key && styles.active].join(" ")} onClick={() => setSelectKey(x.key)} >

                        <img className={styles.image} src={x.image} />
                        <span>{x.title}</span>
                      </div>
                    </Col>
                  )
                })
              }


            </Row>

            <div className="mt-4 text-center">
              <Button disabled={isLoading} onClick={handleSelect} type="success" >{"Accept"}</Button>
            </div>

          </div>
          :
          <Tab.Container id="modal-tab-container" defaultActiveKey={activeTab}>
            <Modal.Header closeButton>
              <Modal.Title className="w-100">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills">
                      {/* {isGalleryDisabled ? (
                    ""
                  ) : (
                    <Nav.Item>
                      <Nav.Link eventKey="gallery">Gallery</Nav.Link>
                    </Nav.Item>
                  )} */}
                      <Nav.Item>
                        <Nav.Link eventKey="upload">Upload</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tab.Content className="pt-0">
                {/* {isGalleryDisabled ? (
              ""
            ) : (
              <Tab.Pane eventKey="gallery">
                <FolderDetail  {...{ handleClickedImage, selectedPhoto, handleModal }} />
              </Tab.Pane>
            )} */}
                <Tab.Pane eventKey="upload">
                  <UploadTab {...{ handleClickedImage, selectedPhoto, handleModal, FOLDER_SLUG }} />
                </Tab.Pane>
              </Tab.Content>
            </Modal.Body>
          </Tab.Container>
      }

    </Modal>
  );
};

export default FinanceModal;
