import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";
import { changePriceFormat, numberWithCommas } from "utils/calculators";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute, ...props }) =>
  // columns: ["Name", "Network type", "Default", "Actions"],
  // body: data?.map((param) => {
  //     return [
  //         param.name,
  //         param.network_type,
  //         (param.is_default)?.toString(),
  //         () => {
  //             return (
  //                 <div className="d-flex">
  //                     <Link to={routes[ROUTE_NAME].edit + '/' + param.id} className="btn btn-primary shadow btn-xs sharp mr-1"  >
  //                         <i className="fa fa-pencil"></i>
  //                     </Link>
  //                     <span className="btn btn-danger shadow btn-xs sharp pointer" onClick={() => onClick({ param, type: 'delete' })} >
  //                         <i className="fa fa-trash pointer"></i>
  //                     </span>
  //                 </div>
  //             )
  //         }
  //     ]
  // }),
  [
    // {
    //   header: "Image",
    //   Component: ({ param }) => {
    //     return (
    //       <>
    //         <span className="d-block mb-1">
    //           <img src={param?.image?.completedUrl || PLACEHOLDER_IMAGE} className="image-fluid" />
    //         </span>
    //       </>
    //     );
    //   },
    // },

    {
      header: "hotel",
      Component: ({ param }) => {
        const { base, edit } = routes.hotel;
        return param.hotel ? (
          <Link to={`${base + edit}/${param.hotel.id}`} target="_blank">
            {" "}
            {param.hotel?.name}{" "}
          </Link>
        ) : (
          "Unknown"
        );
      },
    },

    {
      header: "invoice",
      Component: ({ param }) => param.hotel?.invoice,
    },
    {
      header: "orderCount",
      Component: ({ param }) => numberWithCommas(param.orderCount),
    },
    {
      header: "totalAmount",
      Component: ({ param }) => numberWithCommas(param.totalAmount),
    },
    {
      header: "totalCommission",
      // Component: ({ param }) => numberWithCommas(param.totalCommission.toFixed(2)),
      Component: ({ param }) => changePriceFormat(param.totalCommission, 2),
    },
    // {
    //   header: "iban",
    //   // Component: ({ param }) => numberWithCommas(param.totalCommission.toFixed(2)),
    //   Component: ({ param }) => param?.hotel?.iban,
    // },
    {
      header: "createdAt",
      Component: ({ param }) => isoToDate(param.createdAt),
    },
    // {
    //   header: "Status",
    //   Component: ({ param }) => <TableStatus {...{ param, onClick }} />,
    // },
    {
      header: "Actions",
      Component: ({ param }) => <TableAction {...{ param, newRoute, onClick, ...props }} />,
    },
  ];
