import FieldText from "components/FieldText";
import ModalLayout from "components/ModalLayout";
import QrCodeComponent from "components/QrCodeComponent";
import { useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import JSZip from "jszip";
import DownloadExcelAllRoomNumbers from "../DownloadExcelAllRoomNumbers/index";

const RoomNumbersQrCode = ({ dataById, qrCodeUrl }) => {
	const qrcodedata = [
		{
			text: qrCodeUrl,
			name: "main",
		},
		{
			text: qrCodeUrl + "?isMagnet=true",
			name: "magnet",
		},
		{
			text: qrCodeUrl + "?isCatalog=true",
			name: "catalog",
		},
	];

	if (dataById?.data?.data?.roomNumbers?.length)
		dataById?.data?.data?.roomNumbers?.map((x) =>
			qrcodedata.push({
				text: qrCodeUrl + `?roomNumber=${x}`,
				roomNumber: x,
				name: `room ${x}`,
			})
		);

	const qrCodeParent = useRef();
	const [toggleQrCode, setToggleQrCode] = useState();
	const [QrCodeOptions, setQrCodeOptions] = useState({
		background: "#000000",
		color: "#ffffff",
		textColor: "#000000",
	});

	const [QrCodeOptionsBase, setQrCodeOptionsBase] = useState({
		background: "#000000",
		color: "#ffffff",
		textColor: "#000000",
	});

	const handleShowQrCodeModal = () => setToggleQrCode((p) => !p);

	const handleSetColor = () => {
		setQrCodeOptionsBase(QrCodeOptions);
	};

	const downloadZip = ({ zipBlob }) => {
		const a = document.createElement("a");
		a.href = URL.createObjectURL(zipBlob);
		a.download = dataById?.data?.data?.name + ".zip";
		a.click();
	};

	const handleDownload = async () => {
		var AllCanvas = qrCodeParent.current.querySelectorAll("canvas");
		console.log("* * * RoomNumbersQrCode * * *", { AllCanvas });
		const qrCodes = [];
		const zip = new JSZip();

		for (let i = 0; i < AllCanvas.length; i++) {
			const canvas = AllCanvas[i];
			let currentData = qrcodedata[i];
			console.log("* * * RoomNumbersQrCode * * *", { i });

			var qrCodeDataURL = await canvas.toDataURL();
			qrCodes.push({ dataURL: qrCodeDataURL, fileName: `${currentData.name}.png` });
			zip.file(`${currentData.name}.png`, qrCodeDataURL.split(",")[1], { base64: true });
		}

		zip.generateAsync({ type: "blob" }).then((zipBlob) => downloadZip({ zipBlob }));
	};

	const handleChange = (e) => {
		setQrCodeOptions((p) => ({ ...p, [e.target.name]: e.target.value }));
	};
	console.log({ qrcodedata });
	return (
		<>
			<ModalLayout title={`Room Numbers (${dataById?.data?.data?.roomNumbers?.length})`} show={toggleQrCode} setShow={setToggleQrCode}>
				<form className="p-3 bg-white">
					<Row>
						<Col>
							<FieldText
								onChange={handleChange}
								value={QrCodeOptions["textColor"]}
								defaultValue={QrCodeOptions["textColor"]}
								// require
								{...{
									name: "textColor",
									placeholder: "Text color",
									label: "Text color",
									type: "color",
								}}
							/>

							<FieldText
								onChange={handleChange}
								value={QrCodeOptions["color"]}
								defaultValue={QrCodeOptions["color"]}
								// require
								{...{
									name: "color",
									placeholder: "Color",
									label: "Color",
									type: "color",
								}}
							/>

							<FieldText
								onChange={handleChange}
								value={QrCodeOptions["background"]}
								defaultValue={QrCodeOptions["background"]}
								// require
								{...{
									name: "background",
									placeholder: "Background",
									label: "Background",
									type: "color",
								}}
							/>

							<div>
								<Button onClick={handleSetColor}>{"Accept"}</Button>

								<Button color="success" variant="success" onClick={handleDownload}>
									{"Download Zip"}
								</Button>
								<DownloadExcelAllRoomNumbers qrCodeUrl={qrCodeUrl} data={dataById?.data?.data} />
							</div>
						</Col>
					</Row>
				</form>

				{dataById?.data?.data?.uuid ? (
					<Row ref={qrCodeParent}>
						{/* <Col sm={6} md={6} lg={6} className="my-3" >
                        <h6> {"Main"}</h6>
                        <QrCodeComponent {...{
                            text: qrCodeUrl,
                            options: {
                                width: 196,
                                height: 196,
                                ...QrCodeOptionsBase
                            }
                        }} />
                    </Col>
                    <Col sm={6} md={6} lg={6} className="my-3" >
                        <h6> {"Magnet"}</h6>
                        <QrCodeComponent {...{
                            text: qrCodeUrl + "&isMagnet=true",
                            options: {
                                width: 196,
                                height: 196,
                                ...QrCodeOptionsBase
                            }
                        }} />
                    </Col>
                    <Col sm={6} md={6} lg={6} className="my-3" >
                        <h6> {"Catalog"}</h6>
                        <QrCodeComponent {...{
                            text: qrCodeUrl + "&isCatalog=true",
                            options: {
                                width: 196,
                                height: 196,
                                ...QrCodeOptionsBase
                            }
                        }} />
                    </Col> */}
						{qrcodedata?.map((x) => {
							return (
								<Col sm={6} md={6} lg={6} className="my-3" key={`qrCode-roomNumbers-${x.name}`}>
									<h6> {x.name}</h6>
									{/* <img className="img-fluid" src={x.path} /> */}
									<QrCodeComponent
										{...{
											text: x.text,
											roomNumber: x.roomNumber,
											options: {
												width: 196,
												height: 196,
												...QrCodeOptionsBase,
											},
										}}
									/>
								</Col>
							);
						})}
					</Row>
				) : (
					""
				)}
			</ModalLayout>
			<div className="mt-5 text-center">
				{
					// dataById?.data?.data?.roomNumbers?.length > 2 ?
					<Button onClick={handleShowQrCodeModal}>{"Show all qrCodes"}</Button>
					// : ""
				}
			</div>
		</>
	);
};
export default RoomNumbersQrCode;
