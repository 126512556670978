import React, { useEffect } from "react";
import "./App.scss";
import AppRoutes from "./AppRoutes";
// import actionRefreshToken from "redux/store/refreshToken/action";
import { useDispatch } from "react-redux";
import routes from "operations/routing/routes";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "containers/Login";
import Verify from "containers/Verify";
import { useSelector } from "react-redux";
import adminAction from "redux/store/admin/action";
import socketAction from "redux/store/socket/action";
import WithErrorHandler from "components/WithErrorHandler";
import Cookies from "universal-cookie";
// import { activate } from "lib/NoSleep";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "moment/locale/tr";

const cookies = new Cookies();

const App = () => {
	const { t, i18n } = useTranslation();
	if (i18n.language) moment.locale(i18n.language);
	// useEffect(() => {
	// 	// const supportedLanguages = ['en', 'fa', 'ar', 'tr', 'fr'];

	// 	// supportedLanguages.forEach(lang => {
	// 	//   moment.updateLocale(lang, null);
	// 	// });
	// 	if (i18n.language) moment.locale(i18n.language);
	// 	// if (i18n.language) moment.updateLocale(i18n.language, null)
	// 	console.log({ language: i18n.language });
	// }, [i18n.language]);

	console.log({ window: window.document.head });

	// const refreshTokenState = useSelector((state) => state.refreshToken);
	const token = useSelector((state) => state.token);
	const adminState = useSelector((state) => state.admin);
	const dispatch = useDispatch();
	const cookieToken = cookies.get("accessToken");
	// useEffect(() => {
	//   dispatch(actionRefreshToken.refreshTokenCheck(null));
	// }, [dispatch]);

	// useEffect(() => {
	//   // Check for service worker and PushManager support
	//   if ('serviceWorker' in navigator && 'PushManager' in window) {
	//     // Register the service worker
	//     navigator.serviceWorker.register('/service-worker.js')
	//       .then(function(registration) {
	//         console.log('Service Worker registered with scope:', registration.scope);
	//       })
	//       .catch(function(error) {
	//         console.error('Service Worker registration failed:', error);
	//       });

	//     // Request permission for push notifications
	//     Notification.requestPermission().then(function(permission) {
	//       if (permission === 'granted') {
	//         console.log('Notification permission granted.');
	//         // You can now subscribe to the push service
	//         subscribeUserToPush();
	//       } else {
	//         console.warn('Notification permission denied.');
	//       }
	//     });
	//   } else {
	//     console.log('Push notifications are not supported in this browser.');
	//   }
	// }, []);

	// const subscribeUserToPush = () => {
	//   if ('serviceWorker' in navigator) {
	//     navigator.serviceWorker.ready.then(function(registration) {
	//       // Get the push subscription
	//       registration.pushManager.subscribe({
	//         userVisibleOnly: true,
	//         applicationServerKey: your_application_server_key // Replace with your server key
	//       }).then(function(subscription) {
	//         console.log('Push subscription:', subscription);
	//         // Send the subscription details to your server
	//       }).catch(function(error) {
	//         console.error('Error subscribing to push service:', error);
	//       });
	//     });
	//   }
	// };

	// async function getMicrophone() {
	//   navigator.permissions.query({ name: '' })
	//     .then(function (permissionStatus) {
	//       console.log(permissionStatus.state);
	//       permissionStatus.onchange = function () {
	//         console.log(permissionStatus.state);
	//       };
	//     })
	//     .catch(function (err) {
	//       console.error(err);
	//     });
	// }

	useEffect(() => {
		// getMicrophone()
		// if (typeof navigator !== 'undefined' && 'permissions' in navigator) {
		//   navigator.permissions.query({ name: 'notifications' }).then(permissionStatus => {
		//     console.log({ permissionStatus });
		//     if (permissionStatus.state !== 'granted') {
		//       // Show a prompt asking the user to allow sound permissions
		//       // This might not directly control sound permissions but encourages the user to take action
		//     }
		//   });
		// }

		if (token.data) {
			dispatch(adminAction.request());
			localStorage.removeItem("state");
		}
	}, []);

	useEffect(() => {
		if (adminState?.data && !adminState?.isFetching) dispatch(socketAction.request());
	}, [adminState]);

	console.log({ adminState, token, cookieToken }, "----> adminState");

	const canWakeLock = () => "wakeLock" in navigator;

	// const handleWackeUp = async () => {
	//   let wakelock;
	//   async function lockWakeState() {
	//     if (!canWakeLock()) return;
	//     try {
	//       wakelock = await navigator.wakeLock.request();
	//       console.log({ wakelock });
	//       wakelock.addEventListener('release', () => {
	//         console.log('Screen Wake State Locked:', !wakelock.released);

	//       });
	//       console.log('Screen Wake State Locked:', !wakelock.released);
	//     } catch (e) {
	//       console.error('Failed to lock wake state with reason:', e.message);
	//     }
	//   }
	//   await lockWakeState()

	//   // function releaseWakeState() {
	//   //   console.log({ wakelock });
	//   //   if (wakelock) wakelock.release();
	//   //   wakelock = null;
	//   // }

	//   // setInterval(releaseWakeState, 1000);

	// }

	// useEffect(() => {
	//   // handleWackeUp()

	//   activate()
	//   // const interval = setInterval(() => console.log("weakup"), 5000);
	//   // return () => clearInterval(interval)
	// }, [])

	return (
		<WithErrorHandler>
			<Routes>
				<Route path={routes.login} element={<Login />} />
				<Route path={routes.verify} element={<Verify />} />
				<Route path={"*"} element={token?.data ? <AppRoutes /> : <Navigate to={"/login"} />} />
			</Routes>
		</WithErrorHandler>
	);
};

export default App;
