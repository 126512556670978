function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? (h < 10 ? "0" + h : h) + ":" : "";
    var mDisplay = m > 0 ? (m < 10 ? "0" + m : m) + ":" : "00:";
    var sDisplay = s > 0 ? (s < 10 ? "0" + s : s) : "00";
    return hDisplay + mDisplay + sDisplay;
}

export default secondsToHms