import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  "title": "title",
  "solution": "solution",
  "text": "text",
  "priority": "priority",
  "file": "file",
  "dueDate": "dueDate"
};

export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.title]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.title)).label(fieldNames.title),
    [fieldNames.solution]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.solution)).label(fieldNames.solution),
    [fieldNames.text]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.text)).label(fieldNames.text),
    [fieldNames.priority]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.priority)).label(fieldNames.priority),
    // [fieldNames.file]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.file)).label(fieldNames.file),
  })

