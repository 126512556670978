export const API_NAME = "finance";
export const ROUTE_NAME = "finance";
export const PERMISSION = "finance";
export const SUPPORT_PERMISSION = ["financeHotels"];
export const COMPONENT_NAMES = ["finance", "finances"];
export const FINANCE_DATE = ["2023", "2024"];
export const FINANCE_DATE_OPTIONS = [
	{ label: "2023", value: "2023" },
	{ label: "2024", value: "2024" },
];
