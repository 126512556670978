import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { Children, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PERMISSION, SUPPORT_PERMISSION, FINANCE_DATE, FINANCE_DATE_OPTIONS } from "../enum";
import { tableData } from "./tableInfo";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import RemoveNullObjectValue from "utils/RemoveNullObjectValue";
import FieldDate from "components/FieldDate";
import FinanceDatingComponent from "../FinanceDatingComponent";

const List = () => {
	const [checkList, setCheckList] = useState();
	const { t } = useTranslation();
	const adminState = useSelector((state) => state.admin);
	const validPermission = adminState?.data?.permissions === true;
	let permissions = validPermission || adminState?.data?.permissions?.[PERMISSION];
	if (!permissions) permissions = adminState?.data?.permissions?.[SUPPORT_PERMISSION?.find((x) => adminState?.data?.permissions?.[x])];
	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	// ---------------------------------------------- Constance
	const button = { title: t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) }), link: newRoute.base + newRoute.add };

	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	let { years } = useParams();

	let defaultYears = FINANCE_DATE[1];

	years = years || defaultYears;

	let d = moment();
	if (years) d.year(years);
	const minMonthDate = new Date(d.startOf("month"));
	const maxMonthDate = new Date(d.endOf("month"));
	const QueryString = useQueryString({ sort: "desc", from: minMonthDate, to: maxMonthDate });
	// console.log({ minMonthDate, maxMonthDate });

	const { page } = QueryString;
	// ---------------------------------------------- fetching functions
	const getting = async () =>
		await axiosClient().get(api[API_NAME].list, {
			params: { ...QueryString },
		});
	const changeStatus = async (params) => {
		console.log(params, "changeStatus");
		await axiosClient().patch(api[API_NAME].changeStatus, params);
	};
	const deleting = async (params) => {
		await axiosClient().delete(api[API_NAME].delete + "/" + params?.id);
	};
	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get`,
		url: getting,
		params: { ...QueryString },
		enabled: !!(validPermission || permissions?.list),
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessDeleteMutation = () => {
		toastify.success({ title: "success" });
	};

	const deleteMutation = useMutationCustom({
		name: `${API_NAME}_delete`,
		url: deleting,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessDeleteMutation,
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessChangeStatusMutation = () => {
		toastify.success({ title: "success" });
	};

	const changeStatusMutation = useMutationCustom({
		name: `${API_NAME}_changeStatus`,
		url: changeStatus,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessChangeStatusMutation,
	});
	// ---------------------------------------------- logs
	console.log({ data, error, isError, isLoading });
	// ---------------------------------------------- action click table

	const actionsOperation = ({ data, status }) => {
		swal({
			title: "Are you sure?",
			text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				if (status.label === "delete") deleteMutation.mutate({ id: data });
				else changeStatusMutation.mutate({ ids: data, isActive: status.value });
			}
		});
	};

	const clickAction = ({ data, status }) => {
		const newData = data.map((x) => x.id);
		actionsOperation({ data: newData, status });
	};

	const handleAction = (status) => {
		const data = Object.keys(checkList);
		console.log({ data, status });
		actionsOperation({ data, status: status });
	};
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [{ title: "Dashboard", link: routes.dashboard.base }, { title: COMPONENT_NAMES[0], link: newRoute.base }, { title: "List" }];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);

	const tableOptions = tableData({
		onClick: clickAction,
		newRoute,
		pathId: "hotel.id",
		endPath: () => years,
		active: { show: (permissions?.list || validPermission) && true },
		disable: { edit: true, delete: true },
	});

	const exportToCSV = ({ apiData, fileName, head, cols }) => {
		const workbook = XLSX.utils.book_new();
		// const myHeader = ["id","name","location"];
		const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e["c"] = head.length - 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);
		worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		worksheet["!cols"] = cols;

		XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
		XLSX.writeFile(workbook, "excel_export.xlsx");

		// const ws = XLSX.utils.json_to_sheet(apiData);
		// /* custom headers */
		// XLSX.utils.sheet_add_aoa(ws, [head], { origin: "A1", });
		// const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		// const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		// const data = new Blob([excelBuffer], { type: fileType });
		// FileSaver.saveAs(data, fileName + fileExtension);
	};

	const handleExcel = () => {
		let cols = [
			{ wch: 30 },
			{ wch: 15 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
		];

		const head = [
			t("name"),
			t("region"),
			t("roomCount"),
			t("commercialTitle"),
			t("invoice"),
			t("commission"),
			t("orderCount"),
			t("totalAmount"),
			t("totalCommission"),
			t("date"),
			t("clock"),
			// t("iban"),
			// t("ibanName"),
		];
		const apiData = data?.result?.map((y) => {
			const date = moment(y?.createdAt);
			return {
				[t("name")]: y?.hotel?.name,
				[t("region")]: y?.region?.name || "-",
				[t("roomCount")]: y?.hotel?.roomCount || "-",
				[t("commercialTitle")]: y?.hotel?.commercialTitle,
				[t("invoice")]: y?.hotel?.invoice,
				[t("commission")]: y?.hotel?.commission,
				[t("orderCount")]: y?.orderCount,
				[t("totalAmount")]: y?.totalAmount,
				[t("totalCommission")]: y?.totalCommission,
				[t("date")]: date.format("YYYY-MM-DD"),
				[t("clock")]: date.format("HH:mm:ss"),
				// [t("iban")]: y?.hotel.iban,
				// [t("ibanName")]: y?.hotel?.ibanName,
			};
		});
		exportToCSV({ apiData, fileName: "exc", head, cols });
	};
	//  ----------------------------------------- LOGS ----------------------------------------- //
	console.log({ checkList, data }, "checkList");

	// const DatingComponent = () => {
	// 	return (
	// 		<div className="d-flex gap-2">
	// 			<div>
	// 				<label className="mb-1">{t("years")}</label>
	// 				<Dropdown variant="primary" className=" ">
	// 					<Dropdown.Toggle id="dropdown-basic" className=" btn outline filter-sort mr-3">
	// 						<span>{years}</span>
	// 					</Dropdown.Toggle>

	// 					<Dropdown.Menu className="dropDown-menu-custom">
	// 						<Dropdown.Header className="text-capitalize" header>
	// 							{t("years")}
	// 						</Dropdown.Header>
	// 						{Children.toArray(
	// 							FINANCE_DATE_OPTIONS?.map((x) => (
	// 								<Dropdown.Item
	// 									key={x.label + "_x"}
	// 									active={x.value === years}
	// 									onClick={() =>
	// 										navigate({
	// 											pathname: routes.financeHotels.base + routes.financeHotels.list + "/" + x.value,
	// 										})
	// 									}
	// 									className="dropDown-item-custom text-capitalize">
	// 									{t(x?.label)}
	// 								</Dropdown.Item>
	// 							))
	// 						)}
	// 					</Dropdown.Menu>
	// 				</Dropdown>
	// 			</div>

	// 			<div className="mb-2">
	// 				<FieldDate
	// 					// isClearable
	// 					{...{
	// 						label: "to",
	// 						onChange: (e) => {
	// 							try {
	// 								if (!e) return navigateUrl({ to: undefined, page: 1 });

	// 								let date = moment(e);
	// 								//  date.setHours(03)
	// 								navigateUrl({ to: new Date(date).toISOString(), page: 1 });
	// 							} catch (error) {
	// 								console.log({ error });
	// 								navigateUrl({ to: undefined, page: 1 });
	// 							}
	// 						},
	// 						selected: QueryString.to && new Date(QueryString.to),
	// 						startDate: QueryString.from && QueryString.from,
	// 						placeholderText: t("selectDate"),
	// 						minDate: QueryString.from && new Date(QueryString.from),
	// 						maxDate: maxDate || new Date(),
	// 						showMonthYearPicker: true,
	// 						dateFormat: "MMMM d, yyyy",
	// 						// dayClassName: date => date.getTime() < (((new Date(QueryString.from)).getTime())) ? 'disabled-date' : undefined
	// 					}}
	// 				/>
	// 			</div>
	// 		</div>
	// 	);
	// };

	return (
		<div>
			{/* <div className="text-center">
				<ButtonGroup
					style={{
						width: "50%",
					}}
					aria-label="Basic example">
					{
						// handleChangeDate
						FINANCE_DATE?.map((x) => (
							<Button
								style={{
									flex: 1,
								}}
								a
								onClick={() => {
									navigate({
										pathname: routes.finance.base + routes.finance.list + "/" + x,
									});
								}}
								variant={years == x ? "primary" : "secondary"}>
								{x}
							</Button>
						))
					}
				</ButtonGroup>
			</div> */}

			<PageTitle
				title={t("listsAuthor", { author: t(COMPONENT_NAMES[0]) }) + ` (${years})`}
				breadcrumbs={breadcrumbs}
				// button={button}
			/>
			<TableComponent
				{...{
					QueryString,
					data: data?.result,
					tableOptions,
					loading: isLoading,
					search: {
						placeholder: t("search"),
						defaultValue: QueryString.search_text,
					},
					title: `${COMPONENT_NAMES[1]}`,
					button: button,
					count: data.total,
					pagination: {
						activePage: page,
						// pages: Math.ceil(data.total / limit),
						// limited: 2,
					},
					dating: {
						component: <FinanceDatingComponent {...{ QueryString, baseUrl: routes.finance.base + routes.finance.list }} />,
						// minDate,
						// maxDate,
						// props: {
						// 	showMonthYearPicker: true,
						// 	dateFormat: "MMMM d, yyyy",
						// },
						// defaultValue: new Date(date + '-01-01'),
					},
					excelButton: {
						show: permissions?.allOrders || validPermission,
						disabled: !data?.result,
						onClick: handleExcel,
					},
				}}
			/>
		</div>
	);
};

export default List;
