import QrCodeComponent from "components/QrCodeComponent";
import configs from "configs";
import { useEffect, useRef } from "react";
import generateQrCode from "utils/generateQrCode";


const ShowQrCode = ({ param }) => {
    const qrCodeUrl = `${configs.data.WEBSITE}?ouid=${param?.uuid}`

    // const qrCodeRef = useRef()
    // useEffect(() => {
    //     generateQrCode(qrCodeUrl, qrCodeRef.current)
    // }, [])


    return (
        <div className="card">
            {/* <img src={param?.qrCode?.path} /> */}
            {/* <div ref={qrCodeRef}></div> */}
            <QrCodeComponent {...{ text: qrCodeUrl }} />

            <a
                target="_blank"
                href={qrCodeUrl}
            >
                {/* room-services.net/sayfa/urunler?ouid={param?.qrCode?.uuid} */}
                {qrCodeUrl}
            </a>
        </div>
    )
}

export default ShowQrCode;