import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute, ...props }) =>
	// columns: ["Name", "Network type", "Default", "Actions"],
	// body: data?.map((param) => {
	//     return [
	//         param.name,
	//         param.network_type,
	//         (param.is_default)?.toString(),
	//         () => {
	//             return (
	//                 <div className="d-flex">
	//                     <Link to={routes[ROUTE_NAME].edit + '/' + param.id} className="btn btn-primary shadow btn-xs sharp mr-1"  >
	//                         <i className="fa fa-pencil"></i>
	//                     </Link>
	//                     <span className="btn btn-danger shadow btn-xs sharp pointer" onClick={() => onClick({ param, type: 'delete' })} >
	//                         <i className="fa fa-trash pointer"></i>
	//                     </span>
	//                 </div>
	//             )
	//         }
	//     ]
	// }),
	[
		{
			header: "Image",
			Component: ({ param }) => {
				return (
					<>
						<span className="d-block mb-1">
							<img src={param?.image} className="image-fluid" />
						</span>
					</>
				);
			},
		},
		{
			header: "Name",
			Component: ({ param }) => param.name || "-",
		},
		// {
		//   header: "listOrderNumber",
		//   Component: ({ param }) => param.listOrderNumber || "-",
		// },
		{
			header: "parent",
			Component: ({ param }) => {
				const { base, edit } = routes.category;
				return param.parent ? (
					<Link to={`${base + edit}/${param.parent.id}`} target="_blank">
						{" "}
						{param.parent?.name}{" "}
					</Link>
				) : (
					"-"
				);
			},
		},
		{
			header: "categoryType",
			Component: ({ param }) => param.categoryType,
		},
		{
			header: "redirect",
			Component: ({ param }) => param?.redirect?.name,
		},

		{
			header: "isClickDisabled",
			Component: ({ param }) => {
				return <CheckedComponent checked={param.isClickDisabled} />;
			},
		},
		{
			header: "isNewCategory",
			Component: ({ param }) => {
				return (
					// <span className={`${param.type === "ADMIN" ? "text-primary" : param.type === "USER" ? "text-success" : "text-secondary"} text-capitalize`}>{param?.type?.toLowerCase() || "-"}</span>
					<CheckedComponent checked={param.isNewCategory} />
				);
			},
		},
		{
			header: "Status",
			Component: ({ param }) => <TableStatus {...{ param, onClick, disabled: props?.disabled?.edit }} />,
		},
		{
			header: "createdAt",
			Component: ({ param }) => isoToDate(param.createdAt),
		},
		{
			header: "Actions",
			Component: ({ param }) => <TableAction {...{ param, newRoute, onClick, ...props }} />,
		},
	];
