import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute, ...props }) => [
	{
		header: "Status",
		Component: ({ param }) => <TableStatus {...{ param, onClick, disabled: props?.disabled?.edit }} />,
	},
	{
		header: "Actions",
		Component: ({ param }) => <TableAction {...{ param, newRoute, onClick, ...props }} />,
	},
	{
		header: "id",
		Component: ({ param }) => param.id,
	},
	{
		header: "stock",
		Component: ({ param }) => param.stock,
	},
	{
		header: "Image",
		Component: ({ param }) => {
			return (
				<>
					<span className="d-block mb-1">
						<img src={param?.images || PLACEHOLDER_IMAGE} className="image-fluid" />
					</span>
				</>
			);
		},
	},
	{
		header: "integrationCode",
		Component: ({ param }) => (param.integrationCode?.code ? `${param.integrationCode.code}-(${param.integrationCode.telegramChatId})` : "-"),
	},
	{
		header: "branchProductName",
		Component: ({ param }) => param.branchProductName || "-",
	},
	{
		header: "name",
		Component: ({ param }) => param.name,
	},
	{
		header: "branch",
		Component: ({ param }) => param?.branch?.name || param?.branch,
	},
	{
		header: "price",
		Component: ({ param }) => param.price,
	},
	{
		header: "shortDesc",
		Component: ({ param }) => param.shortDesc,
	},
	{
		header: "isCourierDisabled",
		Component: ({ param }) => <CheckedComponent checked={param.isCourierDisabled} />,
	},

	{
		header: "translations",
		Component: ({ param }) => (
			<div>
				{param?.translations?.map((x) => {
					return (
						<div>
							{x?.name + " (" + x?.language + ")"}
							<br />
						</div>
					);
				})}
			</div>
		),
	},
	{
		header: "translations",
		Component: ({ param }) => (
			<div>
				{param?.translations?.map((x) => {
					return (
						<div>
							{x?.shortDesc + " (" + x?.language + ")"}
							<br />
						</div>
					);
				})}
			</div>
		),
	},
	{
		header: "category",
		Component: ({ param }) => {
			const { base, edit } = routes.category;
			return param.category ? (
				<Link to={`${base + edit}/${param.category.id}`} target="_blank">
					{" "}
					{param.category?.name}{" "}
				</Link>
			) : (
				"Unknown"
			);
		},
	},
	{
		header: "isRecommended",
		Component: ({ param }) => {
			return <CheckedComponent checked={param.isRecommended} />;
		},
	},
	{
		header: "listOrderNumber",
		Component: ({ param }) => `${param.listOrderNumber || "Unknown"}`,
	},

	{
		header: "createdAt",
		Component: ({ param }) => isoToDate(param.createdAt),
	},
];
