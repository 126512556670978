import UploadBox from "./UploadTab";

const UpdatePriceComponent = ({ onClose }) => {
	return (
		<div className={["bg-white py-3 px-4 rounded-3 mb-3"].join(" ")}>
			<UploadBox onClose={onClose} />
		</div>
	);
};

export default UpdatePriceComponent;
