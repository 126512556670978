
import actionTypes from "../actionTypes";
import initialState from "./initialState"
import { failure, initial, receive, request } from "./operation"



const orderReducer = (state = { ...initialState }, action) => {
  // console.log({ state, action }, "send code orderReducer");
  switch (action.type) {

    case actionTypes.REQUEST:
      return request(state, action);

    case actionTypes.RECEIVE:
      return receive(state, action);

    case actionTypes.FAILURE:
      return failure(state, action);

    case actionTypes.INITIAL:
      return initial(state, action);

    default:
      return state

  }
}

export default orderReducer;