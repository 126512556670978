import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
  name: "name",
  telegramChatId: "telegramChatId",
  integrationCode: "integrationCode",
  integrationCodes: "integrationCodes",
};

// const translations = yup
//   .object({
//     [fieldNames.translate_name]: yup.string().required(ERRORS.pleaseEnterYour("name")).label("name"),
//     [fieldNames.translate_shortDesc]: yup.string().required(ERRORS.pleaseEnterYour("shortDesc")).label("shortDesc"),
//     [fieldNames.translate_desc]: yup.string(),
//     [fieldNames.translate_language]: yup.object().required(ERRORS.pleaseEnterYour("language")).label("language"),
//   })
//   .defined();

export const validation = (URL_ID) =>
  yup.object().shape({
    [fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
    [fieldNames.integrationCodes]: yup.array().required(ERRORS.pleaseEnterYour(fieldNames.integrationCodes)).label(fieldNames.integrationCodes),
    [fieldNames.telegramChatId]: yup.string().default(0).required(ERRORS.pleaseEnterYour(fieldNames.telegramChatId)).label(fieldNames.telegramChatId),
  });
