import { useRef } from "react";

const useDebounce = (props) => {
	// Debounced function
	const timeoutId = useRef();

	const debounce = (value, setDebounce) => {
		clearTimeout(timeoutId.current);
		timeoutId.current = setTimeout(() => {
			const method = props?.setDebounce || setDebounce;
			method?.(value);
		}, 500);
	};

	return {
		debounce,
	};
};
export default useDebounce;
