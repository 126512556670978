import "./index.scss"
import PrintPage from ".";
import PrintComponent from "utils/PrintComponent";
import { printData } from "./enum";
import { useRef } from "react";

const PrintExample = () => {

    // const reference = useRef()
    function printContent() {
        PrintComponent(<PrintPage data={printData} />)
    }



    return (
        <div id="content">
            <button onClick={printContent}>print</button>
            {/* <div ref={reference}>
                <PrintPage data={printData} />
            </div> */}
        </div>
    )
}

export default PrintExample;