import { Dropdown, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const DeliveryTooltip = ({ param,vigoDelivery,
	delivery, children, disabled, isHotel, isAdmin }) => {
	const TooltipComponent = ({ data }) => {
		const { t } = useTranslation();
		return (
			<div className="bg-white p-2 mr-2 border">
				{vigoDelivery?<ul>
					<li>
						<small> {t("name")}</small> :<span>{vigoDelivery?.assignedCourier?.fullname ||"-"} </span>
					</li>

					<li>
						<small> {t("delivery")}</small> :<span>{vigoDelivery?.deliveryState||"-" } </span>
					</li>

					{isAdmin ? (
						<li>
							<small> {t("phoneNumber")}</small> :<span>{vigoDelivery?.assignedCourier?.cellPhone||"-"} </span>
						</li>
					) : (
						""
					)}
				</ul>:
				<ul>
				<li>
					<small> {t("name")}</small> :<span>{ delivery?.telegramName||"-"} </span>
				</li>

				<li>
					<small> {t("userName")}</small> :<span>{delivery?.telegramUsername||"-"} </span>
				</li>

				{isAdmin ? (
					<li>
						<small> {t("telegramId")}</small> :<span>{delivery?.telegramId||"-"} </span>
					</li>
				) : (
					""
				)}
			</ul>
				}
			</div>
		);
	};

	return disabled ? (
		children || <></>
	) : (
		<OverlayTrigger trigger={["hover", "click"]} delay={{ show: 100, hide: 400 }} placement="left" overlay={TooltipComponent({ data: param })}>
			{children || <></>}
		</OverlayTrigger>
	);
};

export default DeliveryTooltip;
