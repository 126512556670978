export const API_NAME = "hotel";
export const ROUTE_NAME = "hotel";
export const PERMISSION = "hotels";
export const COMPONENT_NAMES = ["hotel", "Hotels"];

export const PAYMENT_METHOD_OBJECT = {
	cash: { label: "cash", value: "cash" },
	giftCard: { label: "giftCard", value: "giftCard" },
	invoice: { label: "invoice", value: "invoice" },
};
export const PAYMENT_METHOD_ARRAY = Object.values(PAYMENT_METHOD_OBJECT);
// ["hotel", "Hotels"];

export const initial = {
	address: "0x704786aae42C84298CDC822a8a3e49c9828D391C",
	alcohol: true,
	branch: "6422f65bb7662d549d44f2f3",
	coldDrink: true,
	commercialTitle: "commercial title",
	commission: "9",
	defaultLanguage: "en",
	exceptCategories: undefined,
	exceptProducts: undefined,
	iban: "test",
	ibanName: "qwe111",
	invoice: "formal",
	isActive: true,
	isCardActive: false,
	isCashActive: false,
	isFastDelivery: false,
	isNewProduct: false,
	isOnlineActive: false,
	isOrderLimit: true,
	name: "mojtaba",
	orderLimit: "1",
	payment: { isOnlineActive: true, isCardActive: true, isCashActive: true },
	phoneNumber: "+90 507 983 73 69",
	roomCount: "1",
	serviceCharge: true,
	taxAdministration: "taxAdministration",
	taxNo: "taxNo",
	// users: [
	//     {
	//         email: "moradi.mojtaba2674@gmail.com",
	//         isActive: false,
	//         // isEmailNotificationActive: true,
	//         // isPhoneNotificationActive: true,
	//         lastName: "bbbbbb",
	//         name: "aaaaaaa",
	//         password: "1234",
	//         phoneNumber: "0944524524",
	//     }
	// ]
};
