import { yupResolver } from '@hookform/resolvers/yup';
import BranchSelector, { convertBranchData } from 'containers/Branch/BranchSelector';
import { ERRORS } from 'enumerations';
import toastify from 'lib/toastify';
import api from 'operations/network/api';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import axiosClient from 'lib/axiosConfig';
import { useMutationCustom } from 'utils/useMutationCustom';
import { useEffect } from 'react';

function BranchModal({ show, setShow, params, refetchKey }) {

    params = show
    console.log({ params });
    const hotel = params?.hotel || params
    const branch = params?.branch
    const updating = async (x) => await axiosClient().patch(api.order.base + "/" + params?.id + "/update-branch", x);


    const onSuccessMutating = () => {
        toastify.success({ title: "success" });
        setShow(false)
    };
    const { isLoading, data, mutate } = useMutationCustom({
        url: updating,
        invalidQuery: refetchKey,
        onSuccess: onSuccessMutating,
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        control,
        reset,
    } = useForm({
        resolver: yupResolver(yup.object().shape({
            branch: yup
                .object()
                .required(ERRORS.pleaseEnterYour("branch"))
                .label("branch"),
        })),
        mode: "onSubmit",
    });

    useEffect(() => {
        if (!params) return
        reset({ branch: convertBranchData(branch) })
    }, [params])

    const onSubmit = () => {
        const values = getValues()
        console.log({ values });
        mutate({
            branch: values.branch?.value
        })
    }
    console.log({ errors });

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >

            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {` Change ${params?.name} Branch`}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
                    <BranchSelector
                        {...{
                            name: "branch",
                            register,
                            label: "branch",
                            errors,
                            control,
                            disabled: isLoading
                        }}
                    />
                    <Button disabled={isLoading} type='submit'>
                        {isLoading ? "loading..." : "Submit"}
                    </Button>
                </form>
            </Modal.Body>

        </Modal>
    );
}

export default BranchModal;