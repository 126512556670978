import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { USER_TYPES_OBJECT } from "enumerations";
import isoToDate from "utils/isoToDate";

export const tableData = ({ onClick, newRoute }) => [
  {
    header: "userType",
    Component: ({ param }) => `${USER_TYPES_OBJECT[param.userType]?.label}` || "Unknown",
  },

  {
    header: "createdAt",
    Component: ({ param }) => isoToDate(param.createdAt),
  },
  {
    header: "Actions",
    Component: ({ param }) => <TableAction {...{ param, newRoute, onClick }} />,
  },
];
