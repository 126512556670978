import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PERMISSION } from "../enum";
import { tableData } from "./tableInfo";
import { useTranslation } from "react-i18next";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ModalLayout from "components/ModalLayout";
// import AddItemToMenus from "containers/Menus/AddItemToMenus";
import styles from "./index.module.scss";
import CategorySelector, {
  convertCategoryData,
} from "containers/Category/CategorySelector";
import MenuSelector, { convertMenuData } from "containers/Menus/MenuSelector";
import TableFiltersSelector from "components/TableFiltersSelector";
import { useLocation, useNavigate } from "react-router-dom";
import RemoveNullObjectValue from "utils/RemoveNullObjectValue";
import FieldText from "components/FieldText";
import useDebounce from "hook/useDebounce";
import IntegrationSelector, {
  convertIntegrationData,
} from "../../IntegrationCode/Selector";
import * as integrationCodeEnum from "../../IntegrationCode/enum";
import * as categoryEnum from "../../Category/enum";
import UpdatePriceComponent from "../UpdatePrice";

const List = () => {
  const { t } = useTranslation();
  const [ShowUpdatePrice, setShowUpdatePrice] = useState();

  const [checkList, setCheckList] = useState();
  const [menuModalState, setMenuModalState] = useState();
  const updatePriceToggle = () => setShowUpdatePrice((p) => !p);

  // ---------------------------------------------- new route
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
  // ---------------------------------------------- Constance

  const QueryString = useQueryString({ sort: "desc" });
  const {
    catName,
    catImage,
    menuName,
    integrationCodeName,
    integrationCodeTelegramChatId,
    ...QueryStringApi
  } = QueryString;
  const { page, limit } = QueryString;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const adminState = useSelector((state) => state.admin);
  const validPermission = adminState?.data?.permissions === true;
  const permissions =
    validPermission || adminState?.data?.permissions?.[PERMISSION];
  const isAdmin = adminState.data?.userType?.includes("admin");
  const integrationCodePermissions =
    adminState?.data?.permissions?.[integrationCodeEnum.PERMISSION];
  const categoryPermissions =
    adminState?.data?.permissions?.[categoryEnum.PERMISSION];

  const button = {
    title: t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) }),
    link: newRoute.base + newRoute.add,
  };
  const buttonTwo = {
    title: t(`updatePrice`),
    onClick: updatePriceToggle,
    type: "warning",
  };
  const buttons = [];
  if (permissions?.create || validPermission) buttons.push(buttonTwo, button);

  // ---------------------------------------------- fetching functions
  const getting = async () =>
    await axiosClient().get(api[API_NAME].list, {
      params: { ...QueryStringApi },
    });
  const changeStatus = async (params) => {
    console.log(params, "changeStatus");
    await axiosClient().patch(api[API_NAME].changeStatus, params);
  };
  const deleting = async (params) => {
    await axiosClient().delete(api[API_NAME].delete + "/" + params?.id);
  };
  // ------------------------------------------------------- query Get
  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
  } = useQueryCustom({
    name: `${API_NAME}_get`,
    url: getting,
    params: { ...QueryStringApi },
    enabled: !!(validPermission || permissions?.list),
  });
  // ---------------------------------------------- mutation Change Status
  const onSuccessDeleteMutation = () => {
    toastify.success({ title: "success" });
  };

  const deleteMutation = useMutationCustom({
    name: `${API_NAME}_delete`,
    url: deleting,
    invalidQuery: `${API_NAME}_get`,
    onSuccess: onSuccessDeleteMutation,
  });
  // ---------------------------------------------- mutation Change Status
  const onSuccessChangeStatusMutation = () => {
    toastify.success({ title: "success" });
  };

  const changeStatusMutation = useMutationCustom({
    name: `${API_NAME}_changeStatus`,
    url: changeStatus,
    invalidQuery: `${API_NAME}_get`,
    onSuccess: onSuccessChangeStatusMutation,
  });
  // ---------------------------------------------- logs
  console.log({ data, error, isError, isLoading });
  // ---------------------------------------------- action click table

  const actionsOperation = ({ data, status }) => {
    swal({
      title: "Are you sure?",
      text: `You want to ${status?.label?.toLowerCase()} this ${
        COMPONENT_NAMES[0]
      }`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willBe) => {
      if (willBe) {
        if (status.label === "delete") deleteMutation.mutate({ id: data });
        else changeStatusMutation.mutate({ ids: data, isActive: status.value });
      }
    });
  };

  const clickAction = ({ data, status }) => {
    const newData = data.map((x) => x.id);
    actionsOperation({ data: newData, status });
  };

  const handleAction = (status) => {
    const data = Object.keys(checkList);
    console.log({ data, status });
    actionsOperation({ data, status: status });
  };

  // ---------------------------------------------- breadcrumbs

  const handleShowAddItemModal = (x) => setMenuModalState(x);
  // ---------------------------------------------- breadcrumbs
  const breadcrumbs = [
    { title: "Dashboard", link: routes.dashboard.base },
    { title: COMPONENT_NAMES[0], link: newRoute.base },
    { title: "List" },
  ];
  // ---------------------------------------------- EFFECTS
  useEffect(() => {
    dispatch(actionBreadcrumbs.set(breadcrumbs));
  }, []);

  const tableOptions = tableData({
    onClick: clickAction,
    newRoute,
    active: {
      manuel: [
        {
          icon: "mdi mdi-content-copy",
          route: "add",
        },
        {
          icon: "mdi mdi-food",
          route: routes.menus.base + routes.menus.addItemsToMenus,
          class: "btn-success",
          // onClick: handleShowAddItemModal
          manual: true,
        },
      ],
    },
    disable: {
      edit: !(validPermission || permissions?.edit),
      delete: !(validPermission || permissions?.delete),
    },
  });

  //  ----------------------------------------- LOGS ----------------------------------------- //
  console.log({ checkList, data, isAdmin }, "checkList");
  const gettingStatistics = async () => {
    return await axiosClient().get(api[API_NAME].list, {
      params: { limit: 0 },
    });
  };

  const exportToCSV = ({ apiData, fileName, head, cols }) => {
    const workbook = XLSX.utils.book_new();
    // const myHeader = ["id","name","location"];
    const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    range.e["c"] = head.length - 1;
    worksheet["!ref"] = XLSX.utils.encode_range(range);
    worksheet["!autofilter"] = {
      ref: `A1:${String.fromCharCode(65 + head.length - 1)}1`,
    };
    worksheet["!cols"] = cols;

    XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
    XLSX.writeFile(workbook, "excel_export.xlsx");
  };

  const handleGetProducts = () => {
    let cols = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    gettingStatistics()
      .then((x) => {
        console.log("x ===>", { x });

        const head = [
          "id",
          "urun_adi",
          "bayi",
          // "urun_adi_en",
          // "urun_adi_ar",
          // "urun_adi_fr",
          // "urun_adi_fa",
          // "urun_adi_es",
          // "urun_adi_de",
          // "urun_adi_ru",
          "fiyat",
          "alis_fiyat",
          "acıklama",
          // "acıklama_en",
          // "acıklama_ar",
          // "acıklama_fr",
          // "acıklama_fa",
          // "acıklama_es",
          // "acıklama_de",
          // "acıklama_ru",
          "kategori",
          "entegrasyon_kodu",
          // "ceviri_adi",
          // "çeviri_acıklama",
          "duzenlenme_tarihi",
        ];
        const data = x?.data?.result?.map((y) => {
          const lang = y?.translations?.reduce((acc, curr) => {
            acc[curr.language] = curr;
            return acc;
          }, {});

          return {
            id: y?.id,
            urun_adi: y?.name,
            bayi: y?.branch?.name || y?.branch,
            // urun_adi_en: lang["en"]?.name,
            // urun_adi_ar: lang["ar"]?.name,
            // urun_adi_fr: lang["fr"]?.name,
            // urun_adi_fa: lang["fa"]?.name,
            // urun_adi_es: lang["es"]?.name,
            // urun_adi_de: lang["de"]?.name,
            // urun_adi_ru: lang["ru"]?.name,
            fiyat: y?.price,
            alis_fiyat: y?.buyPrice,
            acıklama: y?.shortDesc, // y?.shortDesc,
            // acıklama_en: lang["en"]?.shortDesc,
            // acıklama_ar: lang["ar"]?.shortDesc,
            // acıklama_fr: lang["fr"]?.shortDesc,
            // acıklama_fa: lang["fa"]?.shortDesc,
            // acıklama_es: lang["es"]?.shortDesc,
            // acıklama_de: lang["de"]?.shortDesc,
            // acıklama_ru: lang["ru"]?.shortDesc,

            kategori: y?.category?.name,
            entegrasyon_kodu: y?.integrationCode?.code,
            duzenlenme_tarihi: y?.updatedAt,

            // ceviri_adi: y?.translations.map((z) => String(z.name))?.join("   ,   "),
          };
        });
        console.log({ data });
        exportToCSV({ apiData: data, fileName: "exc", head, cols });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const changeUrl = (params) => ({
    pathname: location.pathname,
    search: new URLSearchParams(
      RemoveNullObjectValue({ ...QueryString, ...params })
    ).toString(),
  });
  console.log({
    QueryString: useQueryString(),
    integrationCodeName,
    aaa: convertIntegrationData({
      code: integrationCodeName,
      id: QueryString?.integrationCode,
      telegramChatId: integrationCodeTelegramChatId,
    }),
  });
  const { debounce } = useDebounce();
  // {setDebounce: (x) => changeUrl({integrationCode: x})}

  const selector = [
    // <MenuSelector isClearable
    //   value={QueryString?.menu && convertMenuData({
    //     name: menuName,
    //     id: QueryString?.menu,
    //   })}
    //   {...{
    //     label: "menu", setState: (params) => {
    //       console.log({ params });
    //       if (params) navigate(changeUrl({
    //         menuName: params?.name,
    //         menu: params?.value,
    //       }))
    //       else navigate(changeUrl({
    //         menuName: null,
    //         menu: null,
    //       }))
    //     }
    //   }} />,
  ];

  if (categoryPermissions?.list || validPermission)
    selector.push(
      <CategorySelector
        value={
          QueryString?.category &&
          convertCategoryData({
            name: catName,
            image: catImage,
            id: QueryString?.category,
          })
        }
        isClearable
        {...{
          label: "category",
          setState: (params) => {
            console.log({ params });
            if (params)
              navigate(
                changeUrl({
                  catName: params?.name,
                  catImage: params?.image,
                  category: params?.value,
                })
              );
            else
              navigate(
                changeUrl({
                  catName: null,
                  catImage: null,
                  category: null,
                })
              );
          },
        }}
      />
    );
  if (integrationCodePermissions?.list || validPermission)
    selector.push(
      <IntegrationSelector
        isClearable
        value={
          QueryString?.integrationCode &&
          convertIntegrationData({
            code: integrationCodeName,
            id: QueryString?.integrationCode,
            telegramChatId: integrationCodeTelegramChatId,
          })
        }
        {...{
          label: "integrationCode",
          setState: (params) => {
            console.log({ params });

            if (params)
              navigate(
                changeUrl({
                  integrationCodeName: params?.data?.code,
                  integrationCodeTelegramChatId: params?.data?.telegramChatId,
                  integrationCode: params?.data?.id,
                })
              );
            else
              navigate(
                changeUrl({
                  integrationCodeName: null,
                  integrationCodeTelegramChatId: null,
                  integrationCode: null,
                })
              );
          },
        }}
      />
    );

  return (
    <div>
      <PageTitle
        title={t("listsAuthor", { author: t(COMPONENT_NAMES[0]) })}
        breadcrumbs={breadcrumbs}
        buttons={buttons}
      />

      {ShowUpdatePrice ? (
        <UpdatePriceComponent onClose={updatePriceToggle} />
      ) : (
        ""
      )}
      {isAdmin ? <TableFiltersSelector data={selector} /> : ""}

      {/* <ModalLayout {...{
        show: menuModalState,
        setShow: setMenuModalState,
        classNames: {
          modal: styles.modal,
          dialog: styles.modalDialog,
          content: styles.modalContent,
        },
        title: "add item to menus"
      }}>
        <AddItemToMenus product={menuModalState} handleClose={setMenuModalState} />
      </ModalLayout> */}
      <TableComponent
        {...{
          QueryString,
          data: data?.result,
          tableOptions,
          loading: isLoading,
          search: {
            placeholder: t("search"),

            defaultValue: QueryString.search_text,
          },
          title: `${COMPONENT_NAMES[1]}`,
          button: button,
          count: data.total,
          pagination: {
            activePage: page,
            pages: Math.ceil(data.total / limit),
            limited: 2,
          },
          checkbox: {
            state: checkList,
            setState: setCheckList,
          },
          actions: {
            onClick: handleAction,
          },
          status: STATUSES,
          excelButton: {
            show: true,
            disabled: !data?.result,
            onClick: handleGetProducts,
          },
        }}
      />
    </div>
  );
};

export default List;
