import axios from "axios";
import { removeTokens, getAccessToken } from "utils/cookies";
import api from "operations/network/api";
import configureStore from "./reduxConfig";

function axiosConfig(props) {
  axios.defaults.baseURL = api.host;
  const store = configureStore()
  const state = store.getState()
  console.log({ store, state });
  let token = state?.token?.data?.access_token || props?.token || getAccessToken();

  axios.defaults.headers = {
    "Accept-Language": "en",
    "Authorization": token ? "Bearer ".concat(token) : "",
  };

  const onRequestSuccess = (config) => {
    return config;
  };

  const onResponseSuccess = (response) => {
    return response;
  };

  const onResponseError = (error) => {
    console.log({ axiosError: error });

    if (error?.response?.status == 401) {
      removeTokens();
    }
    if (error?.response?.status == 403) {
      // removeTokens();
    }
    return Promise.reject(error);
  };

  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);

  return {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
  };
}

export default axiosConfig;
