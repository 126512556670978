import routes, { ROUTES_OBJECT } from "operations/routing/routes";

// import placeholder from "../images/placeholder.jpeg";
// const isProduction = process.env.NODE_ENV === "production";
export const GALLERY_QUERY_OPTION = { page: 1, limit: 102, type: "image" };
export const VIDEO_QUERY_OPTION = { page: 1, limit: 30, type: "video" };
// export const IMAGE_PLACEHOLDER = placeholder;
export const buildNo = "1.0.0";
export const BASE_IMAGE = "https://ds447a7iqja4o.cloudfront.net/images/"

export const formats = ["jpg", "jpeg", "png", "pdf", "gif", "webp"];


export const ADMIN_PERMISSIONS = {

    hotel: {
        permissions: {
            dashboard: true,
            orders: true,
            finance: true,
            financeHotels: true,
        },
        disabled: {

        },
        redirect: routes.dashboard.base
    },
    branch: {
        permissions: {
            orders: {
                edit: true,
                list: true,
            },
            finance: true,
            financeBranches: true,
        },
        disabled: {

        },
        redirect: routes.order.base
    },
    admin: {
        permissions: true,
        redirect: routes.order.base
    },
    // admin: {
    //     permissions,
    //     disabled: {

    //     },
    //     redirect: routes.dashboard.base,
    // },
    super_admin: {
        permissions: true,
        disabled: {

        },
        redirect: routes.dashboard.base,
    },
    // admin: true,
}
const redirect = {
    hotel: {
        redirect: {
            name: "dashboards",
            path: "dashboard",
        }
    },
    branch: {
        redirect: {
            name: "orders",
            path: "order",
        }
    },
    admin: {
        redirect: {
            name: "orders",
            path: "order",
        }
    },
    super_admin: {
        redirect: {
            name: "dashboards",
            path: "dashboard",
        }
    }
}
const checkRole = (role) => {

}

export const ADMIN_REDIRECT = ({ userType, permissions } = {}) => {
    console.log("* * * ADMIN_REDIRECT :", { userType, permissions });
    console.log("* * * ADMIN_REDIRECT :", {});
    if (!permissions) return ""
    const current = redirect[userType];
    console.log("* * * ADMIN_REDIRECT :", { current });

    const route = routes?.[current?.redirect?.path]?.base;
    console.log("* * * ADMIN_REDIRECT :", { route });

    const valid = permissions?.[current?.redirect?.name]
    console.log("* * * ADMIN_REDIRECT :", { valid });

    if (valid) return route

    const permissionsArray = Object.keys(permissions || {})
    console.log("* * * ADMIN_REDIRECT :", { permissionsArray });

    return ROUTES_OBJECT[permissionsArray[0]]?.base
    // return ""

    // permissionsArray[0]

}