import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { Accordion, Button, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { Link, Routes, useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME } from "../enum";
import {
  ORDER_STATUS_DATA,
  ORDER_STATUS_OBJECT,
  PAYMENT_DATA,
  PAYMENT_DATA_OBJECT,
  ROLES,
  STATUSES,
} from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import GalleryComponent from "components/gallery/GalleryComponent";
import { numberWithCommas } from "utils/calculators";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Factor from "containers/Factor";
import ReactDOMServer from "react-dom/server";
import PlacesSelector from "../../../containers/Places/PlacesSelector";
import { convertPlaceData } from "../../../containers/Places/PlacesSelector";
import reactDom from "react-dom";

const Add = () => {
  const { t } = useTranslation();

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [type, setType] = useState(null);
  // ------------------------------------ route
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
  const typeOptions = [
    { label: "User", value: "USER" },
    { label: "Admin", value: "ADMIN" },
  ];

  // ----------------------------------------------------------------------------- Fetching Functions
  const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
  const updating = async (params) => await axiosClient().patch(api[API_NAME].base + "/" + URL_ID, params);
  const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);
  // const getRoles = async () => await axiosClient().get(api["role"].list);

  const navigation = useNavigate();
  let { id } = useParams();
  const URL_ID = id ? id : "";
  // ----------------------------------------------------------------------------- Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validation(URL_ID)),
    mode: "onSubmit",
  });

  // ----------------------------------------------------------------------------- Mutation
  const onSuccessMutating = () => {
    toastify.success({ title: "success" });
    navigation(newRoute.base);
  };
  const { isLoading, data, mutate } = useMutationCustom({
    url: URL_ID ? updating : creating,
    invalidQuery: `getById_${API_NAME}`,
    onSuccess: onSuccessMutating,
  });

  // ----------------------------------------------------------------------------- Query By ID
  const onSuccessDataById = ({ data }) => {
    let params = { ...data };
    const resetData = {
      ...params,
      paymentType: PAYMENT_DATA_OBJECT[params.paymentType],
      status: orderStatus.object[params.status],
      products: params.products.map((x) => ({
        ...x,
        // place: convertPlaceData({ name: x.place }),
      })),
    };
    console.log({ params });
    console.log({ resetData });
    reset(resetData);
  };
  const dataById = useQueryCustom({
    name: `getById_${API_NAME}`,
    url: getById,
    onSuccess: onSuccessDataById,
    enabled: !!URL_ID,
  });

  //  ----------------------------------------- GETTING ROLES ----------------------------------------- //
  // const onSuccessRoles = ({ data }) => {
  //   setRolesOption(convertToLabelValue(data?.result));
  // };
  // const rolesData = useQueryCustom({
  //   name: "rolesData",
  //   url: getRoles,
  //   onSuccess: onSuccessRoles,
  //   params: { sort: "desc", page: 1, limit: 10, status: 1 },
  // });
  // --------------------------------------- Submit Form
  const onSubmit = () => {
    const { paymentType, status, productPlace, cancelNote, ...values } = getValues();
    const requestData = {
      paymentType: paymentType.value,
      status: status?.value,
      cancelNote: cancelNote || dataById?.data?.data?.cancelNote
      // products: values.products.map((x) => ({
      //   oldProduct: x.product.id,
      //   newProduct: undefined,
      //   canceled: true,
      //   notify: false,
      // })),
    };
    console.log("requestData", { requestData }, values);
    mutate(requestData);
  };

  // ----------------------------------------------------------------------------- Constance
  const dispatch = useDispatch();
  const button = {
    title: `Back To ${COMPONENT_NAMES[0]} List`,
    link: newRoute.base + newRoute.list,
  };

  const breadcrumbs = [
    { title: "Dashboard", link: routes.dashboard.base },
    { title: COMPONENT_NAMES[0], link: newRoute.base },
    {
      title: URL_ID ? `Edit / ${dataById?.data?.data?.result?.name} ${dataById?.data?.data?.result?.name}` : "Add",
    },
  ];
  // ---------------------------------------------- EFFECTS
  useEffect(() => {
    dispatch(actionBreadcrumbs.set(breadcrumbs));
  }, [dataById]);
  // ----------------------------------------------------------------------------- Log
  const values = getValues();
  console.log({ dataById, values }, "Add");
  console.log({ URL_ID });
  console.log({ errors }, "errors");

  // ----------------------------------------------------------------------------- UseEffects

  //  ----------------------------------------- GALLERY ----------------------------------------- //
  const [showModal, setShowModal] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const handleGalleryModal = () => {
    setShowModal(!showModal);
  };
  const handleSelectThumbnail = (thumbnail) => {
    setValue(fieldNames.image, thumbnail, {
      shouldValidate: true,
    });
    setThumbnail(thumbnail);
  };

  const adminState = useSelector((state) => state.admin);
  const isHotel = adminState.data.userType === "hotel";
  const isBranch = adminState.data.userType === "branch";
  const isAdmin = adminState.data.userType.includes("admin");
  const orderStatus = ORDER_STATUS_DATA(isBranch, isHotel);

  const fullDisable = isHotel;
  const halfDisable = isBranch || fullDisable;


  async function printDiv() {
    var myframe = document.createElement("iframe");
    myframe.style.position = "absolute";
    myframe.style.top = "-10000px";
    document.body.appendChild(myframe);
    let props = {
      isBranch,
      isAdmin,
      isHotel
    };
    if (dataById?.data?.data) {
      reactDom.render(<Factor {...dataById?.data?.data} propData={props} />, myframe.contentDocument.body);
    }

    setTimeout(function () {
      myframe.focus();
      myframe.contentWindow.print();

      try {
        myframe.parentNode.removeChild(myframe);
      } catch (error) {

      }
    }, 1000);
    window.focus();
  }

  // async function printDiv() {
  //   var myframe = document.createElement("IFRAME");
  //   myframe.domain = document.domain;
  //   myframe.style.position = "absolute";
  //   myframe.style.top = "-10000px";
  //   var parser = new DOMParser();
  //   // var parsedDocument = parser.parseFromString(xhr.response, 'text/html');
  //   document.body.appendChild(myframe);
  //   let props = {
  //     data: { ...dataById?.data?.data },
  //     role: { isBranch },
  //   };
  //   let element = ReactDOMServer.renderToString(<Factor {...props} />);

  //   myframe.contentDocument.write(element);
  //   setTimeout(function () {
  //     myframe.focus();
  //     myframe.contentWindow.print();
  //     myframe.parentNode.removeChild(myframe); // remove frame
  //   }, 1000); // wait for images to load inside iframe
  //   window.focus();
  // }

  return (
    <div>
      <GalleryModal
        {...{
          showModal,
          handleModal: handleGalleryModal,
          handleClickedImage: handleSelectThumbnail,
          selectedPhoto: thumbnail,
        }}
      />
      <PageTitle title={URL_ID ? `View  ${COMPONENT_NAMES[0]}` : `Add  ${COMPONENT_NAMES[0]}`} button={button} />
      <form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
        <div className="row mt-5">
          <Col md="8">
            <div className="col-12 border p-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{t("orderInformation")}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <FieldText
                        require
                        disabled
                        {...{
                          name: fieldNames.orderNumber,
                          register,
                          placeholder: "orderNumber",
                          label: "orderNumber",
                          errors,
                        }}
                      />

                      <FieldText
                        require
                        disabled
                        {...{
                          name: fieldNames.name,
                          register,
                          placeholder: "name",
                          label: "Name",
                          errors,
                        }}
                      />

                      <FieldText
                        require
                        disabled
                        {...{
                          name: fieldNames.note,
                          register,
                          placeholder: "note",
                          label: "note ",
                          errors,
                        }}
                      />

                      <FieldText
                        require
                        disabled
                        {...{
                          name: fieldNames.dollar,
                          register,
                          placeholder: "dollar",
                          label: "dollar",
                          errors,
                        }}
                      />
                      <FieldText
                        require
                        disabled
                        {...{
                          name: fieldNames.euro,
                          register,
                          placeholder: "euro",
                          label: "euro",
                          errors,
                        }}
                      />

                      <FieldText
                        require
                        disabled
                        {...{
                          name: fieldNames.totalPrice,
                          register,
                          placeholder: "totalPrice",
                          label: "totalPrice ",
                          errors,
                        }}
                      />
                      <FieldText
                        require
                        disabled
                        {...{
                          name: fieldNames.totalPriceWithoutFee,
                          register,
                          placeholder: "totalPriceWithoutFee",
                          label: "totalPriceWithoutFee ",
                          errors,
                        }}
                      />

                      <FieldText
                        require
                        disabled
                        {...{
                          name: fieldNames.roomNumber,
                          register,
                          placeholder: "roomNumber",
                          label: "roomNumber ",
                          errors,
                        }}
                      />

                      {/* <GalleryComponent
                        isRequired
                        {...{
                          handleModal: handleGalleryModal,
                          selectedPhoto: thumbnail,
                          labe: "image",
                          name: fieldNames.image,
                          Controller,
                          control,
                          errors,
                          register,
                        }}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isAdmin ? (
              <div className="col-12 border p-4 mt-4">
                <div className="">
                  <div className="b-1">
                    <h4 className="">{"Delivery Information"}</h4>
                  </div>
                  <div className="mt-5">
                    <div className="col-12">
                      <div className="basic-form">
                        <FieldText
                          require
                          disabled
                          {...{
                            name: "delivery.firstName",
                            register,
                            placeholder: "firstName",
                            label: "firstName",
                            errors,
                          }}
                        />

                        <FieldText
                          require
                          disabled
                          {...{
                            name: "delivery.lastName",
                            register,
                            placeholder: "lastName",
                            label: "lastName",
                            errors,
                          }}
                        />

                        <FieldText
                          require
                          disabled
                          {...{
                            name: "deliveryLink",
                            register,
                            placeholder: "deliveryLink",
                            label: "deliveryLink ",
                            errors,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-12 border p-4 mt-4">
              <div className="">
                <div className="b-1">
                  <h4 className="">{"Delivery Information"}</h4>
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <FieldText
                        require
                        disabled
                        {...{
                          name: "delivery.firstName",
                          register,
                          placeholder: "firstName",
                          label: "firstName",
                          errors,
                        }}
                      />

                      <FieldText
                        require
                        disabled
                        {...{
                          name: "delivery.lastName",
                          register,
                          placeholder: "lastName",
                          label: "lastName",
                          errors,
                        }}
                      />

                      <FieldText
                        require
                        disabled
                        {...{
                          name: "deliveryLink",
                          register,
                          placeholder: "deliveryLink",
                          label: "deliveryLink ",
                          errors,
                        }}
                      />

                      {/* <FieldText
                        require
                        disabled
                        {...{
                          name: fieldNames.euro,
                          register,
                          placeholder: "euro",
                          label: "euro",
                          errors,
                        }}
                      />

                      <FieldText
                        require
                        disabled
                        {...{
                          name: fieldNames.totalPrice,
                          register,
                          placeholder: "totalPrice",
                          label: "totalPrice ",
                          errors,
                        }}
                      />
                      <FieldText
                        require
                        disabled
                        {...{
                          name: fieldNames.totalPriceWithoutFee,
                          register,
                          placeholder: "totalPriceWithoutFee",
                          label: "totalPriceWithoutFee ",
                          errors,
                        }}
                      />

                      <FieldText
                        require
                        disabled
                        {...{
                          name: fieldNames.roomNumber,
                          register,
                          placeholder: "roomNumber",
                          label: "roomNumber ",
                          errors,
                        }}
                      /> */}

                      {/* <GalleryComponent
                        isRequired
                        {...{
                          handleModal: handleGalleryModal,
                          selectedPhoto: thumbnail,
                          labe: "image",
                          name: fieldNames.image,
                          Controller,
                          control,
                          errors,
                          register,
                        }}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 border p-4 mt-4">
              <div className="">
                <div className="b-1  d-flex justify-content-between align-items-center">
                  <h4 className="">{t("hotel")}</h4>

                  {!halfDisable ? (
                    <div className="">
                      <Controller
                        control={control}
                        name={"hotel"}
                        render={({ field: { onChange: change, value } }) => {
                          return (
                            <Link
                              to={routes.hotel.base + routes.hotel.edit + "/" + value?.id}
                              target="_blank"
                              className="btn btn-primary "
                            >
                              {t("viewAuthor", { author: t("hotel") })}
                            </Link>
                          );
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <FieldText
                        require
                        disabled
                        {...{
                          name: "hotel.name",
                          register,
                          placeholder: "name",
                          label: "Name ",
                          errors,
                        }}
                      />
                      <FieldText
                        require
                        disabled
                        {...{
                          name: "hotel.address",
                          register,
                          placeholder: "address",
                          label: "address ",
                          errors,
                        }}
                      />

                      {!halfDisable ? (
                        <>
                          <FieldText
                            require
                            disabled
                            {...{
                              name: "hotel.commercialTitle",
                              register,
                              placeholder: "commercialTitle",
                              label: "commercialTitle ",
                              errors,
                            }}
                          />

                          <FieldText
                            require
                            disabled
                            {...{
                              name: "hotel.roomCount",
                              register,
                              placeholder: "roomCount",
                              label: "roomCount ",
                              errors,
                            }}
                          />

                          <FieldText
                            require
                            disabled
                            {...{
                              name: "hotel.taxNo",
                              register,
                              placeholder: "taxNo",
                              label: "taxNo ",
                              errors,
                            }}
                          />
                          <GalleryComponent
                            isRequired
                            {...{
                              labe: "QRcode",
                              name: "hotel.qrCode.path",
                              Controller,
                              control,
                              errors,
                              register,
                            }}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 border p-4 mt-4">
              <div className="">
                <div className="b-1  d-flex justify-content-between align-items-center">
                  <h4 className="">{t("branch")}</h4>

                  {!halfDisable ? (
                    <div className="">
                      <Controller
                        control={control}
                        name={"hotel.branch"}
                        render={({ field: { onChange: change, value } }) => {
                          return (
                            <Link
                              to={routes.branch.base + routes.branch.edit + "/" + value?.id}
                              target="_blank"
                              className="btn btn-primary "
                            >
                              {t("viewAuthor", { author: t("hotel") })}
                            </Link>
                          );
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mt-5">
                  <div className="col-12">
                    <div className="basic-form">
                      <FieldText
                        require
                        disabled
                        {...{
                          name: "hotel.branch.name",
                          register,
                          placeholder: "name",
                          label: "Name ",
                          errors,
                        }}
                      />
                      <FieldText
                        require
                        disabled
                        {...{
                          name: "hotel.branch.address",
                          register,
                          placeholder: "address",
                          label: "address ",
                          errors,
                        }}
                      />

                      {!halfDisable ? (
                        <>
                          <FieldText
                            require
                            disabled
                            {...{
                              name: "hotel.branch.commercialTitle",
                              register,
                              placeholder: "commercialTitle",
                              label: "commercialTitle ",
                              errors,
                            }}
                          />

                          <FieldText
                            require
                            disabled
                            {...{
                              name: "hotel.branch.startTime",
                              register,
                              placeholder: "startTime",
                              label: "startTime",
                              errors,
                            }}
                          />

                          <FieldText
                            require
                            disabled
                            {...{
                              name: "hotel.branch.taxNo",
                              register,
                              placeholder: "taxNo",
                              label: "taxNo ",
                              errors,
                            }}
                          />

                          <FieldText
                            require
                            disabled
                            {...{
                              name: "hotel.branch.currentNotWorkingReason",
                              register,
                              placeholder: "currentNotWorkingReason",
                              label: "currentNotWorkingReason ",
                              errors,
                            }}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 border p-4 mt-4">
              <div className="">
                <Controller
                  control={control}
                  name={"products"}
                  render={({ field: { onChange: change, value } }) => {
                    return (
                      <div>
                        <div className="b-1">
                          <h4 className="">{`products (${value?.length})`}</h4>
                        </div>
                        <Accordion>
                          {value?.map((x, index) => {
                            console.log({ x });
                            return (
                              <Accordion.Item eventKey={index}>
                                <Accordion.Header className="w-100 ">
                                  <div className="list-group-item p-3 bg-snow mt-4 w-100 mr-4">
                                    <div className="row w-100 no-gutters">
                                      <div className="col-6 col-md">
                                        <h6 className="text-charcoal mb-0 w-100">totalPrice</h6>
                                        <p className="text-pebble mb-0 w-100 mb-2 mb-md-0">
                                          €{numberWithCommas(x?.price)}
                                        </p>
                                      </div>
                                      <div className="col-6 col-md">
                                        <h6 className="text-charcoal mb-0 w-100">quantity</h6>
                                        <p className="text-pebble mb-0 w-100 mb-2 mb-md-0">{x?.quantity}</p>
                                      </div>
                                      {!halfDisable ? (
                                        <div className="col-12 col-md-3">
                                          <Link
                                            to={routes.product.base + routes.product.edit + "/" + x?.product?.id}
                                            target="_blank"
                                            className="btn btn-primary w-100"
                                          >
                                            View
                                          </Link>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="mt-5">
                                    <div className="col-12">
                                      <div className="basic-form">
                                        <FieldText
                                          require
                                          disabled
                                          {...{
                                            name: `products[${index}].product.name`,
                                            register,
                                            placeholder: "name",
                                            label: "Name ",
                                            errors,
                                          }}
                                        />
                                        <FieldText
                                          require
                                          disabled
                                          value={x?.price / x?.quantity}
                                          {...{
                                            // name: `products[${index}].product.price`,
                                            // register,
                                            placeholder: "price",
                                            label: "price",
                                            errors,
                                          }}
                                        />

                                        {isAdmin ? (
                                          <>
                                            <FieldText
                                              require
                                              disabled
                                              {...{
                                                name: `products[${index}].product.buyPrice`,
                                                register,
                                                placeholder: "buyPrice",
                                                label: "buyPrice",
                                                errors,
                                              }}
                                            />

                                            {/* <PlacesSelector
                                              require
                                              {...{
                                                Controller,
                                                name: `products[${index}].place`,
                                                register,
                                                label: "Places",
                                                errors,
                                                control,
                                              }}
                                            /> */}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {!halfDisable ? (
                                          <>
                                            <FieldText
                                              require
                                              disabled
                                              {...{
                                                name: `products[${index}].product.stock`,
                                                register,
                                                placeholder: "stock",
                                                label: "stock",
                                                errors,
                                              }}
                                            />
                                            <FieldText
                                              require
                                              disabled
                                              {...{
                                                name: `products[${index}].product.integrationCode`,
                                                register,
                                                placeholder: "integrationCod",
                                                label: "integrationCod",
                                                errors,
                                              }}
                                            />
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <GalleryComponent
                                          isRequired
                                          {...{
                                            // selectedPhoto: x.product.images,
                                            labe: "image",
                                            name: `products[${index}].product.images`,
                                            Controller,
                                            control,
                                            errors,
                                            register,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                          })}
                        </Accordion>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </Col>

          <Col md="4">
            <div className="row">
              <Col md="12 border p-4">
                <div className="card">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title">{"Actions"}</h4>

                    <span
                      className="btn btn-success shadow btn-xs sharp pointer action-btn mdi rounded p-2 "
                      onClick={printDiv}
                    // onClick={() => onClick({ data: [param], status: { label: "print" } })}
                    >
                      <i className="mdi mdi-printer"></i>
                    </span>
                  </div>

                  <div className="card-body">
                    <div className="basic-form">
                      <div className="publishing-actions">
                        <div className="pub-section">
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>

                          <FieldSelect
                            {...{
                              Controller,
                              name: fieldNames.paymentType,
                              register,
                              label: "paymentType",
                              errors,
                              control,
                              options: PAYMENT_DATA,
                              isDisabled: halfDisable,
                            }}
                          />
                        </div>

                        <div className="pub-section">
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>

                          <FieldSelect
                            {...{
                              Controller,
                              name: fieldNames.status,
                              register,
                              label: "status",
                              errors,
                              control,
                              options: orderStatus.array,
                              isDisabled: fullDisable,
                            }}
                          />
                        </div>
                      </div>

                      <hr />

                      <Button
                        block
                        type="success"
                        htmlType="submit"
                        className={`btn btn-success btn-sm`}
                        loading={isLoading}
                      >
                        {!isLoading ? t("publish") : "Loading..."}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
            <div className="row"></div>
          </Col>
        </div>
      </form>
    </div>
  );
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
