import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
  userType: "userType",
  permissions: "permissions",
};

export const validation = () =>
  yup.object().shape({
    [fieldNames.userType]: yup.object().required(ERRORS.pleaseEnterYour("Name")).label("Name"),
    // [fieldNames.permissions]: yup.array().nullable().required(ERRORS.pleaseSelectYour("Permissions")).label("Permissions"),
    [fieldNames.permissions]: yup.object(),
  });
