import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, PARENT_API_NAME, ROUTE_NAME, PARENT_ROUTE_NAME, PARENT_COMPONENT_NAMES, PERMISSION } from "../enum";
import { tableData } from "./tableInfo";
import { useTranslation } from "react-i18next";
import AssignUser from "../components/AssignUser";

const List = () => {
  const [checkList, setCheckList] = useState();
  const [assignModal, setAssignModal] = useState(false);
  const adminState = useSelector((state) => state.admin);
  const validPermission = adminState?.data?.permissions === true
  const permissions = validPermission || adminState?.data?.permissions?.[PERMISSION]
  // ---------------------------------------------- new route
  const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
  const newParentRoute = useMemo(() => getDataInObjectDepth(PARENT_ROUTE_NAME, routes), []);
  // ---------------------------------------------- Constance
  const QueryString = useQueryString({ sort: "desc" });
  const { page, limit } = QueryString;
  const dispatch = useDispatch();
  let { id } = useParams();
  const { t } = useTranslation();

  // ---------------------------------------------- fetching functions
  const getting = async () =>
    await axiosClient().get(api[PARENT_API_NAME].list + "/" + id, {
      params: { ...QueryString },
    });
  const changeStatus = async (params) => {
    console.log(params, "changeStatus");
    await axiosClient().patch(api[API_NAME].changeStatus, params);
  };
  const assignApi = async (params) => {
    console.log(params, "assignApi");
    await axiosClient().patch(`/v1/admin/hotel/${id}/users/assign/${params.user}`);
  };
  const unassignApi = async (params) => {
    console.log(params, "assignApi");
    await axiosClient().patch(`/v1/admin/hotel/${id}/users/unassign/${params.user}`);
  };
  // ------------------------------------------------------- query Get
  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
  } = useQueryCustom({
    name: `${API_NAME}_get`,
    url: getting,
    params: { ...QueryString },
  });
  // ---------------------------------------------- mutation Change Status
  const onSuccessUnassignMutation = () => {
    toastify.success({ title: "success" });
    refetch()
  };

  const unassignMutation = useMutationCustom({
    name: `${API_NAME}_unassign_${id}`,
    url: unassignApi,
    invalidQuery: `${API_NAME}_get_${id}`,
    onSuccess: onSuccessUnassignMutation,
  });
  // ---------------------------------------------- mutation Change Status
  const onSuccessAssignMutation = () => {
    toastify.success({ title: "success" });
    refetch()
  };

  const AssignMutation = useMutationCustom({
    name: `${API_NAME}_Assign_${id}`,
    url: assignApi,
    invalidQuery: `${API_NAME}_get_${id}`,
    onSuccess: onSuccessAssignMutation,
  });
  // ---------------------------------------------- mutation Change Status
  const onSuccessChangeStatusMutation = () => {
    toastify.success({ title: "success" });
  };

  const changeStatusMutation = useMutationCustom({
    name: `${API_NAME}_changeStatus`,
    url: changeStatus,
    invalidQuery: `${API_NAME}_get_${id}`,
    onSuccess: onSuccessChangeStatusMutation,
  });
  // ---------------------------------------------- logs
  console.log({ data, error, isError, isLoading });
  // ---------------------------------------------- action click table

  const actionsOperation = ({ data, status }) => {
    swal({
      title: "Are you sure?",
      text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willBe) => {
      if (willBe) {
        changeStatusMutation.mutate({ ids: data, isActive: status.value });
      }

    });
  };

  const clickAction = ({ data, status }) => {
    const newData = data.map((x) => x.id);
    actionsOperation({ data: newData, status });
  };

  const handleAction = (status) => {
    const data = Object.keys(checkList);
    console.log({ data, status });
    actionsOperation({ data, status: status });
  };
  // ---------------------------------------------- breadcrumbs
  const breadcrumbs = [{ title: "Dashboard", link: routes.dashboard.base }, { title: PARENT_COMPONENT_NAMES[0], link: newParentRoute.base }, { title: COMPONENT_NAMES[0] }, { title: "List" }];
  // ---------------------------------------------- EFFECTS
  useEffect(() => {
    dispatch(actionBreadcrumbs.set(breadcrumbs));
  }, []);



  const handleAssign = () => {
    // alert("assign")
    setAssignModal({
      show: true
    })
  }

  const submittedAssign = (user) => {
    AssignMutation.mutate({ user });
  }

  const handleUnassign = (data) => {
    console.log("* * * unassign : ", { data })
    unassignMutation.mutate({ user: data?.id });
  }

  const button = [

    {
      title: t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) }), link: newRoute.base + newRoute.add + "/" + id,
    },
  ];

  if (validPermission) button.unshift({
    title: t(`assign`), onClick: handleAssign, className: "btn-success"
  },)


  const tableOptions = tableData({
    onClick: clickAction, newRoute, id,
    ...(validPermission && {
      active: {
        manuel: {
          icon: "mdi mdi-account-minus",
          class: "bg-warning",
          onClick: (data) => {

            swal({
              title: "Are you sure?",
              text: `You want to unassign this user (${data.name} ${data.lastName})`,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willBe) => {
              if (willBe) {
                handleUnassign(data)
              }

            });

          }
          ,
        },
      }
    })

  });

  //  ----------------------------------------- LOGS ----------------------------------------- //
  console.log({ checkList, data }, "checkList");


  return (
    <div>
      <PageTitle title={t("listsAuthor", { author: t(COMPONENT_NAMES[0]) })} breadcrumbs={breadcrumbs} buttons={button} />


      <AssignUser  {...{
        setShow: setAssignModal,
        show: assignModal,
        onSubmit: submittedAssign
      }} />


      <TableComponent
        {...{
          QueryString,
          data: data?.users,
          tableOptions,
          loading: isLoading,
          // search: {
          //   placeholder: `Search ${COMPONENT_NAMES[0]}`,
          //   defaultValue: QueryString.search_text,
          // },
          title: `${COMPONENT_NAMES[1]}`,
          button: button,
          count: data.total,
          pagination: {
            activePage: page,
            pages: Math.ceil(data.total / limit),
            limited: 2,
          },
          checkbox: {
            state: checkList,
            setState: setCheckList,
          },
          actions: {
            onClick: handleAction,
          },
          status: STATUSES,
          disables: {
            all: true
          }
        }}
      />
    </div>
  );
};

export default List;
