import { ERRORS } from "enumerations";
import * as yup from "yup";
export const fieldNames = {
  user: "user",
};



export const validation = yup.object().shape({
  [fieldNames.user]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.user)).label(fieldNames.user),
});
