import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PERMISSION } from "../enum";
import { tableData } from "./tableInfo";
import { useTranslation } from "react-i18next";
import * as hotelMenuEnum from "containers/HotelMenu/enum";
import RemoveProductSelector from "../component/RemoveProductSelector";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";

const List = () => {
	const { t } = useTranslation();
	const [checkList, setCheckList] = useState();
	const [showModal, setShowModal] = useState(false);
	const adminState = useSelector((state) => state.admin);
	const validPermission = adminState?.data?.permissions === true;
	const permissions = validPermission || adminState?.data?.permissions?.[PERMISSION];
	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	const newRouteHotelMenu = useMemo(() => getDataInObjectDepth(hotelMenuEnum.ROUTE_NAME, routes), []);

	// ---------------------------------------------- Constance
	const button = (validPermission || permissions?.create) && {
		title: t(`addAuthor`, { author: t(COMPONENT_NAMES[0]) }),
		link: newRoute.base + newRoute.add,
	};
	const buttonTwo = (validPermission || permissions?.addItemAllMenus) && {
		title: `Add ${"menu to all hotel"}`,
		link: newRouteHotelMenu.base + newRouteHotelMenu.add + "/add-item-all-hotels",
	};
	const buttonThree = (validPermission || permissions?.addItemAllMenus) && {
		title: `Add ${"item to all menus"}`,
		link: newRoute.base + newRoute.addItem + "/all",
	};
	const buttonFour = (validPermission || permissions?.create) && {
		title: `Add ${"item with integration codes"}`,
		link: newRoute.base + newRoute.addItem + "/add-menu-with-integration-codes",
	};

	const buttonFive = (validPermission || permissions?.addItemAllMenus) && {
		title: `Add ${"items to all menus"}`,
		link: newRoute.base + newRoute.addItemsToMenus,
	};

	const buttonSix = (validPermission || permissions?.create) && {
		title: `remove ${"items with integration codes"}`,
		link: newRoute.base + newRoute.addItem + "/remove-menus-with-integration-codes",
		className: "btn-danger",
	};

	const buttons = [buttonFour, buttonSix, buttonFive, buttonThree, buttonTwo, button];
	const QueryString = useQueryString({ sort: "desc" });
	const { page, limit } = QueryString;
	const dispatch = useDispatch();

	// ---------------------------------------------- fetching functions
	const getting = async () =>
		await axiosClient().get(api[API_NAME].list, {
			params: { ...QueryString },
		});
	const changeStatus = async (params) => {
		console.log(params, "changeStatus");
		await axiosClient().patch(api[API_NAME].changeStatus, params);
	};
	const deleting = async (params) => {
		await axiosClient().delete(api[API_NAME].delete + "/" + params?.id);
	};
	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get`,
		url: getting,
		params: { ...QueryString },
		enabled: !!(validPermission || permissions?.list),
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessDeleteMutation = () => {
		toastify.success({ title: "success" });
	};

	const deleteMutation = useMutationCustom({
		name: `${API_NAME}_delete`,
		url: deleting,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessDeleteMutation,
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessChangeStatusMutation = () => {
		toastify.success({ title: "success" });
	};

	const changeStatusMutation = useMutationCustom({
		name: `${API_NAME}_changeStatus`,
		url: changeStatus,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessChangeStatusMutation,
	});
	// ---------------------------------------------- logs
	console.log({ data, error, isError, isLoading });
	// ---------------------------------------------- action click table

	const actionsOperation = ({ data, status }) => {
		swal({
			title: "Are you sure?",
			text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				if (status.label === "delete") deleteMutation.mutate({ id: data });
				else changeStatusMutation.mutate({ ids: data, isActive: status.value });
			}
		});
	};

	const clickAction = ({ data, status }) => {
		const newData = data.map((x) => x.id);
		actionsOperation({ data: newData, status });
	};

	const handleAction = (status) => {
		const data = Object.keys(checkList);
		console.log({ data, status });
		actionsOperation({ data, status: status });
	};

	const exportToCSV = ({ apiData, fileName, head, cols }) => {
		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e["c"] = head.length - 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);
		// worksheet["!autofilter"] = { ref: "A1:E1" };
		worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		worksheet["!cols"] = cols;

		XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
		XLSX.writeFile(workbook, (fileName || "excel_export") + ".xlsx");
	};

	const handleDownloadItems = (data) => {
		let cols = [{ wch: 30 }, { wch: 10 }, { wch: 35 }, { wch: 20 }];

		axiosClient()
			.get(api[API_NAME].get + "/" + data?.id)
			.then((x) => {
				console.log({ x });
				const head = [t("productName"), t("price"), t("integrationCode"), t("branchName")];
				const data = x?.data?.items?.map((y) => ({
					[t("productName")]: y?.product?.name,
					[t("price")]: y?.price,
					[t("integrationCode")]: y?.product?.integrationCode,
					[t("branchName")]: y?.product?.branchString || "-",
				}));
				console.log("data =====>", data);

				exportToCSV({ apiData: data, fileName: `all-items-${x?.data?.name}`, head, cols });
			})
			.catch((err) => {
				console.log({ err });
			});
	};
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [{ title: "Dashboard", link: routes.dashboard.base }, { title: COMPONENT_NAMES[0], link: newRoute.base }, { title: "List" }];
	const isBranch = adminState.data.userType === "branch";
	const isHotel = adminState.data.userType === "hotel";
	const isAdmin = adminState.data.userType?.includes("admin");
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);

	let manuel = [
		{
			icon: "mdi mdi-cloud-download",
			class: "bg-info",
			onClick: handleDownloadItems,
			title: t("download"),
		},
	];

	if (validPermission || permissions?.addItem)
		manuel.push({
			icon: "mdi mdi-cart-plus",
			route: "addItem",
			title: t("addItem"),
		});

	if (validPermission || permissions?.deleteItem)
		manuel.push({
			icon: "mdi mdi-basket",
			class: "bg-warning",
			onClick: (data) => setShowModal(data),
			title: t("basket"),
		});

	const tableOptions = tableData({
		onClick: clickAction,
		newRoute,
		active: {
			manuel,
		},
		disable: { ...(!validPermission && { edit: !permissions?.edit, delete: !permissions?.delete }) },
	});

	//  ----------------------------------------- LOGS ----------------------------------------- //
	console.log({ checkList, data }, "checkList");

	return (
		<div>
			<RemoveProductSelector
				{...{
					setModal: setShowModal,
					show: showModal,
					isBranch,
					isHotel,
					onClick: clickAction,
				}}
			/>
			<PageTitle title={t("listsAuthor", { author: t(COMPONENT_NAMES[0]) })} breadcrumbs={breadcrumbs} buttons={buttons} />

			<TableComponent
				{...{
					QueryString,
					data: data?.result,
					tableOptions,
					loading: isLoading,
					search: {
						placeholder: t("search"),

						defaultValue: QueryString.search_text,
					},
					title: `${COMPONENT_NAMES[1]}`,
					button: button,
					count: data.total,
					pagination: {
						activePage: page,
						pages: Math.ceil(data.total / limit),
						limited: 2,
					},
					checkbox: {
						state: checkList,
						setState: setCheckList,
					},
					actions: {
						onClick: handleAction,
					},
					status: STATUSES,
				}}
			/>
		</div>
	);
};

export default List;
