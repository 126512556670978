
const orderActionTypes = {
  REQUEST: 'ORDER_REQUEST',
  RECEIVE: 'ORDER_RECEIVE',
  FAILURE: 'ORDER_FAILURE',
  INITIAL: 'ORDER_INITIAL',
  RUN_EVENTS: 'ORDER_RUN_EVENT',
  FAILURE_INITIAL: 'ORDER_FAILURE_INITIAL',
}
export default orderActionTypes
