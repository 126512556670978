import moment from "moment";

export const customMoment = (d) => {
	try {
		return moment(d);
	} catch (error) {
		return false;
	}
};

export const checkDateFormat = (date) => {
	let def = "2017-03-13";
	try {
		let newDate = moment(date);
		if (!newDate.isValid()) return false;
		return date;
	} catch (error) {
		return def;
	}
};

export const convertTrToAmPm = (timeString) => {
	if (!timeString) return;
	return timeString?.replace?.("ÖÖ", "AM")?.replace?.("ÖS", "PM");
};

// export function convertTrToAmPm(timeString) {
// 	if (!timeString) return;
// 	let [time, period] = timeString.split(" ");
// 	if (period === "ÖS") {
// 		period = "PM";
// 	} else if (period === "ÖÖ") {
// 		period = "AM";
// 	}
// 	return `${time} ${period}`;
// }
// .format("hh:mm A")
// .locale("en").format("hh:mm A")
