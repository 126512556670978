import React, { Fragment, useEffect, useRef } from "react";
// import { InputError } from "components/forms/InputError";
import AsyncSelect from "react-select/async";
import { API_NAME } from "./enum";
import axiosClient from "lib/axiosConfig";
import { useQueryCustom } from "utils/useQueryCustom";
import api from "operations/network/api";
import { useMemo } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import compareTranslate from "utils/compareTranslate";
import { Form } from "react-bootstrap";

const LoadingIndicator = () => {
  return (
    <div className="mx-2">
      <Spinner animation="grow" size="sm" />
    </div>
  );
};

export const convertCategoriesData = (data, { withAll, withParent, t } = {}) => {
  console.log({ data });
  const param = data?.map?.((x) => ({
    label: (
      <div className="flex align-items-center justify-items-center ">
        <div className="ml-4 mt-1 d-flex align-items-center">{
          // withParent
          //     ?
          ` ${x?.parent?.name ? `${x?.parent?.name} -> ` : ""} ${x?.name}`
          // :
          // x.name
        }</div>
      </div>
    ),
    value: x?.id || x?._id,
    name: x?.name,
    image: x?.image,
  }));

  if (withAll)
    param?.unshift?.({
      label: (
        <div className="flex align-items-center justify-items-center">
          <div className="ml-4 mt-1 d-flex align-items-center">{t("all")}</div>
        </div>
      ),
      value: "all",
    });

  return param;
};

export const convertCategoryData = (x, { withParent } = {}) => ({
  label: (
    <div className="flex align-items-center justify-items-center">
      <div className="ml-4 mt-1 d-flex align-items-center">
        {
          // withParent
          //     ?
          ` ${x?.parent?.name ? `${x?.parent?.name} -> ` : ""} ${x?.name}`
          // : x.name
        }
      </div>
    </div>
  ),
  value: x?.id || x?._id,
  name: x?.name,
  image: x?.image,
});

const CategorySelector = ({
  name,
  control,
  label,
  valid,
  register,
  errors,
  require,
  options,
  setState,
  withAll,
  setParams,
  withParent,
  isDisabled,
  ...other
}) => {
  // const [dataForSelectBox, setDataForSelectBox] = React.useState([])

  const { t } = useTranslation();

  const getting = async (params) => {
    const { search_text, limit, page } = params;
    return await axiosClient().get(api[API_NAME].list, {
      params: {
        limit: limit || 30,
        search_text,
        page,
        ...(setParams && setParams),
      },
    });
  };
  // ------------------------------------------------------- query Get
  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
  } = useQueryCustom({
    name: `${API_NAME}_get`,
    url: getting,
    // params: {}
    // params: { ...QueryString },
  });

  // const loadOptions = async (inputValue, callback) => {
  //     const params = {
  //         search_text: inputValue,
  //         limit: 5,
  //         page: 1
  //     }
  //     await getting(params).then(x => {
  //         callback(convertCategoriesData(x?.data?.result, { withAll, withParent, t }))
  //     })

  // }

  const searchRef = useRef();

  const loadOptions = async (inputValue, callback) =>
    new Promise((resolve, reject) => {
      if (searchRef.current) clearTimeout(searchRef.current);
      // if (!inputValue) return callback()
      const time = setTimeout(async () => {
        const params = {
          search_text: inputValue,
          limit: 20,
          page: 1,
        };
        await getting(params).then((x) => {
          resolve(callback(convertCategoriesData(x?.data?.result, { withAll, t })));
        });
      }, 1000);
      searchRef.current = time;
    });
  // React.useEffect(() => {
  //     async function getInitData() {
  //         const query = {}
  //         await GetAll(query)
  //             .then((response) => {
  //                 const { data } = response.data

  //                 let arrayForSelect = []

  //                 data?.data.forEach((item) => {
  //                     arrayForSelect.push({
  //                         value: item,
  //                         label: (
  //                             <div className='flex align-items-center justify-items-center'>
  //                                 <div className='ml-4 mt-1 d-flex align-items-center'>{item.title}</div>
  //                             </div>
  //                         ),
  //                     })
  //                 })

  //                 setDataForSelectBox(arrayForSelect)
  //             })
  //             .catch((err) => { })
  //     }
  // }, [])
  const dataForSelectBox = useMemo(() => {
    return convertCategoriesData(data?.result, { withAll, withParent, t });
  }, [data]);
  let newRegister = register ? register(name) : {};

  useEffect(() => {
    refetch();
  }, [setParams]);
  console.log({ setParams });
  // console.log({ data, dataForSelectBox });

  return (
    <>
      <Form.Group className="form-group ">
        {label ? (
          <div className={"d-flex justify-content-between"}>
            <label htmlFor={name}>
              {compareTranslate(t, label)}
              {require ? " *" : ""}
            </label>
            {errors ? <div className={`error-text `}>{compareTranslate(t, errors[name]?.message)}</div> : ""}
          </div>
        ) : (
          ""
        )}
        {control ?
          <Controller
            control={control}
            ref={newRegister}
            name={name}
            render={({ field: { onChange, value } }) => {
              function handleChange(params) {
                onChange?.(params);
                setState?.(params);
              }
              // console.log({ onChange, value, options },'');
              return (
                <AsyncSelect
                  className="custom-selector"
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions={dataForSelectBox}
                  onChange={handleChange}
                  components={{ LoadingIndicator }}
                  classNamePrefix="select"
                  styles={{
                    input: (base) => ({
                      ...base,
                      "input:focus": {
                        boxShadow: "none",
                      },
                    }),
                    control: (styles) => ({
                      ...styles,
                      borderColor: error ? "red" : styles.borderColor,
                    }),
                  }}
                  // validationState={error ? "error" : "default"}
                  value={value}
                  isDisabled={isDisabled || isLoading}
                  {...other}
                />
              );
              //   return <Select className="custom-selector" placeholder={label} options={newOptions}
              //    value={value} onChange={handleChange} {...other} />
            }}
          />
          :
          <AsyncSelect
            className="custom-selector"
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions={dataForSelectBox}
            onChange={setState}
            components={{ LoadingIndicator }}
            classNamePrefix="select"
            styles={{
              input: (base) => ({
                ...base,
                "input:focus": {
                  boxShadow: "none",
                },
              }),
              control: (styles) => ({
                ...styles,
                borderColor: error ? "red" : styles.borderColor,
              }),
            }}
            // validationState={error ? "error" : "default"}
            isDisabled={isDisabled || isLoading}
            {...other}
          />
        }
      </Form.Group>

      {/* <InputError error={error} /> */}
    </>
  );
};
export default CategorySelector;


// import React, { Fragment, useEffect, useRef } from "react";
// // import { InputError } from "components/forms/InputError";
// import AsyncSelect from "react-select/async";
// import { API_NAME } from "./enum";
// import axiosClient from "lib/axiosConfig";
// import { useQueryCustom } from "utils/useQueryCustom";
// import api from "operations/network/api";
// import { useMemo } from "react";
// import Spinner from "react-bootstrap/Spinner";
// import { Controller } from "react-hook-form";
// import { useTranslation } from "react-i18next";
// import compareTranslate from "utils/compareTranslate";
// import { Form } from "react-bootstrap";

// const LoadingIndicator = () => {
//   return (
//     <div className="mx-2">
//       <Spinner animation="grow" size="sm" />
//     </div>
//   );
// };

// export const convertCategoriesData = (data, { withAll, withParent, t } = {}) => {
//   console.log({ data });
//   const param = data?.map?.((x) => ({
//     label: (
//       <div className="flex align-items-center justify-items-center ">
//         <div className="ml-4 mt-1 d-flex align-items-center">{
//           // withParent
//           //     ?
//           ` ${x?.parent?.name ? `${x?.parent?.name} -> ` : ""} ${x?.name}`
//           // :
//           // x.name
//         }</div>
//       </div>
//     ),
//     value: x?.id || x?._id,
//   }));

//   if (withAll)
//     param?.unshift?.({
//       label: (
//         <div className="flex align-items-center justify-items-center">
//           <div className="ml-4 mt-1 d-flex align-items-center">{t("all")}</div>
//         </div>
//       ),
//       value: "all",
//     });

//   return param;
// };

// export const convertCategoryData = (x, { withParent } = {}) => ({
//   label: (
//     <div className="flex align-items-center justify-items-center">
//       <div className="ml-4 mt-1 d-flex align-items-center">
//         {
//           // withParent
//           //     ?
//           ` ${x?.parent?.name ? `${x?.parent?.name} -> ` : ""} ${x?.name}`
//           // : x.name
//         }
//       </div>
//     </div>
//   ),
//   value: x?.id || x?._id,
// });

// const CategorySelector = ({
//   name,
//   control,
//   label,
//   valid,
//   register,
//   errors,
//   require,
//   options,
//   setState,
//   withAll,
//   setParams,
//   withParent,
//   isDisabled,
//   ...other
// }) => {
//   // const [dataForSelectBox, setDataForSelectBox] = React.useState([])

//   const { t } = useTranslation();

//   const getting = async (params) => {
//     const { search_text, limit, page } = params;
//     return await axiosClient().get(api[API_NAME].list, {
//       params: {
//         limit: limit || 30,
//         search_text,
//         page,
//         ...(setParams && setParams),
//       },
//     });
//   };
//   // ------------------------------------------------------- query Get
//   const {
//     data: { data = {} } = {},
//     error,
//     isError,
//     isLoading,
//     refetch,
//   } = useQueryCustom({
//     name: `${API_NAME}_get`,
//     url: getting,
//     // params: {}
//     // params: { ...QueryString },
//   });

//   // const loadOptions = async (inputValue, callback) => {
//   //     const params = {
//   //         search_text: inputValue,
//   //         limit: 5,
//   //         page: 1
//   //     }
//   //     await getting(params).then(x => {
//   //         callback(convertCategoriesData(x?.data?.result, { withAll, withParent, t }))
//   //     })

//   // }

//   const searchRef = useRef();

//   const loadOptions = async (inputValue, callback) =>
//     new Promise((resolve, reject) => {
//       if (searchRef.current) clearTimeout(searchRef.current);
//       // if (!inputValue) return callback()
//       const time = setTimeout(async () => {
//         const params = {
//           search_text: inputValue,
//           limit: 20,
//           page: 1,
//         };
//         await getting(params).then((x) => {
//           resolve(callback(convertCategoriesData(x?.data?.result, { withAll, t })));
//         });
//       }, 1000);
//       searchRef.current = time;
//     });
//   // React.useEffect(() => {
//   //     async function getInitData() {
//   //         const query = {}
//   //         await GetAll(query)
//   //             .then((response) => {
//   //                 const { data } = response.data

//   //                 let arrayForSelect = []

//   //                 data?.data.forEach((item) => {
//   //                     arrayForSelect.push({
//   //                         value: item,
//   //                         label: (
//   //                             <div className='flex align-items-center justify-items-center'>
//   //                                 <div className='ml-4 mt-1 d-flex align-items-center'>{item.title}</div>
//   //                             </div>
//   //                         ),
//   //                     })
//   //                 })

//   //                 setDataForSelectBox(arrayForSelect)
//   //             })
//   //             .catch((err) => { })
//   //     }
//   // }, [])
//   const dataForSelectBox = useMemo(() => {
//     return convertCategoriesData(data?.result, { withAll, withParent, t });
//   }, [data]);
//   let newRegister = register ? register(name) : {};

//   useEffect(() => {
//     refetch();
//   }, [setParams]);
//   console.log({ setParams });
//   // console.log({ data, dataForSelectBox });

//   return (
//     <>
//       <Form.Group className="form-group ">
//         {label ? (
//           <div className={"d-flex justify-content-between"}>
//             <label htmlFor={name}>
//               {compareTranslate(t, label)}
//               {require ? " *" : ""}
//             </label>
//             {errors ? <div className={`error-text `}>{compareTranslate(t, errors[name]?.message)}</div> : ""}
//           </div>
//         ) : (
//           ""
//         )}
//         {/* {control ? */}
//         <Controller
//           control={control}
//           ref={newRegister}
//           name={name}
//           render={({ field: { onChange, value } }) => {
//             function handleChange(params) {
//               onChange?.(params);
//               setState?.(params);
//             }
//             // console.log({ onChange, value, options },'');
//             return (
//               <AsyncSelect
//                 className="custom-selector"
//                 cacheOptions
//                 loadOptions={loadOptions}
//                 defaultOptions={dataForSelectBox}
//                 onChange={handleChange}
//                 components={{ LoadingIndicator }}
//                 classNamePrefix="select"
//                 styles={{
//                   input: (base) => ({
//                     ...base,
//                     "input:focus": {
//                       boxShadow: "none",
//                     },
//                   }),
//                   control: (styles) => ({
//                     ...styles,
//                     borderColor: error ? "red" : styles.borderColor,
//                   }),
//                 }}
//                 // validationState={error ? "error" : "default"}
//                 value={value}
//                 isDisabled={isDisabled || isLoading}
//                 {...other}
//               />
//             );
//             //   return <Select className="custom-selector" placeholder={label} options={newOptions}
//             //    value={value} onChange={handleChange} {...other} />
//           }}
//         />
//         {/* :
//         <Select className="custom-selector" options={options} {...other} />
//       } */}
//       </Form.Group>

//       {/* <InputError error={error} /> */}
//     </>
//   );
// };
// export default CategorySelector;
