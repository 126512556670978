import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "components/PageTitle";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { fieldNames, validation } from "./validation";

import FieldSelect from "components/FieldSelect";
import FieldText from "components/FieldText";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import cLog from "utils/cLog";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PARENT_ROUTE_NAME, PARENT_COMPONENT_NAMES } from "../enum";
import { ROLES, ROLES_OBJECT, STATUSES, USER_TYPES, USER_TYPES_OBJECT } from "enumerations";
import GalleryModal from "components/gallery/GalleryModal";
import CheckBox from "components/CheckBox";
import RoleSelector, { convertRoleData } from "containers/Role/RoleSelector";
// import GalleryComponent from "components/gallery/GalleryComponent";
// import { convertToLabelValue } from "utils/convertToLabelValue";

const Add = () => {
	const [showPass, setShowPass] = useState(false);
	const [showConfirmPass, setShowConfirmPass] = useState(false);
	const [type, setType] = useState(null);
	// ------------------------------------ route
	const newRoute = useMemo(() => getDataInObjectDepth(PARENT_ROUTE_NAME, routes), []);

	// ----------------------------------------------------------------------------- Fetching Functions
	const creating = async (params) => await axiosClient().post(api[API_NAME].base, params);
	const updating = async (params) => await axiosClient().put(api[API_NAME].base + "/" + URL_ID, params);
	const getById = async () => await axiosClient().get(api[API_NAME].get + "/" + URL_ID);
	// const getRoles = async () => await axiosClient().get(api["role"].list);

	const navigation = useNavigate();
	let { id, hotel } = useParams();

	const URL_ID = id ? id : "";

	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(validation(URL_ID)),
		mode: "onSubmit",
		defaultValues: {
			[fieldNames.userType]: USER_TYPES_OBJECT.hotel,
		},
	});
	const watchUserType = watch(fieldNames.userType);

	// ----------------------------------------------------------------------------- Mutation
	const onSuccessMutating = () => {
		toastify.success({ title: "success" });
		navigation(newRoute[API_NAME].base + newRoute[API_NAME].list + "/" + hotel);
	};
	const { isLoading, data, mutate } = useMutationCustom({
		url: URL_ID ? updating : creating,
		invalidQuery: `getById_${API_NAME}`,
		onSuccess: onSuccessMutating,
	});

	// ----------------------------------------------------------------------------- Query By ID
	const onSuccessDataById = ({ data }) => {
		let params = { ...data };
		let selectedStatus = STATUSES.find((item) => item.value === params.isActive);

		handleSelectThumbnail({ _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title });
		reset({
			...params,
			role: convertRoleData(params?.role),
			isActive: selectedStatus,
			userType: USER_TYPES_OBJECT[params?.userType],

			// image: { _id: params?.image?._id, completedUrl: params?.image?.completedUrl, title: params?.image?.title },
		});
	};
	const dataById = useQueryCustom({
		name: `getById_${API_NAME}`,
		url: getById,
		onSuccess: onSuccessDataById,
		enabled: !!URL_ID,
	});

	//  ----------------------------------------- GETTING ROLES ----------------------------------------- //
	// const onSuccessRoles = ({ data }) => {
	//   setRolesOption(convertToLabelValue(data?.result));
	// };
	// const rolesData = useQueryCustom({
	//   name: "rolesData",
	//   url: getRoles,
	//   onSuccess: onSuccessRoles,
	//   params: { sort: "desc", page: 1, limit: 10, status: 1 },
	// });
	// --------------------------------------- Submit Form
	const onSubmit = () => {
		const values = getValues();
		const requestData = {
			...values,
			role: values.role?.value,
			userType: values.userType?.value,
			phoneNumber: values.phoneNumber && values.phoneNumber.toString(),
			isActive: values.isActive ? values.isActive.value : "1",
			isEmailNotificationActive: !!values.isEmailNotificationActive,
			isPhoneNotificationActive: !!values.isPhoneNotificationActive,
			password: values.password || undefined,
			...(hotel && { hotel }),
			// image: values.image._id,
		};
		console.log({ requestData });
		mutate(requestData);
	};

	// ----------------------------------------------------------------------------- Constance
	const dispatch = useDispatch();
	const button = {
		title: `Back To ${COMPONENT_NAMES[0]} List`,
		link: newRoute[API_NAME].base + newRoute[API_NAME].list + "/" + hotel,
	};

	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: PARENT_COMPONENT_NAMES[0], link: newRoute.base },
		{ title: COMPONENT_NAMES[0], link: newRoute[API_NAME].base + newRoute[API_NAME].list + "/" + hotel },
		{
			title: URL_ID ? `Edit` : "Add",
		},
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
		// if (hotel && !id) {
		//   setValue(fieldNames.role, ROLES_OBJECT["hotel"])
		// }
	}, [dataById]);
	// ----------------------------------------------------------------------------- Log
	const values = getValues();
	console.log({ dataById, values }, "Add");
	console.log({ URL_ID });
	console.log({ errors }, "errors");

	// ----------------------------------------------------------------------------- UseEffects

	//  ----------------------------------------- GALLERY ----------------------------------------- //
	const [showModal, setShowModal] = useState(false);
	const [thumbnail, setThumbnail] = useState(null);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};
	const handleSelectThumbnail = (thumbnail) => {
		setValue(fieldNames.image, thumbnail, {
			shouldValidate: true,
		});
		setThumbnail(thumbnail);
	};

	return (
		<div>
			<GalleryModal
				{...{
					showModal,
					handleModal: handleGalleryModal,
					handleClickedImage: handleSelectThumbnail,
					selectedPhoto: thumbnail,
				}}
			/>
			<PageTitle
				title={URL_ID ? `Edit   ${dataById?.data?.data?.name} ${dataById?.data?.data?.lastName}` : `Add  ${COMPONENT_NAMES[0]}`}
				button={button}
			/>
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				<div className="row mt-5">
					<Col md="8">
						<div className="col-12 border p-4">
							<div className="">
								<div className="b-1">
									<h4 className="">{"Basic Information"}</h4>
								</div>
								<div className="mt-5">
									<div className="col-12">
										<div className="basic-form">
											<FieldText
												require
												{...{
													name: fieldNames.name,
													register,
													placeholder: "first name",
													label: "First Name ",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.lastName,
													register,
													placeholder: "last name",
													label: "Last Name ",
													errors,
												}}
											/>
											<FieldText
												require
												{...{
													name: fieldNames.email,
													register,
													placeholder: "email",
													label: "Email ",
													errors,
												}}
											/>
											<FieldText
												{...{
													name: fieldNames.phoneNumber,
													register,
													placeholder: "Mobile Number",
													label: "Mobile Number ",
													errors,
												}}
											/>
											<FieldText
												require
												type={showPass ? "text" : "password"}
												{...{
													// disabled: URL_ID,
													name: fieldNames.password,
													register,
													placeholder: "password",
													label: "Password",
													errors,
													show: showPass,
													setShow: setShowPass,
												}}
											/>
											<FieldText
												require
												type={showConfirmPass ? "text" : "password"}
												{...{
													// disabled: URL_ID,
													name: fieldNames.confirm_password,
													register,
													placeholder: "Confirm Password",
													label: "Confirm Password",
													errors,
													show: showConfirmPass,
													setShow: setShowConfirmPass,
													autoComplete: "false",
												}}
											/>

											<FieldSelect
												isDisabled
												{...{
													Controller,
													name: fieldNames.userType,
													register,
													label: "userType",
													errors,
													control,
													options: USER_TYPES,
												}}
											/>

											<RoleSelector
												require
												isDisabled={!watchUserType?.value}
												{...{
													Controller,
													name: fieldNames.role,
													register,
													label: "role",
													errors,
													control,
													setParams: {
														...(watchUserType?.value && { userType: watchUserType?.value }),
													},
												}}
											/>

											<FieldSelect
												{...{
													Controller,
													name: fieldNames.isActive,
													register,
													label: "Status",
													errors,
													control,
													options: STATUSES,
												}}
											/>
											<CheckBox
												{...{
													Controller,
													name: fieldNames.isEmailNotificationActive,
													register,
													label: "is Email Notification Active",
													errors,
													control,
												}}
											/>
											<CheckBox
												{...{
													Controller,
													name: fieldNames.isPhoneNotificationActive,
													register,
													label: "is Phone Notification Active",
													errors,
													control,
												}}
											/>

											{/* <GalleryComponent
                        isRequired
                        {...{
                          handleModal: handleGalleryModal,
                          selectedPhoto: thumbnail,
                          labe: "image",
                          name: fieldNames.image,
                          Controller,
                          control,
                          errors,
                          register,
                        }}
                      /> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col md="4">
						<div className="row">
							<Col md="12 border p-4">
								<div className="card">
									<div className="">
										<h4 className="card-title">{"Actions"}</h4>
									</div>

									<div className="card-body">
										<div className="basic-form">
											{/* <div className='publishing-actions'>
                        <div className='pub-section'>
                          <i className="fa glyphicon glyphicon-export fa-info-circle mr-2"></i>
                          <span>Status : </span>
                          <span className='text-success ml-2'> Active</span>
                        </div>
                      </div> */}

											{/* <hr /> */}

											<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`} loading={isLoading}>
												{!isLoading ? "Publish" : "Loading..."}
											</Button>
										</div>
									</div>
								</div>
							</Col>
						</div>
					</Col>
				</div>
			</form>
		</div>
	);
};

export default Add;

// <Controller control={control} name={fieldNames.status} render={
//     ({ field: { onChange, value } }) => {
//         let valid = value !== undefined
//         return <Dropdown>
//             <Dropdown.Toggle
//                 variant="outline-primary"
//                 size="sm"
//                 className="mt-1 mb-2"
//             >
//                 {valid ? value ? 'Active' : 'Disable' : 'Status'}
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <Dropdown.Item onClick={() => onChange(true)} active={value}>Active</Dropdown.Item>
//                 <Dropdown.Item onClick={() => onChange(false)} active={!value}>Disable</Dropdown.Item>
//             </Dropdown.Menu>
//         </Dropdown>
//     }
// } >

// </Controller>
