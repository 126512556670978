function isoToDate(createdAt) {
  const monthNames = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
  let dt = new Date(createdAt);
  console.log("dt ====>", dt);

  return (
    dt.getDate() +
    "." +
    monthNames[dt.getMonth()] +
    "." +
    dt.getFullYear() +
    " " +
    dt.getHours() +
    ":" +
    (dt.getMinutes() < 10 ? "0" : "") +
    dt.getMinutes()
  );
}
export default isoToDate;

