import TableAction from "components/TableAction";


import isoToDate from "utils/isoToDate";
import TableStatus from 'components/TableStatus';
import { PLACEHOLDER_IMAGE } from "enumerations";
import { Badge } from "react-bootstrap";
import { PRIORITY_OBJECT } from "../enum";
export const tableData = ({ onClick, newRoute, ...props }) =>
  // columns: ["Name", "Network type", "Default", "Actions"],
  // body: data?.map((param) => {
  //     return [
  //         param.name,
  //         param.network_type,
  //         (param.is_default)?.toString(),
  //         () => {
  //             return (
  //                 <div className="d-flex">
  //                     <Link to={routes[ROUTE_NAME].edit + '/' + param.id} className="btn btn-primary shadow btn-xs sharp mr-1"  >
  //                         <i className="fa fa-pencil"></i>
  //                     </Link>
  //                     <span className="btn btn-danger shadow btn-xs sharp pointer" onClick={() => onClick({ param, type: 'delete' })} >
  //                         <i className="fa fa-trash pointer"></i>
  //                     </span>
  //                 </div>
  //             )
  //         }
  //     ]
  // }),
  [
    {
      header: "file",
      Component: ({ param }) => {
        return (
          <>
            <span className="d-block mb-1">
              <img
                src={param?.file?.path || PLACEHOLDER_IMAGE}
                className="image-fluid"
              />
            </span>
          </>
        );
      },
    },
    {
      header: "title",
      Component: ({ param }) => param.title || "-",
    },
    {
      header: "solution",
      Component: ({ param }) => param.solution || "-",
    },
    {
      header: "text",
      Component: ({ param }) => param.text || "-",
    },
    {
      header: "dueDate",
      Component: ({ param }) => param.dueDate ? isoToDate(param.dueDate) : "-",
    },
    {
      header: "user",
      Component: ({ param }) => `${param?.user?.name} ${param?.user?.lastName}` || "-",
    },
    {
      header: "priority",
      Component: ({ param }) => param.priority ? <Badge color={PRIORITY_OBJECT[param.priority]?.color} >{PRIORITY_OBJECT[param.priority]?.label}</Badge> : "-",
    },
    {
      header: "createdAt",
      Component: ({ param }) => isoToDate(param.createdAt),
    },
    // {
    //   header: "Status",
    //   Component: ({ param }) => <TableStatus {...{ param, onClick }} />,
    // },
    {
      header: "actions",
      Component: ({ param }) => <TableAction {...{ param, newRoute, onClick, ...props }} />,
    },
  ];
