import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import IsWorking from "components/IsWorkingStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute, ...other }) => [
  {
    header: "actions",
    Component: ({ param }) => (
      <TableAction {...{ param, newRoute, onClick, ...other }} />
    ),
  },
  {
    header: "name",
    Component: ({ param }) => param.name,
  },
  {
    header: "id",
    Component: ({ param }) => param.id,
  },
  // {
  //   header: "phoneNumber",
  //   Component: ({ param }) => param.phoneNumber,
  // },
];
