import styles from "./index.module.scss";

const BoxWithLabelValue = ({ label, value, small }) => {
	return (
		<section className={[styles.box, small && styles.small].join(" ")}>
			<label className={[styles.label].join(" ")}>{label}</label>
			<span className={[styles.value, "text-primary"].join(" ")}>{value}</span>
		</section>
	);
};

export default BoxWithLabelValue;
