import { useEffect, useRef, useState } from "react";
import configs from "configs";
import QrCodeComponent from "components/QrCodeComponent";

const Single = ({ data: x }) => {
	const canvasRef = useRef();

	let qrCodeUrl = `${configs.data.WEBSITE}?ouid=${x?.uuid}`;
	if (x?.roomNumber) qrCodeUrl = qrCodeUrl + `&roomNumber=${x?.roomNumber}`;
	if (x?.option) qrCodeUrl = qrCodeUrl + x?.option;

	let text = String(x?.roomNumber || "");
	var maxFontSize = 50;
	var minFontSize = 20;
	var thresholdCharacters = 4;

	var newFontSize = maxFontSize / text.length;

	if (text.length > 1) {
		newFontSize = newFontSize * 2;
	}
	if (newFontSize < minFontSize) newFontSize = minFontSize;

	console.log({ newFontSize, text }, text?.length);

	let fontSize = 200 / text.length / 1.2;
	if (text.length < 4) {
		if (fontSize > 25) fontSize = 25;
	} else if (fontSize > 14) fontSize = 14;
	console.log({ fontSize, text }, text?.length);

	return (
		<div className="print-page">
			<div className="first-box">
				<div className="start">
					<img src={x?.logo} />
				</div>
				<div className="end">
					{x?.roomNumber ? (
						<>
							<div className="top">{"ROOM NUMBER"}</div>
							<div
								className="bottom"
								style={{
									fontSize: newFontSize + "px",
								}}>
								{x?.roomNumber}
							</div>
						</>
					) : (
						""
					)}
				</div>
			</div>

			<div className="second-box">
				<div className="start"></div>
				<div className="end">
					{/* <div ref={qrCodeRef}>
                    </div> */}
					<div className="qrCode-roomNumber">
						<p
							style={{
								fontSize: fontSize + "px",
								fontWeight: "bold",
								marginBottom: 0,
								marginTop: 0,
								maxWidth: "55px",
							}}>
							{x?.roomNumber}
						</p>
					</div>
					<QrCodeComponent
						{...{
							text: qrCodeUrl,
							roomNumber: x?.roomNumber,
							options: {
								logo: {
									bgWhite: true,
								},
							},
						}}
					/>
				</div>
			</div>
			<canvas className="base-canvas" ref={canvasRef} width="195px" height="195px"></canvas>
		</div>
	);
};

export default Single;
