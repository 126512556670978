import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { convertToTimeFormat } from "utils/convertor";

export const tableData = ({ onClick, newRoute, ...props }) => [
  {
    header: "hour",
    Component: ({ param }) => convertToTimeFormat(param.hour) || '0',
  },
  {
    header: "totalPrice",
    Component: ({ param }) => param.totalPrice || '0',
  },
  {
    header: "totalScan",
    Component: ({ param }) => param.totalScan || '0',
  },
  {
    header: "avg",
    Component: ({ param }) => param.avg || '0',
  },
  {
    header: "totalOrders",
    Component: ({ param }) => param.totalOrders || '0',
  },
  {
    header: "branch",
    Component: ({ param }) => {
      return param?.regions ? <Button variant="info" size="sm">{`view (${param?.regions?.length})`}</Button> : "-"
    },
    subTable: {
      getData: (param) => param?.regions,
      options: [
        {
          header: "regionName",
          Component: ({ param }) => param?.regionName || "-"
        },
        {
          header: "totalOrders",
          Component: ({ param }) => param?.totalOrders || "-",
        },
        {
          header: "totalPrice",
          Component: ({ param }) => param?.totalPrice || "-",
        },
        {
          header: "totalScan",
          Component: ({ param }) => param?.totalScan || "-",
        },
      ]
    }

    // Component: ({ param }) => {

    //   return (
    //     <div className="">
    //       {param.regions ? param.regions?.map((x) => {
    //         return (
    //           <ul className="" key={x.regionName}>
    //             <li className="d-flex text-nowrap justify-content-start align-items-center ">
    //               <strong style={{ minWidth: "100px" }} className="mr-2 text-start">
    //                 {"regionName"}
    //               </strong>{" "}
    //               <div className="mx-2">{":"}</div>
    //               <div className="text-start">{x.regionName}</div>{" "}
    //             </li>
    //             <li className="d-flex text-nowrap justify-content-start align-items-center ">
    //               <strong style={{ minWidth: "100px" }} className="mr-2 text-start">
    //                 {"totalOrders"}
    //               </strong>{" "}
    //               <div className="mx-2">{":"}</div>
    //               <div className="text-start">{x.totalOrders}</div>{" "}
    //             </li>
    //             <li className="d-flex text-nowrap justify-content-start align-items-center ">
    //               <strong style={{ minWidth: "100px" }} className="mr-2 text-start">
    //                 {"totalPrice"}
    //               </strong>{" "}
    //               <div className="mx-2">{":"}</div>
    //               <div className="text-start">{x.totalPrice}</div>{" "}
    //             </li>
    //             <li className="d-flex text-nowrap justify-content-start align-items-center ">
    //               <strong style={{ minWidth: "100px" }} className="mr-2 text-start">
    //                 {"totalScan"}
    //               </strong>{" "}
    //               <div className="mx-2">{":"}</div>
    //               <div className="text-start">{x.totalScan}</div>{" "}
    //             </li>
    //             <hr />
    //           </ul>
    //         );
    //       }) : "-"}
    //     </div>
    //   )

    // },
  },



];
