import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
  productId: "productId",
  buyPrice: "buyPrice",
  products: "products"
};

export const validation = () =>
  yup.object().shape({
    // [fieldNames.productId]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.productId)).label(fieldNames.productId),
    // [fieldNames.buyPrice]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.buyPrice)).label(fieldNames.buyPrice),
  });
