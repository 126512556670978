import React, { Component } from "react";

class Footer extends Component {
	render() {
		return (
			<footer className="footer">
				<div className="d-sm-flex justify-content-center justify-content-sm-between py-2">
					<div className="w-100 d-flex justify-content-center">
						<h6 className="font-weight-normal mb-3 supportText ">Destek : 0(212) 909 05 44 | 0(535) 833 3625</h6>{" "}
					</div>
					<span className="ml-auto text-muted text-center text-sm-left d-block d-sm-inline-block text-nowrap">
						Copyright ©{" "}
						<a href="https://www.papyonotel.com/" target="_blank" rel="noopener noreferrer">
							Papyonotel.com{" "}
						</a>
						2022
					</span>
					{/* <h6 className="font-weight-normal mb-3 supportText ">Operasyon Müdürü: 0(533) 920 94 13 | Ferhat Aslan</h6>{" "} */}
				</div>
			</footer>
		);
	}
}

export default Footer;
