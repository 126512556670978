import { forwardRef, useEffect, useRef, useState } from "react";
import "./index.css";
import moment from "moment";
import { changePriceFormat } from "utils/calculators";
import { useTranslation } from "react-i18next";
import isoToDate from "utils/isoToDate";

// import axios from "axios";

const Factor = ({ propData, ...props }, ref) => {
  const paperRef = useRef();
  const [offsetHeight, setOffsetHeight] = useState()
  const { isBranch, isAdmin } = propData || {};
  // const [Style, setStyle] = useState()
  let Style;
  const { t } = useTranslation();
  console.log("factor props", props);
  console.log("isBranch", isBranch);

  const pxToMm = (px) => {

    // let div = document.createElement("div");
    // div.style.display = "block";
    // div.style.height = "1mm";
    // document.body.appendChild(div);
    // let final = Math.floor(px / div.offsetHeight);
    // try {
    //   document.body.removeChild(div);
    // } catch (error) {

    // }
    let final = 0.2645833333 * px
    return final?.toFixed()
    // return Math.floor(px / 0.2645833333);
    // return (px * 25.4) / DPI
  };

  const mmToPx = (mm) => {
    let div = document.createElement("div");
    div.style.display = "block";
    div.style.height = "1mm";
    document.body.appendChild(div);
    let final = Math.floor(mm * div.offsetHeight);
    try {
      document.body.removeChild(div);
    } catch (error) {
    }
    return final
  };
  const reducer = (accumulator, currentValue) =>
    accumulator + currentValue.quantity;
  const total = props?.products?.reduce(reducer, 0);
  console.log({ total });
  // const inputWidthMm = pxToMm(mmToPx(53));
  const inputHeightMm = offsetHeight ? pxToMm(offsetHeight + 100) : 0;
  // const inputHeightMm = pxToMm((total * 24) + offsetHeight + 50);
  // const inputHeightMm = pxToMm((total * 24) + 450);
  // const inputHeightMm = pxToMm(total * 22 + 450);
  // console.log({ inputHeightMm, inputWidthMm });
  // // props?.products
  console.log({ offsetHeight, inputHeightMm, total }, offsetHeight + 100, props?.orderNumber);
  const GlobalStyler = () => (
    <style>{`
  
    body{
      margin:0;
    }
 #factor{
  font-family: 'Barlow Semi Condensed', sans-serif;
  width: fit-content;
  width:80mm;
  .row-label{
    justify-content: space-between;
    display: flex;
    label{
      font-size: 14px;
      font-weight: 800;
      margin-right:10px;
    }
    h7{
      font-weight:600;
      font-size: 17px;
      white-space: nowrap;
    }

  }
  .note{
    text-align: start;
    font-weight:500;
    font-size: 14px;

  }
 }
${inputHeightMm ?
        `@media print {
 
      @page {
        size: 80mm ${inputHeightMm}mm;
        body{
          width:80mm;
        }
      }
  }`
        : ""
      }
  `}</style>
  );

  useEffect(() => {

    // const factor = document.querySelector("#factor")
    console.log({ offsetHeight: ref?.current?.offsetHeight, scrollHeight: ref?.current?.scrollHeight, current: ref?.current }, props?.hotel?.name);
    if (paperRef?.current?.offsetHeight) {
      setOffsetHeight(paperRef?.current?.offsetHeight)
    }
    // if (paperRef?.current?.scrollHeight) {
    //   setOffsetHeight(paperRef?.current?.scrollHeight)
    // }
  }, [])

  return (
    <div id="factor" ref={paperRef}>
      <div
        className="factor border py-2"
        ref={ref}
        style={{
          width: "80mm",
          background: "white",
          fontWeight: "700",
        }}
      >
        <div className="" >
          <div className="pt-2">
            <h6
              className="title text-center"
              style={{
                // fontSize: "16px",
                fontWeight: "900",
              }}
            >
              {/* Papyon Room Service Solutions */}
              {isAdmin
                ? props?.hotel?.region?.name
                : "Papyon Room Service Solutions"}
            </h6>
          </div>

          <div className="px-3 py-2">
            <div className="row-label mb-1">
              <label>{"Sipariş Numarası"}</label>
              <h7>{props?.orderNumber}</h7>
            </div>

            {!isBranch ? (
              <div className="row-label  mb-1">
                <label
                  style={{
                  }}
                >
                  Ad Soyad
                </label>
                <h7 className="ml-auto">{props.name}</h7>
              </div>
            ) : (
              ""
            )}

            {!isBranch ? (
              <div className="row-label mb-1">
                <label
                  style={{
                  }}
                >
                  Otel
                </label>
                <h7>{props?.hotel?.name}</h7>
              </div>
            ) : (
              ""
            )}

            {!isBranch ? (
              <div className="row-label mb-1">
                <label
                  style={{
                  }}
                >
                  Oda Numarası
                </label>
                <h7>{props?.roomNumber}</h7>
              </div>
            ) : (
              ""
            )}

            {!isBranch ? (
              <div
                className="row-label mb-1"
                style={{
                }}
              >
                <label
                  style={{
                  }}
                >
                  Ödeme
                </label>
                <h7>{t(props?.paymentType)}</h7>
              </div>
            ) : (
              ""
            )}
          </div>

          <div
            className="border-bottom "
            style={{
              borderBottom: "1px solid #ccc",
            }}
          ></div>

          <div
            className="px-3 py-2"
            style={{
              padding: "10px 10px",
            }}
          >
            {props?.products?.map((x) => {
              console.log({ x });
              return new Array(x.quantity).fill({}).map((_, i) => (
                <div
                  className="row-label mb-1 d-flex justify-content-between"
                  key={x?.product?.name + x?.price + i}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <label>{x?.product?.name} </label>
                  <h7
                    style={{
                      marginLeft: "20px",
                    }}
                  >
                    {
                      !isBranch ?
                        <>
                          {changePriceFormat(x?.price / x.quantity)}{" "}
                          <small
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            €
                          </small>
                        </>
                        :
                        ""

                    }

                  </h7>
                </div>
              ));
            })}
          </div>

          <div
            className="border-bottom "
            style={{
              borderBottom: "1px solid #ccc",
            }}
          ></div>

          <div
            className="px-3 py-2 text-center"
            style={{
              textAlign: "center",
              padding: "10px 10px",
            }}
          >
            <div
              className="row-label mb-1"
              style={{
                marginBottom: "5px",
                display: "flex",

              }}
            >
              <label style={{
                whiteSpace: "nowrap"
              }} >Not :</label>
              <p className="note">{props.note}</p>
            </div>

            {!isBranch ? (
              <>
                <div
                  className="row-label  mb-1"
                  style={{
                    marginBottom: "5px",
                    display: "flex",
                    // justifyContent: "space-between",
                  }}
                >
                  <label>Service Charge:</label>
                  <h7>
                    {changePriceFormat(props.serviceCharge)}
                    <small
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      €
                    </small>{" "}
                  </h7>
                </div>

                <div
                  className="row-label  mb-2"
                  style={{
                    marginBottom: "5px",
                    display: "flex",
                    // justifyContent: "space-between",
                  }}
                >
                  <label>TOTAL EURO</label>
                  <h7>
                    {changePriceFormat(props.totalPrice)}
                    <small
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      €
                    </small>{" "}
                  </h7>
                </div>

                <div
                  className="row-label  mb-2"
                  style={{
                    marginBottom: "5px",
                    display: "flex",
                    // justifyContent: "space-between",
                  }}
                >
                  <label>TOTAL DOLLAR</label>
                  <h7>
                    {changePriceFormat(props.totalPriceUsd)}
                    <small
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      $
                    </small>{" "}
                  </h7>
                </div>

                <div
                  className="row-label  mb-2"
                  style={{
                    marginBottom: "5px",
                    display: "flex",
                    // justifyContent: "space-between",
                  }}
                >
                  <label>TOTAL TL</label>
                  <h7>
                    {changePriceFormat(props.totalPriceTry)}
                    <small
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      ₺
                    </small>{" "}
                  </h7>
                </div>

                {props.paymentCurrency ? <div
                  className="row-label  mb-2"
                  style={{ marginBottom: "5px", display: "flex" }}
                >
                  <label>Type</label>
                  <h7>
                    {/* {changePriceFormat(props.paymentCurrency)} */}
                    <h7>
                      {props.paymentCurrency === "try" && "Turkish Lira"}
                      {props.paymentCurrency === "usd" && "Dollar"}
                      {props.paymentCurrency === "eur" && "Euro"}
                    </h7>
                  </h7>
                </div> : ""}

                <div
                  className="border-bottom "
                  style={{
                    borderBottom: "1px solid #ccc",
                  }}
                ></div>

                {props?.dollar ? <div
                  className="row-label mt-2"
                  style={{
                    marginBottom: "5px",
                    display: "flex",
                    // justifyContent: "space-between",
                    textAlign: "center",
                  }}
                >
                  <h7>1 $ = {(props?.dollar * 1.08).toFixed(2)}</h7>
                </div> : ""}
                {props?.euro ? <div
                  className="row-label mt-2"
                  style={{
                    marginBottom: "5px",
                    display: "flex",
                    // justifyContent: "space-between",
                    textAlign: "center",
                  }}
                >
                  <h7>1 € = {(props?.euro * 1.08).toFixed(2)}</h7>
                </div> : ""}
              </>
            ) : (
              ""
            )}

            <div
              className="row-label mb-2"
              style={{
                marginBottom: "5px",
                display: "flex",
                // justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              <label>Tarih </label>
              <h7>{isoToDate(props?.createdAt)}</h7>
            </div>

            <div
              className="row-label mb-2"
              style={{
                marginTop: "15px",
                marginBottom: "5px",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <h7>Mali Değeri Yoktur</h7>
            </div>
          </div>
        </div>
      </div>
      <GlobalStyler />
    </div>
  );
};

export default forwardRef(Factor);
