import { STATUSES } from "enumerations";
import { Children } from "react";
import { Button, Dropdown } from "react-bootstrap";

const DynamicStatus = ({ value, onClick, options }) => {
  let foundStatus = options.find((x) => x.value === value);
  let status = foundStatus;
  return (
    onClick ? <Dropdown>
      <Dropdown.Toggle
        id="dropdown-basic"
        className="btn-sm filter-sort mr-3"
        variant={status.color}
      >
        <span>{foundStatus?.label}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropDown-menu-custom">
        {Children.toArray(
          options.map((item) => (
            <Dropdown.Item
              key={item.label + "_STATUS"}
              onClick={item.value === value ? "" : () => onClick(item)}
              className="dropDown-item-custom"
              active={item.value === value
              }
            >
              {item?.label}
            </Dropdown.Item>
          ))
        )}
      </Dropdown.Menu>
    </Dropdown> : <Button size="sm" variant={status.color}>{status.label}</Button>

  );
};

export default DynamicStatus;
