import { convertorArrayOption } from "utils/convertor";

export const API_NAME = "ideaBox";
export const ROUTE_NAME = "ideaBox";
export const PERMISSION = "ideaBoxes";
export const COMPONENT_NAMES = ["ideaBox", "ideaBoxes"];

export const PRIORITY = [
    { label: "low", value: "low", color: "success" },
    { label: "normal", value: "normal", color: "warning" },
    { label: "high", value: "high", color: "danger" },
];

export let PRIORITY_OBJECT = {};

convertorArrayOption(PRIORITY, PRIORITY_OBJECT);
