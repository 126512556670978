import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { INVOICE_DATA_OBJECT, PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import isoToDate from "utils/isoToDate";
import ModalImage from "react-modal-image";
import TranslateByObject from "components/TranslateByObject";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { changePriceFormat, numberWithCommas } from "../../../utils/calculators";

export const tableData = ({ onClick, newRoute, active, isBranch }) => {
	let data = [
		{
			header: "branch",
			Component: ({ param }) => {
				const { base, edit } = routes.branch;
				return param.branch ? (
					<Link to={`${base + edit}/${param.branch.id}`} target="_blank">
						{" "}
						{param.branch?.name}{" "}
					</Link>
				) : (
					"Unknown"
				);
			},
		},
		{
			header: "orderCount",
			Component: ({ param }) => numberWithCommas(param.orderCount),
		},
		{
			header: "totalCommission",
			Component: ({ param }) => "₺ " + changePriceFormat(param.totalCommission, 2),
		},
	];

	if (isBranch)
		data = [
			{
				header: "name",
				Component: ({ param }) => param.name,
			},
			{
				header: "commission",
				Component: ({ param }) => "₺ " + changePriceFormat(param.commission, 2),
			},
			{
				header: "orderNumber",
				Component: ({ param }) => param.orderNumber,
			},
			// {
			//   header: "kdv",
			//   Component: ({ param }) => numberWithCommas(param.kdv),
			// },
			// {
			//   header: "price",
			//   Component: ({ param }) => "₺ " + changePriceFormat(param.price, 2),
			// },
			{
				header: "createdAt",
				Component: ({ param }) => isoToDate(param.createdAt),
			},
		];

	return data;
};
